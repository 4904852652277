import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

export interface InputProps {
  onChange: (v: any) => void;
  onBlur: () => void;
  value: any;
}

export interface FormItemProps {
  name: string;
  label: string;
  rules?: RegisterOptions;
  hidden?: boolean;
  disabled?: boolean;
  input: React.ComponentType<InputProps>;
}

const FormItem: React.FC<FormItemProps> = ({
  name,
  label,
  rules,
  hidden,
  disabled,
  input: Input
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <IonItem lines="none" hidden={hidden} disabled={disabled}>
        <IonLabel hidden={!label.length} position="stacked">
          {label} {rules?.required && "*"}
        </IonLabel>
        <Controller
          render={({ field }) => <Input {...field} />}
          control={control}
          name={name}
          rules={rules}
        />
      </IonItem>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <span
            className="ion-padding-start"
            style={{
              color: "red",
              fontSize: "smaller"
            }}
          >
            {message}
          </span>
        )}
      />
    </>
  );
};

export default FormItem;
