import React, { useEffect, useState } from "react";
import {
  IonRouterOutlet,
  IonLabel,
  IonMenu,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonListHeader,
  IonSplitPane,
  IonLoading,
  IonThumbnail,
  IonImg
} from "@ionic/react";
import { Route, useLocation } from "react-router";
import { useAuthContext } from "../../context/AuthProvider";

/* Icons */
import Icon from "../Icon";
import {
  faCalendarCheck,
  faTeethOpen,
  faUserTie,
  faIdBadge,
  IconDefinition,
  faIdCard,
  faSignOutAlt,
  faExchangeAlt,
  faHospital,
  faHospitalAlt,
  faUserCog,
  faMoneyBillWave,
  faTasks,
  faThLarge,
  faCheckCircle,
  faChartLine
} from "@fortawesome/free-solid-svg-icons";

/*Components*/
import AuthRoute from "./AuthRoute";
import NonAuthRoute from "./NonAuthRoute";

/*Pages*/
import Profile from "../profile/Profile";
import Cases from "../case/Cases";
import MainTabs from "../MainTabs";
import CalendarAllCases from "../calendar/CalendarPage";
import NewLab from "../lab/NewLab";
import NotFound from "../NotFound";
import AcceptInvitation from "../employee/AcceptInvitation";
import Doctors from "../doctor/Doctors";
import DoctorProfile from "../doctor/DoctorProfile";
import Employees from "../employee/Employees";
import EmployeeProfile from "../employee/EmployeeProfile";
import InviteEmployee from "../employee/InviteEmployee";
import ViewInvitation from "../employee/ViewInvitation";
import ChangeLab from "../lab/ChangeLab";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import VerifyEmail from "../auth/VerifyEmail";
import { Permission } from "../../models/Permissions";
import EditLab from "../lab/EditLab";
import Case from "../case/Case";
import InvoiceView from "../invoice/InvoiceView";
import Settings from "../Settings";
import Invoices from "../invoice/Invoices";
import useTranslation from "../../data/useTranslation";
import Tasks from "../task/Tasks";
import Task from "../task/Task";
import Dashboard from "../dashboard/Dashboard";
import InitialPath from "./InitialPath";
import ProductionLogs from "../productionLog/ProductionLogs";
import SchedulingTemplates from "../schedulingTemplate/SchedulingTemplates";
import ProductView from "../product/ProductView";
import Products from "../product/Products";
import Extras from "../product/Extras";
import ExtraView from "../product/ExtraView";
import useApi from "../../data/Api";
import { firebaseMessaging } from "../../data/firebase";
import { useNotificationContext } from "../../context/NotificationProvider";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import Notifications from "../profile/Notifications";
import usePushNotifications from "../../data/pushNotifications";
import MyTasks from "../productionLog/MyTasks";
import Stats from "../stats/Stats";
import { APP_UPDATE_EVENT } from "../../data/Constants";

export interface Page {
  url: string;
  icon?: IconDefinition;
  title: string;
  component: React.FC<any>;
  permission?: Permission;
}

// Edit my Profile, Add/edit Technicians, Clinic info/settings, Products, etc.
const accountPages: Page[] = [
  {
    title: "settings",
    url: "/settings",
    icon: faUserCog,
    component: Settings
  }
];

// Tasks, Cases, doctors, etc.
const myLabPages: Page[] = [
  {
    title: "dashboard.title",
    url: "/tabs/dashboard",
    icon: faThLarge,
    component: Dashboard
    // permission: Permission.DashboardRead
  },
  {
    title: "myTasks.title",
    url: "/tabs/myTasks",
    icon: faCheckCircle,
    component: MyTasks
  },

  {
    title: "calendar",
    url: "/tabs/calendar",
    icon: faCalendarCheck,
    component: CalendarAllCases
    // permission: Permission.CalendarRead
  },
  {
    title: "cases.menuTitle",
    url: "/tabs/cases",
    icon: faTeethOpen,
    component: Cases,
    permission: Permission.CasesTableRead
  },
  {
    title: "workLog.title",
    url: "/work-logs",
    icon: faTasks,
    component: ProductionLogs,
    permission: Permission.ProductionLogsTableRead
  },
  {
    title: "invoices.title",
    url: "/invoices",
    icon: faMoneyBillWave,
    component: Invoices,
    permission: Permission.InvoicesTableRead
  },
  {
    title: "doctors.title",
    url: "/tabs/doctors",
    icon: faUserTie,
    component: Doctors,
    permission: Permission.DoctorsTableRead
  },
  {
    title: "employees.title",
    url: "/employees",
    icon: faIdBadge,
    component: Employees,
    permission: Permission.EmployeeRead
  }
  // {
  //   title: "stats.title",
  //   url: "/stats",
  //   icon: faChartLine,
  //   component: Stats,
  //   permission: Permission.ProductionLogRead
  // }
];

// other pages that are not in the menus
const otherPages: Page[] = [
  {
    title: "changeLab.title",
    url: "/change-lab",
    icon: faExchangeAlt,
    component: ChangeLab
  },
  {
    title: "newLab.title",
    url: "/new-lab",
    icon: faHospital,
    component: NewLab
  },
  {
    title: "profile.title",
    url: "/profile",
    icon: faIdCard,
    component: Profile
  },
  {
    title: "notifications.editNotifications",
    url: "/notifications",
    icon: faBell,
    component: Notifications
  },
  {
    title: "editLab.title",
    url: "/edit-lab",
    icon: faHospitalAlt,
    component: EditLab
  },
  {
    title: "case",
    url: "/case/:id",
    component: Case
  },
  {
    title: "invoice",
    url: "/invoice/:id",
    component: InvoiceView
  },
  {
    title: "doctor",
    url: "/doctor/:id",
    component: DoctorProfile
  },
  {
    title: "employee",
    url: "/employee/:id",
    component: EmployeeProfile
  },
  {
    title: "inviteEmployee.title",
    url: "/invite-employee",
    component: InviteEmployee
  },
  {
    title: "viewInvitation.title",
    url: "/view-invitation/:id",
    component: ViewInvitation
  },
  {
    title: "task.titlePlural",
    url: "/tasks",
    component: Tasks
  },
  {
    title: "task.title",
    url: "/task/:id",
    component: Task
  },
  {
    title: "schedulingTemplate.titlePlural",
    url: "/scheduling-templates",
    component: SchedulingTemplates
  },
  {
    title: "products.title",
    url: "/product/:id",
    component: ProductView
  },
  {
    title: "products.title",
    url: "/products",
    component: Products
  },
  {
    title: "extras.title",
    url: "/extras",
    component: Extras
  },
  {
    title: "extras.title",
    url: "/extra/:id",
    component: ExtraView
  }
];

// merge for router outlet
export const allPages: Page[] = myLabPages.concat(accountPages, otherPages);

const Navigation: React.FC = () => {
  const location = useLocation();
  const { authenticated, loadingAuthState, user, lab, logout } =
    useAuthContext();
  const { t } = useTranslation();
  const [tabPages, setTabPages] = useState<Page[]>([]);
  const [logoImg, setLogoImg] = useState<string>();
  const { apiGetImage } = useApi();
  const { showToast } = useNotificationContext();
  const { notificationsGranted } = usePushNotifications();

  useEffect(() => {
    const s = (ev: any) => {
      showToast({
        message: t("appUpdate"),
        duration: 5000,
        color: "warning"
      });
      // const sw = (ev.detail as ServiceWorkerRegistration).waiting;
      // if (sw) {
      //   sw.postMessage("SKIP_WAITING");
      // sw.addEventListener("statechange", (e: any) => {
      //   if (e.target.state === "activated") {
      //     window.location.reload();
      //   }
      // });
      // }
    };

    window.addEventListener(APP_UPDATE_EVENT, s);
    // Remove listener
    return () => window.removeEventListener(APP_UPDATE_EVENT, s);
  }, []);

  useEffect(() => {
    if (!user) setTabPages([]);
    else
      setTabPages(
        myLabPages.filter(
          p =>
            p.url.startsWith("/tabs") &&
            ((p.permission && user.hasPermission(p.permission)) ||
              !p.permission)
        )
      );
  }, [user]);

  useEffect(() => {
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    if (!notificationsGranted) return;

    return (
      firebaseMessaging &&
      firebaseMessaging.onMessage(payload => {
        console.log(payload);
        showToast({
          message: payload.notification.body,
          duration: 3000,
          color: "success"
        });
      })
    );
  }, [notificationsGranted]);

  useEffect(() => {
    if (lab) {
      apiGetImage("lab/getLogo").then(logo =>
        setLogoImg(logo ? URL.createObjectURL(logo) : undefined)
      );
    } else {
      setLogoImg(undefined);
    }
  }, [lab]);

  if (loadingAuthState)
    return <IonLoading isOpen message={t("loggingInMessage")} />;
  var invitationCode = new URLSearchParams(location.search).get("code");
  if (user && !user.emailVerified) {
    return <VerifyEmail code={invitationCode} />;
  }

  const renderListItems = (pages: Page[]) =>
    pages
      .filter(page => !page.permission || user!.hasPermission(page.permission))
      .map((page, index) => (
        <IonMenuToggle key={index} autoHide={false}>
          <IonItem
            className={location.pathname === page.url ? "selected" : ""}
            routerLink={page.url}
            routerDirection="root"
            lines="none"
            detail={false}
          >
            {page.icon && (
              <Icon
                slot="start"
                className="left-menu-icon"
                size="lg"
                icon={page.icon}
              />
            )}
            <IonLabel>{t(page.title)}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));

  return (
    <IonSplitPane contentId="main">
      {authenticated && (
        <IonMenu contentId="main" swipeGesture={true} type="overlay">
          <IonContent className="ion-no-padding">
            {logoImg ? (
              <>
                <IonThumbnail className="logo-thumb">
                  <IonImg src={"assets/img/logo4.png"} alt="" />
                </IonThumbnail>
                <IonThumbnail className="logo-menu logo-menu-client">
                  <IonImg src={logoImg} alt="assets/img/logo4.png" />
                </IonThumbnail>
              </>
            ) : (
              <IonImg
                className="logo-menu"
                style={{ marginTop: 15, maxWidth: 130 }}
                src={"assets/img/logo4.png"}
                alt=""
              />
            )}
            {lab && (
              <IonList>
                <IonListHeader className="lab-name ion-no-padding">
                  <IonLabel>{lab.name}</IonLabel>
                </IonListHeader>
                {renderListItems(myLabPages)}
              </IonList>
            )}
            <IonList lines="none">
              <IonListHeader>{t("navigation.menuAccountHeader")}</IonListHeader>
              {renderListItems(accountPages)}
              <IonMenuToggle autoHide={false}>
                <IonItem onClick={logout} button>
                  <Icon
                    slot="start"
                    size="lg"
                    className="left-menu-icon"
                    icon={faSignOutAlt}
                  />
                  <IonLabel>{t("logout")}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>
      )}
      <IonRouterOutlet id="main">
        <AuthRoute path="/tabs" render={() => <MainTabs pages={tabPages} />} />
        {allPages
          .filter(page => !page.url.includes("/tabs"))
          .map((page, index) => (
            <AuthRoute
              key={index}
              path={page.url}
              component={page.component}
              exact
            />
          ))}

        <NonAuthRoute
          path="/login"
          render={() => <Login code={invitationCode} />}
        />
        <NonAuthRoute
          path="/register"
          render={() => <Register code={invitationCode} />}
        />
        <NonAuthRoute path="/forgot-password" component={ForgotPassword} />

        <Route
          path="/invitation"
          render={() => <AcceptInvitation code={invitationCode} />}
        />

        <Route path="/" component={InitialPath} exact />
        <Route component={NotFound} />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default Navigation;
