import React, { useEffect, useState } from "react";
import { IonItem, IonSkeletonText, IonList } from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import { ProductionLogFormDto } from "../../models/Case";
import useDate from "../../hooks/useDate";
import ProductionLogItem from "./ProductionLogItem";
import NoResults from "../NoResults";
import { scheduledDayColorClasses } from "../../data/Helpers";
import { CalendarProductionLogDto } from "../../models/CalendarDto";
import { useAuthContext } from "../../context/AuthProvider";

interface Props {
  urlProps: {
    employeeId: number;
    status: number;
    getUnassigned?: boolean;
  };
  mode: "inProgress" | "scheduled";
  editProductionLog: (l: ProductionLogFormDto) => void;
}
interface TaskDay {
  day: string;
  count: number;
  colorClass: string;
  logs: CalendarProductionLogDto[];
}

const EmployeeTasks: React.FC<Props> = ({
  urlProps,
  mode,
  editProductionLog
}) => {
  const [loading, setLoading] = useState(false);

  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const [productionLogs, setProductionLogs] =
    useState<CalendarProductionLogDto[]>();
  const [taskDays, setTaskDays] = useState<TaskDay[]>([]);

  const { t } = useTranslation();
  const { toDateString } = useDate();

  const { user } = useAuthContext();

  const today = toDateString(new Date().toISOString());

  useEffect(() => {
    fetchProductionLogs();
  }, [urlProps]);

  useEffect(() => {
    if (mode !== "scheduled" || !productionLogs) return;
    const taskDays = productionLogs
      .filter(l => l.scheduled)
      .reduce<TaskDay[]>((result, item) => {
        const day = toDateString(item.scheduled);

        const group = result.find(g => g.day === day);
        if (group) {
          return [
            ...result.filter(g => g.day !== day),
            { ...group, count: group.count + 1, logs: [...group.logs, item] }
          ];
        } else {
          const colorClass = scheduledDayColorClasses(
            new Date(item.scheduled)
          ).bgClass;
          return [...result, { day, count: 1, colorClass, logs: [item] }];
        }
      }, [])
      .map(r => ({
        ...r,
        logs: r.logs.sort(
          (a, b) =>
            new Date(a.scheduled).getTime() - new Date(b.scheduled).getTime()
        )
      }))
      .sort((a, b) => new Date(a.day).valueOf() - new Date(b.day).valueOf());
    setTaskDays(taskDays);
  }, [productionLogs, mode]);

  const fetchProductionLogs = () => {
    setLoading(true);
    return apiPost<CalendarProductionLogDto[]>(`calendar/getLogs`, urlProps)
      .then(setProductionLogs)
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  return (
    <>
      {!productionLogs && loading && (
        <IonSkeletonText animated title={t("loading")} />
      )}
      {mode === "scheduled" && (
        <>
          <h3 className="form-header text_center">
            {t("scheduling.scheduledTasks")}
          </h3>
          {productionLogs && (
            <>
              {productionLogs.length === 0 &&
                user &&
                (user.userId === urlProps.employeeId ? (
                  <NoResults title={t("myTasks.noMyScheduledTasks")} />
                ) : (
                  <NoResults title={t("myTasks.noScheduledTasks")} />
                ))}
              {productionLogs.length > 0 &&
                taskDays.map(taskDay => (
                  <IonList key={taskDay.day}>
                    <h4 className={taskDay.colorClass + " date-header"}>
                      {taskDay.day === today ? t("today") : taskDay.day}
                    </h4>
                    {taskDay.logs.map(log => (
                      <IonItem key={log.id}>
                        <ProductionLogItem
                          $case={log.case}
                          showTimeOnly
                          {...log}
                          hideEmployee
                          caseStatus={1}
                          editProductionLog={() =>
                            editProductionLog({
                              ...log,
                              taskName: log.task?.name
                            })
                          }
                          deleteProductionLog={() => {}}
                        />
                      </IonItem>
                    ))}
                  </IonList>
                ))}
              {productionLogs.filter(l => !l.scheduled).length > 0 && (
                <>
                  <h3 className="date-header primaryBgColor">
                    {t("scheduling.deadline")}: {t("notDefined")}
                  </h3>
                  <IonList>
                    {productionLogs
                      .filter(l => !l.scheduled)
                      .map(log => (
                        <IonItem key={log.id}>
                          <ProductionLogItem
                            showTimeOnly
                            $case={log.case}
                            {...log}
                            hideEmployee
                            caseStatus={log.case.status}
                            editProductionLog={() =>
                              editProductionLog({
                                ...log,
                                taskName: log.task?.name
                              })
                            }
                            deleteProductionLog={() => {}}
                          />
                        </IonItem>
                      ))}
                  </IonList>
                </>
              )}
            </>
          )}
        </>
      )}

      {mode === "inProgress" && (
        <>
          <h3 className="form-header text_center">{t("myTasks.inProgress")}</h3>
          {productionLogs && (
            <>
              {productionLogs.length === 0 &&
                user &&
                (user.userId === urlProps.employeeId ? (
                  <NoResults title={t("myTasks.noMyInProgressTasks")} />
                ) : (
                  <NoResults title={t("myTasks.noInProgressTasks")} />
                ))}

              {productionLogs.length > 0 && (
                <IonList className="ion-no-padding">
                  {productionLogs.map(log => (
                    <IonItem
                      onClick={() => {
                        editProductionLog({
                          ...log,
                          taskName: log.task?.name
                        });
                      }}
                    >
                      <ProductionLogItem
                        key={log.id}
                        $case={log.case}
                        {...log}
                        hideEmployee
                        caseStatus={1}
                        editProductionLog={editProductionLog}
                        deleteProductionLog={() => {}}
                      />
                    </IonItem>
                  ))}
                </IonList>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default EmployeeTasks;
