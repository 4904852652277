import React from "react";
import { IonImg, IonRow } from "@ionic/react";

interface Props {
  lightMode?: boolean;
}

const Logo: React.FC<Props> = ({ lightMode }) => (
  <IonRow className="ion-justify-content-center">
    <IonImg
      class="app-logo  ion-justify-content-center"
      src={lightMode ? "assets/img/logo4.png" : "assets/img/logo5.png"}
    />
  </IonRow>
);

export default Logo;
