import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonCol,
  IonRow,
  IonGrid,
  IonBackButton
} from "@ionic/react";
import React from "react";
import useApi from "../../data/Api";
import { useAuthContext } from "../../context/AuthProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import LabForm, { convertToFormData } from "./LabForm";
import useTranslation from "../../data/useTranslation";
import LabDto from "../../models/Lab";
import { Permission } from "../../models/Permissions";
import withPermission from "../../data/withPermission";

const EditLab: React.FC = () => {
  const { apiPostMultiForm } = useApi();
  const { resetLab } = useAuthContext();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const editLab = (lab: LabDto) =>
    apiPostMultiForm<boolean>("lab/update", convertToFormData(lab))
      .then(() => {
        resetLab(lab);
        showSuccessToast(t("editLab.updated"));
      })
      .catch(handleError);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("editLab.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol
              size-sm="10"
              offset-sm="1"
              size-md="6"
              offset-md="3"
              size-xl="4"
              offset-xl="4"
            >
              <LabForm submitButtonText={t("save")} onSubmit={editLab} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(EditLab, Permission.LabUpdate);
