import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  useIonViewWillEnter,
  IonLabel,
  IonItem,
  IonMenuButton,
  IonListHeader
} from "@ionic/react";
import React, { useState } from "react";
import {
  faBell,
  faEdit,
  faExchangeAlt,
  faNetworkWired,
  faPaperclip,
  faTooth,
  faUserEdit,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../context/AuthProvider";
import { useNotificationContext } from "../context/NotificationProvider";
import useApi from "../data/Api";
import LabDto from "../models/Lab";
import { Permission } from "../models/Permissions";
import Icon from "./Icon";
import Can from "./Can";
import useTranslation from "../data/useTranslation";
import LanguageSelect from "./LanguageSelect";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";

interface MenuButtonProps {
  url: string;
  icon: IconDefinition;
  title: string;
}

const MenuButton: React.FC<MenuButtonProps> = ({ icon, title, url }) => (
  <IonItem button detail routerLink={url}>
    <Icon slot="start" size="lg" color="white" icon={icon} />
    <IonLabel>{title}</IonLabel>
  </IonItem>
);

const Settings: React.FC = () => {
  const { lab } = useAuthContext();
  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const [labs, setLabs] = useState<LabDto[]>([]);
  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    apiGet<LabDto[]>("lab/getall").then(setLabs).catch(handleError);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("settings")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding settings-menu">
        <IonListHeader>{t("settings.titles.myProfile")}</IonListHeader>

        <MenuButton
          icon={faUserEdit}
          title={t("profile.editProfile")}
          url="/profile"
        />
        <MenuButton
          icon={faBell}
          title={t("notifications.title")}
          url="/notifications"
        />

        <IonListHeader>{t("settings.titles.myLab")}</IonListHeader>
        {lab && (
          <>
            <Can permission={Permission.LabUpdate}>
              <MenuButton
                icon={faEdit}
                title={t("editLab.title")}
                url="/edit-lab"
              />
            </Can>

            <Can permission={Permission.TaskRead}>
              <MenuButton
                icon={faNetworkWired}
                title={t("task.titlePlural")}
                url="/tasks"
              />
            </Can>
            {/* <Can permission={Permission.SchedulingTemplateRead}> */}
            <MenuButton
              icon={faNetworkWired}
              title={t("schedulingTemplate.titlePlural")}
              url="/scheduling-templates"
            />
            {/* </Can> */}

            <Can permission={Permission.ProductsRead}>
              <MenuButton
                icon={faTooth}
                title={t("products.title")}
                url="/products"
              />
              <MenuButton
                icon={faPaperclip}
                title={t("extras.title")}
                url="/extras"
              />
            </Can>
          </>
        )}

        <IonListHeader>{t("settings.titles.other")}</IonListHeader>
        <MenuButton
          icon={faPlusSquare}
          title={t("newLab.title")}
          url="/new-lab"
        />
        {labs.length > 1 && (
          <MenuButton
            icon={faExchangeAlt}
            title={t("changeLab.title")}
            url="/change-lab"
          />
        )}

        <LanguageSelect />
      </IonContent>
    </IonPage>
  );
};

export default Settings;
