import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  useIonViewWillEnter,
  IonBackButton,
  IonCol,
  IonGrid,
  IonRow,
  IonSkeletonText
} from "@ionic/react";
import React, { useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import useTranslation from "../../data/useTranslation";
import { RegisterData } from "../../models/User";
import EmailFormItem from "../form/EmailFormItem";
import Form from "../form/Form";
import FormInput from "../form/FormIonInput";

const Profile: React.FC = () => {
  const { apiGet, apiPost } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const [initialData, setInitialData] = useState<RegisterData>();
  const { t, tRequired, tPlaceholder } = useTranslation();

  useIonViewWillEnter(() => {
    apiGet<RegisterData>("user/get").then(setInitialData).catch(handleError);
  });

  const updateUser = (data: RegisterData) =>
    apiPost("user/update", data)
      .then(() => showSuccessToast(t("profile.updated")))
      .catch(handleError);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("profile.editProfile")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              size-sm="10"
              offset-sm="1"
              size-md="6"
              offset-md="3"
              size-xl="4"
              offset-xl="4"
            >
              {!initialData && (
                <IonSkeletonText animated title={t("loadingData")} />
              )}
              {initialData && (
                <Form
                  initialData={initialData}
                  submitButtonText={t("save")}
                  onSubmit={updateUser}
                >
                  <FormInput
                    name="firstName"
                    label={t("firstName")}
                    rules={{
                      required: tRequired("firstName")
                    }}
                    placeholder={tPlaceholder("firstName")}
                  />
                  <FormInput
                    name="lastName"
                    label={t("lastName")}
                    rules={{
                      required: tRequired("lastName")
                    }}
                    placeholder={tPlaceholder("lastName")}
                  />
                  <EmailFormItem disabled />
                </Form>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
