import React from "react";
import { IonSelect } from "@ionic/react";
import { RegisterOptions } from "react-hook-form";
import FormItem from "./FormItem";

export interface FormItemProps {
  name: string;
  label: string;
  rules?: RegisterOptions;
  hidden?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  interface?: "action-sheet" | "popover" | "alert";
  okText?: string;
  cancelText?: string;
  children: React.ElementType<any> | React.FunctionComponent<any> | any;
}

const FormIonSelect: React.FC<FormItemProps> = ({
  name,
  label,
  rules,
  hidden,
  disabled,
  children,
  ...props
}) => (
  <FormItem
    name={name}
    label={label}
    rules={rules}
    hidden={hidden}
    disabled={disabled}
    input={({ onChange, onBlur, ...rest }) => (
      <IonSelect
        onIonChange={e => onChange(e.detail.value)}
        onIonBlur={onBlur}
        {...rest}
        {...props}
      >
        {children}
      </IonSelect>
    )}
  />
);

export default FormIonSelect;
