import React from "react";
import { scheduledTimeColorClasses } from "../data/Helpers";
import useDate from "../hooks/useDate";

interface Props {
  date: string;
  format?: "datetime" | "date" | "time";
  ignoreColor?: boolean;
  children?: React.ReactNode;
  datePosition?: "before" | "after";
  customCSSClass?: string;
}

const ScheduledDate: React.FC<Props> = ({
  date,
  format,
  ignoreColor,
  children,
  datePosition,
  customCSSClass
}) => {
  const { toDateTimeString, toDateString, toTimeString } = useDate();
  const color = !ignoreColor
    ? scheduledTimeColorClasses(new Date(date)).colorClass
    : "";

  return (
    <span className={color + " " + customCSSClass}>
      {datePosition === "after" && children + " "}
      {format === "date"
        ? toDateString(date)
        : format === "time"
        ? toTimeString(date)
        : toDateTimeString(date)}
      {datePosition === "before" && " " + children}
    </span>
  );
};

export default ScheduledDate;
