export enum ProductType {
  Crown = 1,
  Bridge = 2,
  Denture = 3,
  Visil = 5,
  Miscellaneous = 6
}

export enum ToothCondition {
  NoTooth = 1,
  Implant = 2,
  Hook = 3,
  DeadTooth = 4
}
export interface ToothDto {
  id: number;
  condition?: ToothCondition;
  type?: ProductType;
  productId?: number;
  shade?: string;
}

export interface ToothProps extends ToothDto {
  name: string;
  selected: boolean;
  path: string;
  gum: string;
  nameTextTransform: string;
  condition?: number;
  deadTooth: string;
  implant: string;
}

export const getMatchingProductTypes = (type: ProductType) => {
  switch (type) {
    case ProductType.Crown:
    case ProductType.Bridge:
      return [ProductType.Crown, ProductType.Bridge];
    case ProductType.Denture:
      return [ProductType.Denture];
    case ProductType.Visil:
      return [ProductType.Visil];
    case ProductType.Miscellaneous:
      return [ProductType.Miscellaneous];
  }
};
