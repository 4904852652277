import { EventContentArg } from "@fullcalendar/core";
import { IonRouterLink } from "@ionic/react";
import React from "react";
import { dateDiffInDays, getPlColor, getCaseColor } from "../../data/Helpers";
import useTranslation from "../../data/useTranslation";
import useDate from "../../hooks/useDate";
import CalendarCaseDto, {
  CalendarProductionLogDto
} from "../../models/CalendarDto";
import { CaseStatus, ProductionLogStatus } from "../../models/Case";
import CaseFlagIcon from "../case/CaseFlagIcon";
import CaseStatusIcon from "../case/CaseStatusIcon";
import ProductionLogIcon from "../productionLog/ProductionLogIcon";

export const getCaseDotColor = (data: CalendarCaseDto) => {
  const today = new Date();
  const eventDate = new Date(data.date);

  const diffDays = dateDiffInDays(today, eventDate);

  if (data.tryoutId) {
    // tryout
    if (diffDays > 0) {
      if (!data.shipped) return "tryout-not-shipped-dot";
      return data.received ? "tryout-received-dot" : "tryout-shipped-dot";
    }
    if (diffDays === 0 || diffDays === -1) return "tryout-today-dot";
    return "tryout-dot";
  } else {
    // finish date
    if (diffDays > 0) {
      return [CaseStatus.Finished].includes(data.status)
        ? "finish-date-made-dot"
        : [
            CaseStatus.OnHold,
            CaseStatus.InProgress,
            CaseStatus.OutOfLab
          ].includes(data.status)
        ? "finish-date-missed-dot"
        : "";
    }
    if (diffDays === 0 || diffDays === -1) return "finish-date-today-dot";
    return "finish-date-dot";
  }
};

/*
PL = payment log
if status = null get PL where status = null and [created or finished or started or scheduled] >start && <end
if status = scheduled get PL where status = scheduled and [scheduled >start && <end]
if status = started get PL where status = started and ([started >start && <end] and scheduled = null) or (scheduled >start && <end)
if status = done or failed get by PL where  status = done (or failed) and ([finished >start && <end] and scheduled = null) or (scheduled >start && <end)

*/
const CalendarEvent: React.FC<EventContentArg> = React.memo(
  (e: EventContentArg) => {
    const { t, tCaseStatus, tProductionLogStatus } = useTranslation();
    const { toTimeString } = useDate();

    const caseData =
      e.event.extendedProps.type === "case_event"
        ? (e.event.extendedProps as CalendarCaseDto)
        : undefined;
    const plData =
      e.event.extendedProps.type === "pl_event"
        ? (e.event.extendedProps as CalendarProductionLogDto)
        : undefined;
    const colorClass = caseData ? getCaseColor(caseData)?.colorClass : "";

    return (
      <>
        {plData && (
          <>
            <div
              className={
                getPlColor({ ...plData }).colorClass + " fc-daygrid-event-dot"
              }
            ></div>

            <p className={getPlColor({ ...plData }).bgClass + " pl_event "}>
              {plData.status !== ProductionLogStatus.None && (
                <IonRouterLink>
                  <span className={`cal-event-title`}>
                    <ProductionLogIcon
                      status={plData.status}
                      taskId={plData.taskId}
                      scheduled={plData.scheduled}
                    />
                    {plData.task && plData.task.name}
                  </span>
                </IonRouterLink>
              )}

              {plData.status !== ProductionLogStatus.None && (
                <span className={`cal-event-sub-title ${colorClass}`}>
                  {`${toTimeString(plData.date)}`},{" "}
                  {tProductionLogStatus(plData.status)}
                </span>
              )}
              <span className={`cal-event-sub-title`}>
                {plData.status === ProductionLogStatus.None && (
                  <ProductionLogIcon
                    status={plData.status}
                    taskId={plData.taskId}
                    scheduled={plData.scheduled}
                  />
                )}
                {plData.notes && (
                  <i>
                    {plData.notes}
                    <br />
                  </i>
                )}
                {plData.user && (
                  <b>
                    {plData.user.firstName} {plData.user.lastName}
                  </b>
                )}
              </span>
              <IonRouterLink
                routerLink={"/case/" + plData.case.id}
                title={t("calendar.clickToOpenCase")}
              >
                <span
                  className={`cal-event-title smaller ion-margin-top`}
                  // className={`cal-event-title ${colorClass}`}
                  color="medium"
                  // title={t("clickToOpenCase")}
                >
                  <CaseFlagIcon flag={plData.case.flag} />
                  {plData.case.name}
                  <br />
                </span>
              </IonRouterLink>
              <span className="case-status ion-no-margin cal-event-sub-title">
                <CaseStatusIcon
                  {...plData.case}
                  underConstruction={
                    plData.case.status === CaseStatus.InProgress
                  }
                  title={tCaseStatus(plData.case.status)}
                />{" "}
                {tCaseStatus(plData.case.status)}
              </span>
            </p>
          </>
        )}
        {caseData && (
          <>
            <div
              className={`case_dot fc-daygrid-event-dot ${
                getCaseColor(caseData)?.colorClass
              }`}
            />
            <p
              className={
                (caseData.tryoutId ? "tryout_date" : "finish_date") +
                " case_event " +
                getCaseColor(caseData)?.bgClass
              }
            >
              <span className="ion-margin-bottom">
                {caseData.tryoutId ? (
                  <span className="cal-event-title">{t("cases.tryout")}</span>
                ) : (
                  <span className={`cal-event-title`}>
                    {t("dates.finishDate")}
                  </span>
                )}
                {caseData.tryoutId ? (
                  <span className={`cal-event-sub-title`}>
                    {`${toTimeString(caseData.date)}, ${caseData.tryoutName}`}
                  </span>
                ) : (
                  <span className={`cal-event-sub-title`}>
                    {toTimeString(caseData.date)}
                  </span>
                )}
              </span>

              <IonRouterLink
                routerLink={"/case/" + caseData.caseId}
                title={t("calendar.clickToOpenCase")}
              >
                <span
                  className={`cal-event-title smaller ion-margin-top`}
                  // className={`cal-event-title ${colorClass}`}
                  color="medium"
                  // title={t("clickToOpenCase")}
                >
                  <CaseFlagIcon flag={caseData.flag} />
                  {caseData.caseName}
                  <br />
                </span>
              </IonRouterLink>
              <span className="case-status ion-no-margin cal-event-sub-title">
                <CaseStatusIcon
                  {...caseData}
                  title={tCaseStatus(caseData.status)}
                />{" "}
                {tCaseStatus(caseData.status)}
              </span>

              <span className="cal-event-time-doctor">
                {caseData.doctorName}
                <i>{caseData.patient && `, ${caseData.patient}`}</i>
              </span>
            </p>
          </>
        )}
      </>
    );
  },
  (pe, ne) => {
    if (
      pe.event.extendedProps.type === "case_event" &&
      ne.event.extendedProps.type === "case_event"
    ) {
      const pData = pe.event.extendedProps as CalendarCaseDto;
      const nData = ne.event.extendedProps as CalendarCaseDto;
      return pData === nData;
    }
    if (
      pe.event.extendedProps.type === "pl_event" &&
      ne.event.extendedProps.type === "pl_event"
    ) {
      const pData = pe.event.extendedProps as CalendarProductionLogDto;
      const nData = ne.event.extendedProps as CalendarProductionLogDto;
      return pData === nData;
    }
    return false;
  }
);

export default CalendarEvent;
