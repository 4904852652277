import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import config from "./firebaseConfig";
import {
  unpackPermissionsFromString,
  containsPermission
} from "../models/Permissions";
import { User } from "../models/User";
import { appUrl } from "./Constants";

const app = firebase.initializeApp(config);

const firebaseAuth = () => app.auth();
const firebaseMessaging = firebase.messaging.isSupported() ? app.messaging() : undefined;
const firestore = app.firestore();

const signInWithGoogle = () =>
  firebaseAuth().signInWithPopup(new firebase.auth.GoogleAuthProvider());

const currentUser = () => firebaseAuth().currentUser;

const PackedPermissionClaimType = "https://rashtan-smile.com/permissions";
const LabIdClaimType = "https://rashtan-smile.com/lab";
const UserIdClaimType = "https://rashtan-smile.com/user";

const extractCustomClaims = async (
  user: firebase.User,
  forceRefresh?: boolean
) => {
  const token = await user.getIdTokenResult(forceRefresh);

  const packedPermissions: string = token.claims[PackedPermissionClaimType];
  const labId: number | undefined = token.claims[LabIdClaimType];
  const userId: number | undefined = token.claims[UserIdClaimType];
  return { packedPermissions, labId, userId };
};

const hasIdInToken = async (user: firebase.User) => {
  const { userId } = await extractCustomClaims(user);

  return userId ? true : false;
};

const extractUserInfo: (user: firebase.User) => Promise<User> = async user => {
  // force refresh the token to get the latest user info
  const { packedPermissions, labId, userId } = await extractCustomClaims(
    user,
    true
  );
  const permissions = unpackPermissionsFromString(packedPermissions);
  return {
    name: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    userId,
    permissions,
    labId,
    sendEmailVerification: (code: string | null) => {
      // we take the current user, because the user object that was passed in might be unavailable
      const u = currentUser();
      if (!u || u === null) return Promise.resolve();

      return u.sendEmailVerification({
        url: code ? `${appUrl}invitation?code=${code}` : `${appUrl}new-lab/`
        // TODO: Add options for opening Continue button in the app
      });
    },
    getIdToken: b => {
      // we take the current user, because the user object that was passed in might be unavailable
      const u = currentUser();
      if (!u || u === null) return Promise.resolve(undefined);
      return u.getIdToken(b);
    },
    hasPermission: p => containsPermission(permissions, p)
  };
};

export {
  firebaseMessaging,
  firestore,
  firebase,
  currentUser,
  firebaseAuth,
  extractUserInfo,
  hasIdInToken,
  signInWithGoogle
};
