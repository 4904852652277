import React from "react";
import { Permission } from "../models/Permissions";
import { useAuthContext } from "../context/AuthProvider";
import { Redirect } from "react-router";

const withPermission = <TProps,>(
  WrappedComponent: React.ComponentType | React.FC<TProps>,
  permission: Permission
) => (props: TProps) => {
  const { user, lab } = useAuthContext();

  // return component if the permission is there
  if (user && user.hasPermission(permission))
    return <WrappedComponent {...props} />;

  // user has not selected a lab, so they have to!
  if (!lab) return <Redirect to="/new-lab"></Redirect>;

  // otherwise just redirect to the main page
  return <Redirect to="/"></Redirect>;
};

export default withPermission;
