import {
  IonItem,
  IonSelect,
  IonLabel,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonSkeletonText,
  useIonViewWillEnter,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonToolbar
} from "@ionic/react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import React, { useCallback, useEffect, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { SortDirection } from "../../models/PagedData";
import useTranslation from "../../data/useTranslation";
import NoResults from "../NoResults";
import {
  ProductionLogTableRowDto,
  ProductionLogStatus,
  ProductionLogSummaryDto
} from "../../models/Case";
import { ProductionLogTableUrlProps } from "../../models/UrlProps";
import useDate from "../../hooks/useDate";
import Employee from "../../models/Employee";
import ProductionLogIcon, {
  productionLogStatusColor
} from "./ProductionLogIcon";
import { getEnumValues } from "../../data/Helpers";
import { TaskListDto } from "../../models/Task";
import useCurrency from "../../hooks/useCurrency";
import Icon from "../Icon";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { Pie } from "@ant-design/charts";
import ItemLabel from "../ItemLabel";
import { Select } from "antd";
import ProductionLogSummary from "./ProductionLogSummary";
import PopupDateRangePicker from "../PopupDateRangePicker";

const { Option } = Select;

const pieChartConfig = {
  appendPadding: 10,
  angleField: "count",
  colorField: "name",
  radius: 0.8,
  label: { type: "outer" },
  interactions: [{ type: "element-active" }]
};

interface Props {
  mode: "allLogs" | "doctorLogs" | "employeeLogs";
  initialUrlProps: ProductionLogTableUrlProps;
  showFilterProp: boolean;
  triggerSaveAsPdf?: number;
  setSavingPdf?: (v: boolean) => void;
}

const ProductionLogTable: React.FC<Props> = ({
  mode,
  initialUrlProps,
  showFilterProp,
  triggerSaveAsPdf,
  setSavingPdf
}) => {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [view, setView] = useState("table");
  const { apiPost, apiGet, apiBlobDownload } = useApi();
  const { handleError } = useNotificationContext();
  const [productionLogs, setProductionLogs] =
    useState<ProductionLogSummaryDto>();
  const [employees, setEmployees] = useState<Employee[]>();
  const [tasks, setTasks] = useState<TaskListDto[]>();
  const { t, tProductionLogStatus } = useTranslation();
  const { toDateString, toDateTimeString } = useDate();
  const { formatWithCurrencySign } = useCurrency();
  const history = useHistory();

  const [urlProps, setUrlProps] = useState<ProductionLogTableUrlProps>({
    ...initialUrlProps
  });

  useIonViewWillEnter(() => {
    fetchProductionLogs();
  }, [urlProps]);

  const saveAsPdf = useCallback(() => {
    setDownloading(true);
    apiBlobDownload("POST", `productionLog/SaveAsPdf`, urlProps)
      .catch(handleError)
      .finally(() => setDownloading(false));
  }, [urlProps]);

  useEffect(() => {
    triggerSaveAsPdf && triggerSaveAsPdf > 0 && saveAsPdf();
  }, [triggerSaveAsPdf]);

  useEffect(() => setSavingPdf && setSavingPdf(downloading), [downloading]);

  useEffect(() => {
    fetchProductionLogs();
  }, [urlProps]);

  useEffect(() => {
    apiGet<TaskListDto[]>(`task/getAll`).then(setTasks).catch(handleError);
    if (mode !== "employeeLogs")
      apiGet<Employee[]>(`employee/getAll`)
        .then(setEmployees)
        .catch(handleError);
  }, [mode]);

  const fetchProductionLogs = () => {
    setLoading(true);
    return apiPost<ProductionLogSummaryDto>(
      `productionLog/getFiltered`,
      urlProps
    )
      .then(setProductionLogs)
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const handleSort = (
    column: IDataTableColumn<ProductionLogTableRowDto>,
    sortDirection: "desc" | "asc"
  ) => {
    setUrlProps(urlProps => ({
      ...urlProps,
      sortDirection:
        sortDirection === "desc" ? SortDirection.Desc : SortDirection.Asc,
      sortBy: column.selector?.toString(),
      page: 1
    }));
  };

  const handlePageChange = (page: number) =>
    setUrlProps(urlProps => ({ ...urlProps, page: page }));

  const columns: IDataTableColumn<ProductionLogTableRowDto>[] = [
    {
      name: t("employee"),
      selector: "user",
      sortable: true,
      grow: 1,
      cell: row => row.userName,
      hide: "sm",
      omit: mode === "employeeLogs" || urlProps.employeeId !== null
    },
    {
      name: t("task.title"),
      selector: "name",
      sortable: true,
      grow: 3,
      cell: row => (
        <IonItem
          lines="none"
          class="ion-no-padding"
          onClick={() => history.push("/case/" + row.caseId)}
        >
          <ProductionLogIcon status={row.status} taskId={row.taskId} />
          <IonLabel>
            {!row.taskName ? "" : <b> {row.taskName}</b>}
            <span
              hidden={row.status === ProductionLogStatus.None}
              className={
                productionLogStatusColor(row.status) +
                " additional-status-label ion-hide-sm-up"
              }
            >
              {tProductionLogStatus(row.status)}
            </span>{" "}
            <span
              className="ion-hide-sm-up additional-status-label"
              hidden={mode === "employeeLogs"}
            >
              <i> ({row.userName})</i>
            </span>
            <p hidden={!row.notes}>
              <i> {row.notes}</i>
            </p>
            <p className="ion-hide-sm-up">{row.caseName}</p>
            <p className="ion-hide-sm-up smaller-font margin-top-6">
              {t("created") + ": " + toDateTimeString(row.created ?? "")}
            </p>
            {row.finished && (
              <p className="ion-hide-sm-up smaller-font">
                {t("finished") + ": " + toDateTimeString(row.finished)}
              </p>
            )}
            {row.scheduled && (
              <p className="ion-hide-sm-up smaller-font">
                {t("scheduled") + ": " + toDateTimeString(row.scheduled)}
              </p>
            )}
          </IonLabel>
        </IonItem>
      )
    },
    {
      name: t("status"),
      selector: "status",
      sortable: true,
      grow: 1,
      cell: row => tProductionLogStatus(row.status),
      hide: "md"
    },

    {
      name: t("created"),
      selector: "created",
      sortable: true,
      grow: 1,
      cell: row => toDateTimeString(row.created ?? ""),
      hide: "sm"
    },
    {
      name: t("finished"),
      selector: "finished",
      sortable: true,
      grow: 1,
      cell: row => row.finished && toDateTimeString(row.finished),
      hide: "sm"
    },
    {
      name: t("scheduling.scheduled"),
      selector: "scheduled",
      sortable: true,
      grow: 1,
      cell: row => row.scheduled && toDateTimeString(row.scheduled),
      hide: "sm"
    },
    {
      name: t("case"),
      selector: "case",
      sortable: true,
      grow: 1,
      cell: row => row.caseName,
      hide: "sm"
    }
    // {
    //   name: t("task.pieceRate"),
    //   selector: "pieceRate",
    //   sortable: true,
    //   grow: 1,
    //   cell: row =>
    //     row.finished &&
    //     row.status === ProductionLogStatus.Done &&
    //     formatWithCurrencySign(row.bonus),
    //   hide: "sm"
    // }
  ];
  return (
    <IonGrid>
      <IonToolbar>
        <IonSegment value={view} mode="ios">
          <IonSegmentButton value="table" onClick={() => setView("table")}>
            {t("table")}
          </IonSegmentButton>
          <IonSegmentButton value="summary" onClick={() => setView("summary")}>
            {t("summary")}
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>
      <IonRow hidden={!showFilterProp}>
        <IonCol hidden={mode === "employeeLogs"}>
          <ItemLabel label={t("employee")}>
            <Select
              size="large"
              // mode="multiple"
              defaultValue={
                urlProps.employeeId !== null ? urlProps.employeeId : ""
              }
              onChange={e => {
                setUrlProps(urlProps => ({
                  ...urlProps,
                  employeeId: e === "" ? null : (e as number)
                }));
              }}
            >
              <Option value="">{t("all")}</Option>
              {employees?.map(e => (
                <Option key={e.id} value={e.id}>
                  {`${e.firstName} ${e.lastName}`}
                </Option>
              ))}
            </Select>
          </ItemLabel>
          <IonItem hidden lines="none" className="ion-margin-none">
            <IonLabel position="stacked">{t("employee")}</IonLabel>
            <IonSelect
              interface="action-sheet"
              cancelText={t("cancel")}
              value={urlProps.employeeId}
              onIonChange={e =>
                setUrlProps(urlProps => ({
                  ...urlProps,
                  employeeId: e.detail.value
                }))
              }
            >
              <IonSelectOption key={0} value={null}>
                {t("all")}
              </IonSelectOption>
              {employees?.map(e => (
                <IonSelectOption key={e.id} value={e.id}>
                  {`${e.firstName} ${e.lastName}`}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonCol>
        <IonCol>
          <ItemLabel label={t("task.title")}>
            <Select
              size="large"
              // mode="multiple"
              defaultValue={urlProps.taskId !== null ? urlProps.taskId : ""}
              onChange={e => {
                setUrlProps(urlProps => ({
                  ...urlProps,
                  taskId: e === "" ? null : (e as number)
                }));
              }}
            >
              <Option value="">{t("all")}</Option>
              {tasks?.map(step => (
                <Option key={step.id} value={step.id}>
                  {step.name}
                </Option>
              ))}
            </Select>
          </ItemLabel>

          <IonItem hidden lines="none" className="ion-margin-none">
            <IonLabel position="stacked">{t("task.title")}</IonLabel>
            <IonSelect
              interface="action-sheet"
              cancelText={t("cancel")}
              value={urlProps.taskId}
              onIonChange={e =>
                setUrlProps(urlProps => ({
                  ...urlProps,
                  taskId: e.detail.value
                }))
              }
            >
              <IonSelectOption value={null}>{t("all")}</IonSelectOption>
              {tasks?.map(step => (
                <IonSelectOption key={step.id} value={step.id}>
                  {step.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonCol>
        <IonCol>
          <ItemLabel label={t("status")}>
            <Select
              size="large"
              // mode="multiple"
              defaultValue={urlProps.status !== null ? urlProps.status : ""}
              onChange={e => {
                setUrlProps(urlProps => ({
                  ...urlProps,
                  status: e === "" ? null : (e as ProductionLogStatus)
                }));
              }}
            >
              <Option value="">{t("all")}</Option>
              {getEnumValues(ProductionLogStatus).map(t => (
                <Option key={t} value={t}>
                  {tProductionLogStatus(t)}
                </Option>
              ))}
            </Select>
          </ItemLabel>
          <IonItem hidden lines="none" className="ion-margin-none">
            <IonLabel position="stacked">{t("status")}</IonLabel>
            <IonSelect
              interface="action-sheet"
              cancelText={t("cancel")}
              value={urlProps.status}
              onIonChange={e =>
                setUrlProps(urlProps => ({
                  ...urlProps,
                  status: e.detail.value!
                }))
              }
            >
              <IonSelectOption value={null}>{t("all")}</IonSelectOption>
              {getEnumValues(ProductionLogStatus).map(status => (
                <IonSelectOption key={status} value={status}>
                  {tProductionLogStatus(status)}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonCol>
        <IonCol>
          <ItemLabel label={t("dates.chooseDate")}>
            <PopupDateRangePicker
              onChange={({ start, end }) =>
                setUrlProps(urlProps => ({
                  ...urlProps,
                  fromDate: start,
                  toDate: end
                }))
              }
            />
          </ItemLabel>
        </IonCol>
        <IonCol hidden>
          <IonItem lines="none" className="ion-margin-none">
            <IonLabel position="stacked">{t("dates.fromDate")}</IonLabel>
            <IonDatetime
              displayFormat="DD MMM YYYY"
              placeholder={t("dates.chooseDate")}
              value={urlProps.fromDate}
              onIonChange={e =>
                setUrlProps(urlProps => ({
                  ...urlProps,
                  fromDate: e.detail.value!
                }))
              }
            />
          </IonItem>
        </IonCol>
        <IonCol hidden>
          <IonItem lines="none" className="ion-margin-none">
            <IonLabel position="stacked">{t("dates.toDate")}</IonLabel>
            <IonDatetime
              displayFormat="DD MMM YYYY"
              placeholder={t("dates.chooseDate")}
              value={urlProps.toDate}
              onIonChange={e =>
                setUrlProps(urlProps => ({
                  ...urlProps,
                  toDate: e.detail.value!
                }))
              }
            />
          </IonItem>
        </IonCol>
      </IonRow>

      {!productionLogs && loading && (
        <IonSkeletonText animated title={t("loading")} />
      )}
      {productionLogs && (
        <>
          {view === "table" && (
            <DataTable
              className="box lower-top-padding"
              noHeader
              pointerOnHover
              columns={columns}
              data={productionLogs.data.data}
              progressPending={loading}
              progressComponent={
                <IonSkeletonText animated title={t("loading")} />
              }
              pagination
              paginationServer
              paginationTotalRows={productionLogs.data.count}
              defaultSortField="created"
              defaultSortAsc={false}
              onChangeRowsPerPage={rows =>
                setUrlProps(urlProps => ({ ...urlProps, pageSize: rows }))
              }
              onRowClicked={row => history.push("/case/" + row.caseId)}
              noDataComponent={<NoResults title={t("noRecords")} />}
              sortServer
              onSort={handleSort}
              onChangePage={handlePageChange}
              paginationComponentOptions={{
                rowsPerPageText: t("dataTable.rowsPerPageText"),
                rangeSeparatorText: t("dataTable.rangeSeparatorText")
              }}
            />
          )}

          {productionLogs.data.data.length === 0 && !loading && (
            <NoResults title={t("noData")} />
          )}
          {productionLogs.data.data.length > 0 &&
            view === "summary" &&
            !loading && (
              <>
                <ProductionLogSummary
                  data={productionLogs}
                  urlProps={urlProps}
                  employees={employees}
                />
                <IonRow class="box" hidden={!productionLogs.tasks.length}>
                  <IonCol size-xs="12" size-md="12">
                    <h3 className="ion-text-center">
                      {t("workLog.totalPieceRate")}
                    </h3>
                    <Pie
                      {...pieChartConfig}
                      angleField="total"
                      data={productionLogs.tasks.filter(v => v.total)}
                      meta={{
                        total: {
                          alias: t("workLog.totalPieceRate"),
                          formatter: v => formatWithCurrencySign(v)
                        },
                        name: { alias: t("task.title") }
                      }}
                      legend={{
                        layout: "horizontal",
                        position: "top"
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow class="box" hidden={!productionLogs.tasks.length}>
                  <IonCol size-xs="12" size-md="12">
                    <h3 className="ion-text-center">
                      {t("workLog.finishedSteps")}
                    </h3>
                    <Pie
                      {...pieChartConfig}
                      data={productionLogs.tasks}
                      meta={{
                        count: { alias: t("workLog.finishedSteps") },
                        name: { alias: t("task.title") }
                      }}
                      legend={{
                        layout: "horizontal",
                        position: "top"
                      }}
                    />
                  </IonCol>
                </IonRow>
              </>
            )}

          {productionLogs.data.data.length > 0 && !loading && (
            <div className="ion-text-center ion-margin-top">
              <IonButton
                fill="outline"
                onClick={saveAsPdf}
                disabled={downloading}
              >
                <Icon
                  icon={downloading ? faSpinner : faDownload}
                  spin={downloading}
                />
                {t("saveAsPdf")}
              </IonButton>
            </div>
          )}
        </>
      )}
    </IonGrid>
  );
};

export default ProductionLogTable;
