import {
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  useIonViewWillEnter,
  IonText,
  IonSkeletonText
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";

import { faNetworkWired, faPlus } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import { Permission } from "../../models/Permissions";
import withPermission from "../../data/withPermission";
import Can from "../Can";
import SearchPage from "../SearchPage";
import useTranslation from "../../data/useTranslation";
import NoResults from "../NoResults";
import { TaskListDto } from "../../models/Task";
import TaskUpsertModal from "./TaskUpsertModal";

const Tasks: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [tasks, setTasks] = useState<TaskListDto[]>();

  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const fetchData = () => {
    setTasks(undefined);
    apiGet<TaskListDto[]>(`task/getAll?searchTerm=${searchTerm}`)
      .then(data => {
        setTasks(data);
      })
      .catch(handleError);
  };

  useIonViewWillEnter(() => fetchData());

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  return (
    <IonPage>
      <SearchPage
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        title={t("task.titlePlural")}
        addNewPermission={Permission.TaskCreate}
        onAddClicked={() => setShowAddModal(true)}
        hideMenuButton
      >
        <IonList className="box lines" hidden={!tasks?.length}>
          {tasks ? (
            tasks.map((d, i) => (
              <IonItem button detail key={i} routerLink={"/task/" + d.id}>
                <Icon slot="start" icon={faNetworkWired} />
                <IonLabel className="ion-text-wrap">
                  <IonText title={d.name}>{d.name}</IonText>
                  <IonText color="medium">
                    <p>{d.description}</p>
                  </IonText>
                </IonLabel>
              </IonItem>
            ))
          ) : (
            <IonSkeletonText animated />
          )}
        </IonList>

        <Can permission={Permission.TaskCreate}>
          <NoResults
            title={tasks && tasks.length === 0 ? t("task.noData") : ""}
          >
            <IonButton color="secondary" onClick={() => setShowAddModal(true)}>
              <Icon icon={faPlus} />
              {t("task.new")}
            </IonButton>
          </NoResults>
        </Can>

        <TaskUpsertModal
          initialData={{ id: 0, name: "" }}
          showTaskModal={showAddModal}
          onSuccess={() => {
            fetchData();
            showSuccessToast(t("task.added"));
            setShowAddModal(false);
          }}
          onCancel={() => setShowAddModal(false)}
        />
      </SearchPage>
    </IonPage>
  );
};

export default withPermission(Tasks, Permission.TaskRead);
