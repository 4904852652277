import React, { useEffect, useReducer, useRef, useState } from "react";
import ToothComp from "./Tooth";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import archesReducer from "./archesReducer";
import useDentalNotation from "../../hooks/useDentalNotation";
import CaseViewDto, { CaseFormDto } from "../../models/Case";
import ProductList from "./ProductList";
import { ProductViewDto } from "../../models/Product";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import BridgeConnection from "./BridgeConnection";
import { ProductType } from "../../models/Teeth";
import { BridgeSvgPaths } from "../../data/arches/SvgPaths";

interface Props {
  $case: CaseViewDto | CaseFormDto;
  exposeGetSvg: (getSvg: () => string | undefined) => void;
}

const ArchesView: React.FC<Props> = ({ $case, exposeGetSvg }) => {
  const [labProducts, setLabProducts] = useState<ProductViewDto[]>();
  const { teeth: starterTeeth } = useDentalNotation();
  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const svgRef = useRef<SVGSVGElement>(null);
  const [{ teeth, products }, dispatch] = useReducer(archesReducer, {
    teeth: [],
    products: $case.products,
    teethConditions: $case.teethCondition,
    anySelected: false,
    productSelected: 0,
    anyConditionSelected: false,
    canBuildBridge: false
  });

  useEffect(() => {
    apiGet<ProductViewDto[]>(`product/getAll`)
      .then(setLabProducts)
      .catch(handleError);
  }, []);

  useEffect(() => {
    dispatch({
      type: "reset",
      data: {
        teeth: starterTeeth,
        anySelected: false,
        productSelected: 0,
        anyConditionSelected: false,
        canBuildBridge: false,
        products: $case.products ?? [],
        teethConditions: $case.teethCondition
      }
    });
  }, [starterTeeth, $case.products, $case.teethCondition]);

  useEffect(() => {
    if (exposeGetSvg) exposeGetSvg(() => svgRef.current?.outerHTML);
  }, [exposeGetSvg]);

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol
            size-xs="12"
            size-sm="12"
            size-md="6"
            size-xl="3"
            className="ion-no-padding"
          >
            <svg
              ref={svgRef}
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 535 712"
            >
              {products
                .filter(p => p.productTypeId === ProductType.Bridge)
                .map(bridge =>
                  bridge.toothIds.map((t, i) => (
                    <BridgeConnection
                      key={bridge.toothIds[i] + bridge.toothIds[i + 1]}
                      path={
                        BridgeSvgPaths[
                          bridge.toothIds[i] + bridge.toothIds[i + 1]
                        ]
                      }
                    />
                  ))
                )}
              {teeth.map(tooth => (
                <ToothComp key={tooth.id} {...tooth} onClick={() => {}} />
              ))}
            </svg>
          </IonCol>
          <IonCol
            size-xs="12"
            size-sm="12"
            size-md="6"
            size-xl="9"
            className="ion-no-padding"
          >
            <ProductList
              $case={$case}
              showActions={false}
              labProducts={labProducts}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default ArchesView;
