import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import DoctorFormDto from "../../models/Doctor";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import Form from "../form/Form";
import EmailFormItem from "../form/EmailFormItem";
import useTranslation from "../../data/useTranslation";
import ButtonTextIcon from "../ButtonTextIcon";
import ModalWrapper from "../ModalWrapper";
import FormInput from "../form/FormIonInput";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showDoctorModal: boolean;
  initialData: DoctorFormDto;
}
const DoctorUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showDoctorModal
}) => {
  const [submit, setSubmit] = useState<() => void>(() => {});
  const [isSubmitting, setSubmitting] = useState(false);
  const { apiPost } = useApi();
  const { t, tRequired, tPlaceholder } = useTranslation();

  const { handleError } = useNotificationContext();

  const upsert = (data: DoctorFormDto) =>
    apiPost<number>(data.id === 0 ? "doctor/create" : "doctor/update", data)
      .then(id => onSuccess(id))
      .catch(handleError);

  return (
    <ModalWrapper
      modalOpened={showDoctorModal}
      dismiss={onCancel}
      modal="doctorUpsert"
    >
      <IonModal isOpen={showDoctorModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {initialData.id > 0 ? t("editDoctor") : t("doctors.new")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={submit} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Form
            initialData={initialData}
            onSubmit={upsert}
            submitButtonText={t("save")}
            exposeSubmit={setSubmit}
            setSubmitting={setSubmitting}
          >
            <FormInput name="id" label="Id" hidden />
            <FormInput
              name="name"
              label={t("name")}
              rules={{
                required: tRequired("name")
              }}
              placeholder={tPlaceholder("name")}
              clearInput
            />
            <FormInput
              name="clinicName"
              label={t("clinicName")}
              placeholder={tPlaceholder("clinicName")}
              clearInput
            />
            <EmailFormItem />

            <FormInput
              name="phone"
              label={t("phone")}
              placeholder={tPlaceholder("phone")}
              clearInput
            />
            <FormInput
              name="website"
              label={t("website")}
              placeholder={tPlaceholder("website")}
              clearInput
            />
            <FormInput
              name="address"
              label={t("address")}
              placeholder={tPlaceholder("address")}
              clearInput
            />
          </Form>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default DoctorUpsertModal;
