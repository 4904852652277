import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonList,
  IonRow,
  IonSkeletonText
} from "@ionic/react";
import useApi from "../../data/Api";
import useTranslation from "../../data/useTranslation";
import ProductionLogUpsertModal from "./ProductionLogUpsertModal";
import {
  CaseStatus,
  ProductionLogFormDto,
  ProductionLogStatus,
  ProductionLogListDto
} from "../../models/Case";
import { faClipboardList, faPlus } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import { useNotificationContext } from "../../context/NotificationProvider";
import SchedulingUpsertModal from "../case/SchedulingUpsertModal";
import { SchedulingTaskDto } from "../../models/Task";
import ProductionLogItem from "./ProductionLogItem";

interface Props {
  caseId: number;
  caseStatus: CaseStatus;
}
const ProductionLog: React.FC<Props> = ({ caseId, caseStatus }) => {
  const [showProductionLogModal, setShowProductionLogModal] = useState(false);
  const [showSchedulingModal, setShowSchedulingModal] = useState(false);
  const [schedulingModalInitialData, setSchedulingModalInitialData] = useState<
    SchedulingTaskDto[]
  >([]);
  const [showProductionLogDeleteAlert, setShowProductionLogDeleteAlert] =
    useState(false);
  const [productionLogForDelete, setProductionLogForDelete] =
    useState<number>();
  const [productionLogInitialData, setProductionLogInitialData] =
    useState<ProductionLogFormDto>({
      id: 0,
      userId: 0,
      caseId: caseId,
      taskId: null,
      notes: "",
      status: ProductionLogStatus.Started
    });

  const [logs, setLogs] = useState<ProductionLogListDto[]>();
  const [scheduledLogs, setScheduledLogs] = useState<ProductionLogListDto[]>(
    []
  );
  const [currentLogs, setCurrentLogs] = useState<ProductionLogListDto[]>([]);
  const { t } = useTranslation();
  const { apiGet, apiDelete } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();

  const fetchLogs = () =>
    apiGet<ProductionLogListDto[]>(`productionLog/getAll?caseId=${caseId}`)
      .then(setLogs)
      .catch(handleError);

  useEffect(() => {
    fetchLogs();
  }, [caseId]);

  useEffect(() => {
    logs &&
      setScheduledLogs(
        logs
          .filter(l => l.status === ProductionLogStatus.Scheduled)
          .sort((a, b) => (a.id > b.id ? 1 : -1))
      );
    logs &&
      setCurrentLogs(
        logs.filter(l => l.status !== ProductionLogStatus.Scheduled)
      );
  }, [logs]);

  const editProductionLog = (l: ProductionLogFormDto) => {
    setProductionLogInitialData(l);
    setShowProductionLogModal(true);
  };
  const deleteProductionLog = (id: number) => {
    setProductionLogForDelete(id);
    setShowProductionLogDeleteAlert(true);
  };

  return (
    <>
      {logs ? (
        <IonGrid>
          <IonRow>
            <IonCol hidden={!scheduledLogs.length}>
              <h3>{t("scheduling.scheduledTasks")}</h3>
              <IonList>
                {scheduledLogs.map(l => (
                  <ProductionLogItem
                    key={l.id}
                    caseStatus={caseStatus}
                    editProductionLog={editProductionLog}
                    deleteProductionLog={deleteProductionLog}
                    {...l}
                  />
                ))}
              </IonList>
            </IonCol>
            <IonCol>
              <h3>{t("workLog.title")}</h3>
              <IonList>
                {currentLogs.map(l => (
                  <ProductionLogItem
                    key={l.id}
                    caseStatus={caseStatus}
                    editProductionLog={editProductionLog}
                    deleteProductionLog={deleteProductionLog}
                    {...l}
                  />
                ))}
              </IonList>
            </IonCol>
          </IonRow>
          <div className="ion-margin-top ion-text-center">
            <IonButton
              color="secondary"
              onClick={() =>
                editProductionLog({
                  id: 0,
                  userId: 0,
                  caseId: caseId,
                  taskId: null,
                  notes: "",
                  status: ProductionLogStatus.Started
                })
              }
            >
              <Icon icon={faPlus} /> {t("workLog.add")}
            </IonButton>
            <IonButton
              onClick={() => {
                setSchedulingModalInitialData(scheduledLogs);
                setShowSchedulingModal(true);
              }}
            >
              <Icon icon={faClipboardList} /> {t("workLog.scheduleTasks")}
            </IonButton>
          </div>
        </IonGrid>
      ) : (
        <IonSkeletonText animated />
      )}

      <ProductionLogUpsertModal
        showProductionLogModal={showProductionLogModal}
        initialData={productionLogInitialData}
        onSuccess={() => {
          setShowProductionLogModal(false);
          fetchLogs();
        }}
        onCancel={() => {
          setShowProductionLogModal(false);
        }}
      />
      <SchedulingUpsertModal
        showSchedulingModal={showSchedulingModal}
        caseId={caseId}
        initialData={schedulingModalInitialData}
        onSuccess={() => {
          setShowSchedulingModal(false);
          fetchLogs();
        }}
        onCancel={() => {
          setShowSchedulingModal(false);
        }}
      />
      <IonAlert
        isOpen={showProductionLogDeleteAlert}
        onDidDismiss={() => setShowProductionLogDeleteAlert(false)}
        header={t("confirm")}
        message={t("workLog.confirmDelete")}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              setShowProductionLogDeleteAlert(false);
            }
          },
          {
            text: t("delete"),
            handler: () => {
              apiDelete("productionLog/delete?id=" + productionLogForDelete)
                .then(() => {
                  showSuccessToast(t("workLog.logDeleted"));
                })
                .catch(handleError);
            }
          }
        ]}
      />
    </>
  );
};

export default ProductionLog;
