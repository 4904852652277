import {
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  useIonViewWillEnter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonText,
  IonSkeletonText
} from "@ionic/react";
import React, { useState } from "react";
import useApi from "../../data/Api";
import DoctorFormDto from "../../models/Doctor";
import { useNotificationContext } from "../../context/NotificationProvider";

import { faPlus, faUserTie } from "@fortawesome/free-solid-svg-icons";
import Icon from "../../components/Icon";
import { Permission } from "../../models/Permissions";
import withPermission from "../../data/withPermission";
import Can from "../Can";
import SearchPage from "../../components/SearchPage";
import PagedData from "../../models/PagedData";
import useTranslation from "../../data/useTranslation";
import DoctorUpsertModal from "./DoctorUpsertModal";
import NoResults from "../NoResults";

const Doctors: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [hideInfiniteScroll, setHideInfiniteScroll] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [doctors, setDoctors] = useState<DoctorFormDto[]>();

  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const fetchDoctors = (page: number, searchTerm: string) =>
    apiGet<PagedData<DoctorFormDto>>(
      `doctor/getAll?page=${page}&pageSize=50&search=${searchTerm}`
    )
      .then(data => {
        if (data.count === 0) {
          setDoctors([]);
          setHideInfiniteScroll(true);
        } else {
          setDoctors(
            page === 1 || !doctors ? data.data : [...doctors, ...data.data]
          );
          setHideInfiniteScroll(data.page === data.pageCount);
        }
      })
      .catch(handleError);

  const searchNext = async (e: CustomEvent<void>) => {
    setPage(page + 1);
    await fetchDoctors(page + 1, searchTerm);

    // we have to call complete
    (e.target as HTMLIonInfiniteScrollElement).complete();
  };

  useIonViewWillEnter(() => fetchDoctors(page, searchTerm));

  return (
    <IonPage>
      <SearchPage
        searchTerm={searchTerm}
        setSearchTerm={s => {
          setSearchTerm(s);
          setPage(1);
          fetchDoctors(1, s);
        }}
        title={t("doctors.title")}
        addNewPermission={Permission.DoctorCreate}
        onAddClicked={() => setShowAddModal(true)}
        noDefaultBackLink
      >
        <IonList className="box lines">
          {doctors ? (
            doctors.map((d, i) => (
              <IonItem button detail key={i} routerLink={"/doctor/" + d.id}>
                <Icon slot="start" icon={faUserTie} />
                <IonLabel className="ion-text-wrap">
                  <IonText title={d.name}>{d.name}</IonText>
                  <IonText color="medium">
                    <p>
                      {d.clinicName}
                      {d.email ? `, ${d.email}` : ""}
                      {d.website ? `, ${d.website}` : ""}
                    </p>
                  </IonText>
                </IonLabel>
              </IonItem>
            ))
          ) : (
            <IonSkeletonText animated />
          )}
        </IonList>
        <IonInfiniteScroll
          threshold="10%"
          disabled={hideInfiniteScroll}
          onIonInfinite={searchNext}
        >
          <IonInfiniteScrollContent loadingText={t("loadingMore")} />
        </IonInfiniteScroll>

        <Can permission={Permission.DoctorCreate}>
          <NoResults
            title={doctors && doctors.length === 0 ? t("doctors.noData") : ""}
          >
            <IonButton color="secondary" onClick={() => setShowAddModal(true)}>
              <Icon icon={faPlus} />
              {t("doctors.new")}
            </IonButton>
          </NoResults>
        </Can>

        <DoctorUpsertModal
          initialData={{ id: 0, name: "", clinicName: "", email: "" }}
          showDoctorModal={showAddModal}
          onSuccess={() => {
            fetchDoctors(page, searchTerm);
            showSuccessToast(t("doctors.added"));
            setShowAddModal(false);
          }}
          onCancel={() => setShowAddModal(false)}
        />
      </SearchPage>
    </IonPage>
  );
};

export default withPermission(Doctors, Permission.DoctorRead);
