import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonTextarea,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { faEdit, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import ButtonTextIcon from "../ButtonTextIcon";
import { ProductionLogFormDto, ProductionLogStatus } from "../../models/Case";
import { TaskListDto } from "../../models/Task";
import TaskUpsertModal from "../task/TaskUpsertModal";
import Icon from "../Icon";
import InfoBox from "../InfoBox";
import ValueLabel from "../ValueLabel";
import useDate from "../../hooks/useDate";
import ProductionLogIcon from "../productionLog/ProductionLogIcon";
import Can from "../Can";
import { Permission } from "../../models/Permissions";
import ModalWrapper from "../ModalWrapper";

interface ProductionLogData extends ProductionLogFormDto {
  taskName?: string;
  userName?: string;
}

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showProductionLogModal: boolean;
  initialData: ProductionLogData;
}

const ProductionLogUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showProductionLogModal
}) => {
  const [loading, setLoading] = useState(false);
  const [gettingTasks, setGettingTasks] = useState(false);
  const [formData, setFormData] = useState<ProductionLogFormDto>(initialData);
  const [tasks, setTasks] = useState<TaskListDto[]>([]);
  const { apiPost, apiGet } = useApi();
  const { t, tProductionLogStatus } = useTranslation();
  const { toDateTimeString } = useDate();

  const { handleError } = useNotificationContext();

  const [showTaskModal, setShowTaskModal] = useState(false);

  const fetchSteps = () => {
    setGettingTasks(true);

    return apiGet<TaskListDto[]>(`task/getAll`)
      .then(setTasks)
      .catch(handleError)
      .finally(() => setGettingTasks(false));
  };

  const upsert = () => {
    setLoading(true);
    apiPost<number>("productionLog/upsert", formData)
      .then(id => onSuccess(id))
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  useEffect(() => {
    fetchSteps();
  }, []);

  return (
    <ModalWrapper
      modalOpened={showProductionLogModal}
      dismiss={onCancel}
      modal="plUpsert"
    >
      <IonModal isOpen={showProductionLogModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {formData.id > 0 ? t("workLog.update") : t("workLog.add")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={upsert} disabled={loading}>
                <ButtonTextIcon
                  button={formData.id > 0 ? "save" : "add"}
                  loading={loading}
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div hidden={initialData.id === 0 || !initialData.taskId}>
            <ValueLabel title={t("task.title")}>
              {initialData.taskName}
            </ValueLabel>
          </div>
          <div
            hidden={
              initialData.id === 0 ||
              !initialData.taskId ||
              initialData.status === ProductionLogStatus.Started ||
              initialData.status === ProductionLogStatus.Scheduled
            }
          >
            <ValueLabel title={t("status")}>
              <ProductionLogIcon
                status={initialData.status}
                taskId={initialData.taskId}
              />{" "}
              {tProductionLogStatus(initialData.status)}
            </ValueLabel>
          </div>
          {initialData.created && initialData.taskId === null && (
            <ValueLabel title={t("date")}>
              {toDateTimeString(initialData.created)}
            </ValueLabel>
          )}
          {initialData.started && (
            <ValueLabel title={t("productionLogStatus.1")}>
              {toDateTimeString(initialData.started)}
            </ValueLabel>
          )}
          {initialData.finished && (
            <ValueLabel title={t("productionLogStatus.2")}>
              {toDateTimeString(initialData.finished)}
            </ValueLabel>
          )}
          {gettingTasks ? (
            <IonSkeletonText animated title={t("loading")} />
          ) : (
            <IonRow className="ion-align-items-center">
              <IonCol>
                <IonItem
                  lines="none"
                  hidden={initialData.id > 0 || !tasks.length}
                >
                  <IonLabel position="stacked">{t("task.title")}</IonLabel>
                  <IonSelect
                    value={formData.taskId}
                    placeholder="Select One"
                    interface="action-sheet"
                    cancelText={t("cancel")}
                    onIonChange={e => {
                      setFormData({
                        ...formData,
                        taskId: e.detail.value
                      });
                    }}
                  >
                    <IonSelectOption key="null" value={null}>
                      {t("none")}
                    </IonSelectOption>

                    {tasks
                      .sort((a, b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) return -1;
                        else if (nameA > nameB) return 1;
                        return 0;
                      })
                      .map(d => (
                        <IonSelectOption key={d.id} value={d.id}>
                          {d.name}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <Can permission={Permission.TaskCreate}>
                <IonCol size="auto" hidden={initialData.id > 0}>
                  <IonButton
                    // size="small"
                    color="secondary"
                    fill="outline"
                    className="ion-margin-top"
                    onClick={() => setShowTaskModal(true)}
                  >
                    <Icon icon={faPlus} />
                    {t("task.new")}
                  </IonButton>
                </IonCol>
              </Can>
            </IonRow>
          )}
          <IonRadioGroup
            hidden={
              !formData.taskId ||
              (initialData.id > 0 &&
                initialData.taskId !== null &&
                initialData.status !== ProductionLogStatus.Scheduled &&
                initialData.status !== ProductionLogStatus.Started)
            }
            value={formData.status}
            onIonChange={e =>
              setFormData({
                ...formData,
                status: e.detail.value!
              })
            }
          >
            <IonLabel position="stacked">{t("status")}</IonLabel>

            <IonItem>
              <IonLabel> {t("workLog.startedWorkingOnIt")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Started} />
            </IonItem>

            <IonItem
              disabled={
                initialData.id > 0 &&
                initialData.status !== ProductionLogStatus.Started &&
                initialData.status !== ProductionLogStatus.Scheduled
              }
            >
              <IonLabel> {t("workLog.iFinishedTask")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Done} />
            </IonItem>

            <IonItem
              className="ion-margin-top"
              disabled={
                initialData.id > 0 &&
                initialData.status !== ProductionLogStatus.Started &&
                initialData.status !== ProductionLogStatus.Scheduled
              }
            >
              <IonLabel color="danger"> {t("workLog.taskFailed")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Failed} />
            </IonItem>
          </IonRadioGroup>

          <IonItem lines="none">
            <IonLabel position="stacked">{t("note")}</IonLabel>
            <IonTextarea
              onIonChange={e =>
                setFormData({
                  ...formData,
                  notes: e.detail.value!
                })
              }
              required={!formData.taskId}
              value={formData.notes}
              placeholder={t("note")}
            />
          </IonItem>
          <IonButton
            onClick={upsert}
            className="ion-margin-top"
            expand="block"
            color="success"
            disabled={loading}
          >
            {loading ? (
              <Icon spin icon={faSpinner} />
            ) : (
              <Icon icon={formData.id > 0 ? faEdit : faPlus} />
            )}
            {formData.id > 0 ? t("save") : t("add")}
          </IonButton>
          <InfoBox hidden={tasks.length > 0} text={t("task.noDataInfo")} />
        </IonContent>
        <Can permission={Permission.TaskCreate}>
          <TaskUpsertModal
            initialData={{ id: 0, name: "" }}
            showTaskModal={showTaskModal}
            onSuccess={d => {
              fetchSteps();
              setFormData(formData => ({ ...formData, taskId: d }));
              //fetchData();
              //showSuccessToast(t("task.added"));
              setShowTaskModal(false);
            }}
            onCancel={() => setShowTaskModal(false)}
          />
        </Can>
      </IonModal>
    </ModalWrapper>
  );
};

export default ProductionLogUpsertModal;
