import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Icon from "./Icon";

interface Props {
  children?: React.ReactNode;
  text: string;
  hidden?: boolean;
}

const InfoBox: React.FC<Props> = ({ children, text, hidden }) => {
  return (
    <div hidden={hidden} className="ion-text-center ion-margin-top border">
      <p hidden={!text.length} className="opacity-07">
        <Icon icon={faInfoCircle} className="secondaryColor" /> {text}
      </p>
      {children}
    </div>
  );
};

export default InfoBox;
