import {
  IonList,
  IonSkeletonText,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonViewDidEnter
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";

import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import FullCalendar, {
  CalendarOptions,
  DateSelectArg,
  EventInput
} from "@fullcalendar/react";
import enLocale from "@fullcalendar/core/locales/en-gb";
import srCyrlLocale from "@fullcalendar/core/locales/sr-cyrl";
import srLocale from "@fullcalendar/core/locales/sr";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import CalendarCaseDto, {
  CalendarProductionLogDto
} from "../../models/CalendarDto";
import NoResults from "../NoResults";
import { EventContentArg } from "@fullcalendar/core";
import useDate from "../../hooks/useDate";
import CalendarEvent from "./CalendarEvent";
import CalendarListItem from "./CalendarListItem";
import {
  CaseStatus,
  ProductionLogFormDto,
  ProductionLogStatus
} from "../../models/Case";
import { getEnumValues, getPlDate } from "../../data/Helpers";
import Employee from "../../models/Employee";
import { TaskListDto } from "../../models/Task";
import ProductionLogUpsertModal from "../productionLog/ProductionLogUpsertModal";

interface CalendarEventInput extends EventInput {
  caseEvent?: CalendarCaseDto;
  plEvent?: CalendarProductionLogDto;
}

interface CaseUrlProps {
  employeeId?: number;
  status?: CaseStatus;
}
interface PlUrlProps {
  employeeId?: number;
  status?: ProductionLogStatus;
  taskId?: number;
  getUnassigned?: boolean;
}
interface Props {
  type: "cases" | "productionLogs";
  showFilter: boolean;
  productionLogProps?: {
    employeeId?: number;
    status?: ProductionLogStatus;
    taskId?: number;
  };
  onTitleChange: (title: string) => void;
  nextClicked?: string;
  prevClicked?: string;
}
interface DateRange {
  start: Date;
  end: Date;
}

const fcOptions: CalendarOptions = {
  locales: [enLocale, srCyrlLocale, srLocale],
  plugins: [dayGridPlugin, interactionPlugin],
  initialView: "dayGridMonth",
  headerToolbar: false,
  defaultTimedEventDuration: 1,
  selectable: true,
  eventTimeFormat: {
    hour: "numeric",
    minute: "2-digit",
    meridiem: false
  },
  eventContent: (e: EventContentArg) => <CalendarEvent {...e} />,
  height: "auto",
  navLinks: true,
  fixedWeekCount: false,
  showNonCurrentDates: false,
  firstDay: 1,
  dayMaxEventRows: true,
  views: {
    dayGridMonth: {
      dayMaxEventRows: 4
    }
  }
};
const Calendar: React.FC<Props> = ({
  type,
  showFilter,
  productionLogProps,
  onTitleChange,
  nextClicked,
  prevClicked
}) => {
  const calendarRef = useRef<FullCalendar>(null);
  const { language } = useTranslation();
  const [events, setEvents] = useState<CalendarEventInput[]>();
  const [filteredEvents, setFilteredEvents] = useState<CalendarEventInput[]>();
  const [employees, setEmployees] = useState<Employee[]>();
  const [tasks, setTasks] = useState<TaskListDto[]>();
  const [calendarType, setCalendarType] =
    useState<"cases" | "productionLogs">(type);

  const [caseUrlProps, setCaseUrlProps] = useState<CaseUrlProps>();
  const [plUrlProps, setPlUrlProps] = useState<PlUrlProps>({
    ...productionLogProps
  });

  const [productionLogInitialData, setProductionLogInitialData] =
    useState<ProductionLogFormDto>();

  const [showProductionLogModal, setShowProductionLogModal] = useState(false);
  useEffect(() => {
    if (productionLogInitialData) setShowProductionLogModal(true);
    else setShowProductionLogModal(false);
  }, [productionLogInitialData]);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<string>();
  const { apiGet, apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tProductionLogStatus } = useTranslation();
  const { toDateString } = useDate();

  const [fcLocale, setFcLocale] = useState("en");

  useEffect(() => {
    if (calendarType === "productionLogs")
      apiGet<TaskListDto[]>(`task/getAll`).then(setTasks).catch(handleError);

    if (!employees)
      apiGet<Employee[]>(`employee/getAll`)
        .then(setEmployees)
        .catch(handleError);
  }, [calendarType]);

  useEffect(() => setFcLocale(language.replace("_", "-")), [language]);

  useEffect(() => {
    if (nextClicked) next();
  }, [nextClicked]);
  useEffect(() => {
    if (prevClicked) prev();
  }, [prevClicked]);

  useEffect(() => {
    title && onTitleChange(title);
  }, [title]);

  // useEffect(() => {
  //   var date = new Date();
  //   fetchEvents({
  //     start: new Date(date.getFullYear(), date.getMonth(), 1),
  //     end: new Date(date.getFullYear(), date.getMonth() + 1, 0)
  //   });
  // }, [type]);

  useEffect(() => {
    var date = new Date();
    fetchEvents({
      start: new Date(date.getFullYear(), date.getMonth(), 1),
      end: new Date(date.getFullYear(), date.getMonth() + 1, 0)
    });
  }, [calendarType, plUrlProps, caseUrlProps]);

  useIonViewDidEnter(() => {
    // if (events) return;
    var date = new Date();

    fetchEvents({
      start: new Date(date.getFullYear(), date.getMonth(), 1),
      end: new Date(date.getFullYear(), date.getMonth() + 1, 0)
    });
    calendarRef.current?.getApi().today();
  }, []);

  const refreshEvents = () => {
    fetchEvents({
      start: calendarRef.current?.getApi().view.activeStart!,
      end: calendarRef.current?.getApi().view.activeEnd!
    });
  };
  const fetchEvents = ({ start, end }: DateRange) => {
    setLoading(true);
    setTitle(calendarRef.current?.getApi().getCurrentData().viewTitle);

    // if the month is current, we select today, otherwise the first of the month
    const now = new Date();
    fireSelectDate(
      start.getFullYear() === now.getFullYear() &&
        start.getMonth() === now.getMonth()
        ? now
        : start
    );

    if (calendarType === "cases") {
      apiGet<CalendarCaseDto[]>(
        `calendar/getCases?start=${start.toISOString()}&end=${end.toISOString()}`
      )
        .then(data => {
          setEvents(
            data
              .filter(e => e.status !== CaseStatus.Cancelled)
              .map<CalendarEventInput>(e => ({
                title: `${e.caseName}`,
                allDay: false,
                date: e.date,
                caseEvent: { ...e },
                extendedProps: { ...e, type: "case_event" }
              }))
          );
        })
        .catch(handleError)
        .finally(() => setLoading(false));
    } else if (calendarType === "productionLogs") {
      apiPost<CalendarProductionLogDto[]>("calendar/getLogs", {
        start: start.toISOString(),
        end: end.toISOString(),
        status: plUrlProps?.status,
        employeeId: plUrlProps?.employeeId,
        taskId: plUrlProps?.taskId
        // getUnassigned: true
      })
        .then(data => {
          setEvents(
            data.map<CalendarEventInput>(e => ({
              title: e.task ? e.task.name : e.notes ? e.notes : "",
              allDay: false,
              date: getPlDate(e),
              plEvent: { ...e, date: getPlDate(e) },
              extendedProps: { ...e, date: getPlDate(e), type: "pl_event" }
            }))
          );
        })
        .catch(handleError)
        .finally(() => setLoading(false));
    }
  };

  const scrollToEventsList = () => {
    const element = document.querySelector("#events");
    if (element !== null) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (selectedDate && events)
      setFilteredEvents(
        events
          .filter(
            e =>
              (e.caseEvent &&
                new Date(e.caseEvent.date).toDateString() ===
                  selectedDate.toDateString()) ||
              (e.plEvent &&
                new Date(getPlDate(e.plEvent)).toDateString() ===
                  selectedDate.toDateString())
          )
          .sort((a, b) => {
            if (a.caseEvent && b.caseEvent)
              return (
                new Date(a.caseEvent.date).getTime() -
                new Date(b.caseEvent.date).getTime()
              );

            if (a.plEvent && b.plEvent)
              return (
                new Date(a.plEvent.date).getTime() -
                new Date(b.plEvent.date).getTime()
              );
            return 0;
          })
      );
    scrollToEventsList();
  }, [selectedDate, events]);

  const fireSelectDate = (date: Date) => {
    calendarRef.current?.getApi().select(date);
  };
  const prev = () => calendarRef.current?.getApi().prev();
  const next = () => calendarRef.current?.getApi().next();

  return (
    <>
      <IonRow hidden={!showFilter}>
        <IonCol>
          <IonItem className="ion-no-padding">
            <IonLabel position="stacked">{t("display")}</IonLabel>
            <IonSelect
              interface="action-sheet"
              value={calendarType}
              onIonChange={e => setCalendarType(e.detail.value)}
            >
              <IonSelectOption value="cases">
                {t("calendar.tryoutsAndFinishDates")}
              </IonSelectOption>
              <IonSelectOption value="productionLogs">
                {t("workLog.title")}
              </IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem lines="none" className="ion-margin-none ion-no-padding">
            <IonLabel position="stacked">{t("employee")}</IonLabel>
            <IonSelect
              interface="action-sheet"
              cancelText={t("cancel")}
              value={
                calendarType === "cases" && caseUrlProps?.employeeId
                  ? caseUrlProps.employeeId
                  : calendarType === "productionLogs" && plUrlProps?.employeeId
                  ? plUrlProps.employeeId
                  : null
              }
              onIonChange={e => {
                if (calendarType === "cases")
                  setCaseUrlProps(caseUrlProps => ({
                    ...caseUrlProps,
                    employeeId: e.detail.value
                  }));
                else if (calendarType === "productionLogs")
                  setPlUrlProps(plUrlProps => ({
                    ...plUrlProps,
                    employeeId: e.detail.value
                  }));
              }}
            >
              <IonSelectOption key={0} value={null}>
                {t("all")}
              </IonSelectOption>
              {employees?.map(e => (
                <IonSelectOption key={e.id} value={e.id}>
                  {`${e.firstName} ${e.lastName}`}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonCol>
        {calendarType === "productionLogs" && tasks && (
          <>
            <IonCol>
              <IonItem lines="none" className="ion-margin-none ion-no-padding">
                <IonLabel position="stacked">{t("task.title")}</IonLabel>
                <IonSelect
                  interface="action-sheet"
                  cancelText={t("cancel")}
                  value={plUrlProps?.taskId ? plUrlProps.taskId : null}
                  onIonChange={e =>
                    setPlUrlProps(plUrlProps => ({
                      ...plUrlProps,
                      taskId: e.detail.value
                    }))
                  }
                >
                  <IonSelectOption value={null}>{t("all")}</IonSelectOption>
                  {tasks?.map(step => (
                    <IonSelectOption key={step.id} value={step.id}>
                      {step.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>

            <IonCol>
              <IonItem lines="none" className="ion-margin-none">
                <IonLabel position="stacked">{t("status")}</IonLabel>
                <IonSelect
                  interface="action-sheet"
                  cancelText={t("cancel")}
                  value={plUrlProps?.status ? plUrlProps.status : null}
                  onIonChange={e =>
                    setPlUrlProps(plUrlProps => ({
                      ...plUrlProps,
                      status: e.detail.value!
                    }))
                  }
                >
                  <IonSelectOption value={null}>{t("all")}</IonSelectOption>
                  {getEnumValues(ProductionLogStatus).map(status => (
                    <IonSelectOption key={status} value={status}>
                      {tProductionLogStatus(status)}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
          </>
        )}
      </IonRow>
      <FullCalendar
        {...fcOptions}
        ref={calendarRef}
        locale={fcLocale}
        stickyHeaderDates={false}
        select={({ start }: DateSelectArg) => setSelectedDate(start)}
        eventClick={({ event: { start } }) => start && fireSelectDate(start)}
        eventContent={e => <CalendarEvent {...e} />}
        events={events}
        datesSet={fetchEvents}
        navLinkDayClick={fireSelectDate}
        moreLinkClick={({ date }) => fireSelectDate(date)}
      />
      {loading && <IonSkeletonText animated title={t("loading")} />}
      {filteredEvents && (
        <IonList class="box" id="events" className="cal_list_items">
          <h3 className="content-font">
            {toDateString(selectedDate.toString())}
          </h3>
          {filteredEvents.map((e, i) => (
            <CalendarListItem
              key={i}
              {...e}
              editProductionLog={setProductionLogInitialData}
            />
          ))}
          {!filteredEvents.length && (
            <NoResults title={t("calendar.noEvents")} />
          )}
        </IonList>
      )}
      {productionLogInitialData && (
        <ProductionLogUpsertModal
          showProductionLogModal={showProductionLogModal}
          initialData={productionLogInitialData}
          onSuccess={() => {
            setShowProductionLogModal(false);
            refreshEvents();
          }}
          onCancel={() => setShowProductionLogModal(false)}
        />
      )}
    </>
  );
};

export default withPermission(Calendar, Permission.CaseRead);
