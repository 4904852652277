import DoctorFormDto from "./Doctor";
import Employee from "./Employee";
import { CaseRecordDto } from "./Case";
import PagedData from "./PagedData";

export enum InvoiceStatus {
  Unpaid = 0,
  PartlyPaid = 1,
  Paid = 2
}

export interface InvoiceItemDto {
  id: number;
  name: string;
  discount?: number;
  quantity?: number;
  price?: number;
  total: number;
}

export interface InvoicePaymentDto {
  id: number;
  amount: number;
  doctorId: number;
  invoiceId: number;
  notes: string;
  paymentType: number;
}

export interface invoicePaymentListDto extends InvoicePaymentDto {
  created: string;
  updated: string;
}

export interface InvoiceFormDto {
  id: number;
  caseId: number;
  doctorId: number;
  number?: string;
  taxRate: number;
  taxTotal?: number;
  subTotal?: number;
  total?: number;
  notes?: string;
  items: InvoiceItemDto[];
}
export interface InvoiceViewDto extends InvoiceFormDto {
  status: InvoiceStatus;
  case: CaseRecordDto;
  doctor: DoctorFormDto;
  taxRate: number;
  totalPaid: number;
  total: number;
  notes?: string;
  items: InvoiceItemDto[];
  payments: invoicePaymentListDto[];
  createdBy: number;
  createdByUser: Employee;
  created: string;
  updated: string;
  shipped?: string;
}

export interface InvoiceListDto {
  id: number;
  caseId: number;
  doctorId: number;
  number?: string;
  status: InvoiceStatus;
  total: number;
  totalPaid: number;
  notes?: string;
  created: string;
  updated: string;
  case: CaseRecordDto;
  doctor: DoctorFormDto;
}

export interface InvoiceSummaryDto {
  data: PagedData<InvoiceListDto>;
  total: number;
  totalPaid: number;
  balanceDue: number;
  charts: InvoiceChartsDto;
}
export interface InvoiceStatsDto {
  invoices: InvoiceDateChartPointDto[];
  payments: InvoiceDateChartPointDto[];
}

export interface InvoiceChartsDto {
  invoices: InvoiceDateChartPointDto[];
  payments: InvoiceDateChartPointDto[];
}

export interface InvoiceDateChartPointDto {
  name: string;
  amount: number;
  date: string;
  type: string;
}

export default InvoiceFormDto;
