import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthProvider";
import { ToothSvgPaths } from "../data/arches/SvgPaths";
import { divRem } from "../data/Helpers";
import useTranslation from "../data/useTranslation";
import { CaseProductDto } from "../models/Case";
import { DentalNotation } from "../models/Lab";
import { ProductType, ToothProps } from "../models/Teeth";

type ToothNameFromId = (id: number) => string;

const palmerNameFromId: ToothNameFromId = (id: number) => {
  const { div, rem } = divRem(id, 10);

  switch (div) {
    case 1:
      return `${rem}/`;
    case 2:
      return `\\${rem}`;
    case 3:
      return `/${rem}`;
    case 4:
      return `${rem}\\`;
    default:
      return "";
  }
};

const isoNameFromId: ToothNameFromId = (id: number) => id.toString();

const universalNames: { [id: string]: string } = {
  /* UL */
  "18": "1",
  "17": "2",
  "16": "3",
  "15": "4",
  "14": "5",
  "13": "6",
  "12": "7",
  "11": "8",

  /* UR */
  "21": "9",
  "22": "10",
  "23": "11",
  "24": "12",
  "25": "13",
  "26": "14",
  "27": "15",
  "28": "16",

  /* LR */
  "38": "17",
  "37": "18",
  "36": "19",
  "35": "20",
  "34": "21",
  "33": "22",
  "32": "23",
  "31": "24",

  /* LL */
  "41": "25",
  "42": "26",
  "43": "27",
  "44": "28",
  "45": "29",
  "46": "30",
  "47": "31",
  "48": "32"
};
const universalNameFromId: ToothNameFromId = (id: number) =>
  universalNames[id.toString()];

const setTeethFromPaths = (mapper: ToothNameFromId) =>
  ToothSvgPaths.map(s => ({
    ...s,
    name: mapper(s.id),
    selected: false
  }));

const toothIdInUpper = (toothId: number) => toothId < 29;
const toothIdInLower = (toothId: number) => !toothIdInUpper(toothId);

const useDentalNotation = () => {
  const { lab } = useAuthContext();
  const { t } = useTranslation();

  const [teeth, setTeeth] = useState<ToothProps[]>([]);
  const [toothIdToName, setToothIdToName] = useState<ToothNameFromId>(
    () => isoNameFromId
  );

  const [printCaseProductTeeth, setPrintCaseProductTeeth] = useState<
    (v: CaseProductDto) => string
  >(() => () => "");
  const [printToothIds, setPrintToothIds] = useState<
    (type: ProductType, toothIds: number[]) => string
  >(() => () => "");

  useEffect(() => {
    if (
      !lab ||
      !lab.dentalNotation ||
      lab.dentalNotation === DentalNotation.ISO
    ) {
      setToothIdToName(() => isoNameFromId);
      setTeeth(setTeethFromPaths(isoNameFromId));
    } else if (lab.dentalNotation === DentalNotation.Universal) {
      setToothIdToName(() => universalNameFromId);
      setTeeth(setTeethFromPaths(universalNameFromId));
    } else if (lab.dentalNotation === DentalNotation.Palmer) {
      setToothIdToName(() => palmerNameFromId);
      setTeeth(setTeethFromPaths(palmerNameFromId));
    }
  }, [lab]);

  useEffect(() => {
    setPrintToothIds(() => (type: ProductType, toothIds: number[]) => {
      if (type === ProductType.Denture) {
        if (toothIds.every(toothIdInLower)) return t("arches.lower");
        if (toothIds.every(toothIdInUpper)) return t("arches.upper");

        return t("arches.total");
      }
      return toothIds.map(toothIdToName).join(", ");
    });
  }, [toothIdToName]);

  useEffect(() => {
    setPrintCaseProductTeeth(
      () => (dto: CaseProductDto) =>
        printToothIds(dto.productTypeId, dto.toothIds)
    );
  }, [printToothIds]);

  return { teeth, printCaseProductTeeth, printToothIds, toothIdToName };
};

export default useDentalNotation;
export { universalNameFromId, toothIdInUpper, toothIdInLower };
