import React, { useEffect, useState } from "react";
import {
  CaseStatus,
  ProductionLogFormDto,
  ProductionLogStatus,
  ProductionLogListDto,
  CaseListDto
} from "../../models/Case";
import { IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import ProductionLogIcon from "./ProductionLogIcon";
import { useAuthContext } from "../../context/AuthProvider";
import useTranslation from "../../data/useTranslation";
import useDate from "../../hooks/useDate";
import CaseFlagIcon from "../case/CaseFlagIcon";
import { getPlColor, getPlDate } from "../../data/Helpers";

interface Props extends ProductionLogListDto {
  caseStatus: CaseStatus;
  $case?: CaseListDto;
  hideEmployee?: boolean;
  showTimeOnly?: boolean;
  editProductionLog: (l: ProductionLogFormDto) => void;
  deleteProductionLog: (id: number) => void;
}

const ProductionLogItem: React.FC<Props> = ({
  caseStatus,
  $case,
  hideEmployee,
  showTimeOnly,
  editProductionLog,
  deleteProductionLog,
  ...l
}) => {
  const { user } = useAuthContext();
  const { t, tProductionLogStatus } = useTranslation();
  const { toDateTimeString, toTimeString } = useDate();
  const [isCurrentUser, setIsCurrentUser] = useState(false);

  useEffect(
    () =>
      setIsCurrentUser(
        user?.userId === l.userId ||
          (l.status === ProductionLogStatus.Scheduled && !l.userId)
      ),
    [user, l]
  );

  return (
    <>
      <IonItem
        // className={getPlColor({ ...l }).bgClass + " production-log-item "}
        className="production-log-item"
        key={l.id}
        detail={isCurrentUser}
        button={isCurrentUser}
        disabled={!isCurrentUser && $case === undefined}
        onClick={() => {
          if (isCurrentUser && user?.userId)
            editProductionLog({
              ...l,
              userId: user.userId,
              taskName: l.task ? l.task.name : l.taskName
            });
        }}
      >
        {showTimeOnly && (
          <p slot="start" className={getPlColor(l).colorClass}>
            {toTimeString(getPlDate(l))}
          </p>
        )}
        {!showTimeOnly && (
          <ProductionLogIcon
            status={l.status}
            taskId={l.taskId}
            scheduled={l.scheduled}
          />
        )}

        <IonLabel className="ion-text-wrap">
          {showTimeOnly && (
            <ProductionLogIcon
              status={l.status}
              taskId={l.taskId}
              scheduled={l.scheduled}
            />
          )}
          {l.task === undefined ? "" : <b>{l.task.name}</b>}
          {l.taskName === undefined ? "" : <b>{l.taskName}</b>}
          <span
            hidden={l.status === ProductionLogStatus.None}
            className={
              // productionLogStatusColor(l.status) + " additional-status-label"
              "mediumColor additional-status-label"
            }
          >
            {tProductionLogStatus(l.status)}
          </span>
          <span className="pl_item_notes" hidden={!l.notes}>
            {l.status !== ProductionLogStatus.None && <br />}
            <i> {l.notes}</i>
          </span>
          {!showTimeOnly && l.status !== ProductionLogStatus.None && (
            <p className={getPlColor(l).colorClass}>
              {t("productionLogStatus." + l.status) + ":"}
              {toDateTimeString(getPlDate(l))}
            </p>
          )}

          {/* {l.scheduled && (
            <p>
              <ScheduledDate
                date={l.scheduled}
                datePosition="after"
                ignoreColor={[
                  ProductionLogStatus.Done,
                  ProductionLogStatus.Failed
                ].includes(l.status)}
              >
                {t("scheduling.deadline") + ":"}
              </ScheduledDate>
            </p>
          )} */}
          {l.scheduled === undefined &&
            l.status === ProductionLogStatus.Scheduled && (
              <p>
                {t("scheduling.deadline")}: {t("notDefined")}
              </p>
            )}
          {!l.taskId && <p>{toDateTimeString(l.created ?? "")}</p>}
          <p hidden={hideEmployee} className={isCurrentUser ? "bold" : ""}>
            {l.user && `${l.user.firstName} ${l.user.lastName}`}
            {l.userName && `${l.userName}`}
          </p>
          <p>
            {l.userId === undefined &&
            l.status === ProductionLogStatus.Scheduled
              ? t("scheduling.anyTechnician")
              : ""}
          </p>
          {$case && (
            <>
              <p className="pl_case_name ion-margin-top">
                <CaseFlagIcon {...$case} />
                {$case.name}
              </p>
              <IonRouterLink
                onClick={e => e.stopPropagation()}
                routerLink={"/case/" + $case.id}
                color="secondary"
              >
                {t("openCase")}
              </IonRouterLink>
            </>
          )}
        </IonLabel>
        {/* <p slot="end">
          <Can permission={Permission.ProductionLogDelete}>
            <IonButton
              hidden
              size="small"
              color="danger"
              fill="clear"
              onClick={() => deleteProductionLog(l.id)}
            >
              {t("delete")}
            </IonButton>
          </Can>
          <Can permission={Permission.ProductionLogUpdate}>
            <IonButton
              hidden
              size="small"
              color="secondary"
              onClick={() =>
                user &&
                editProductionLog({
                  ...l,
                  userId: user.userId,
                  taskName: l.task ? l.task.name : l.taskName
                })
              }
            >
              <Icon icon={faUserEdit} className="whiteColor" />
              {t("update")}
            </IonButton>
          </Can>
        </p> */}
      </IonItem>
    </>
  );
};

export default ProductionLogItem;
