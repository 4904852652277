import React from "react";
import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import useTranslation, { Language, Languages } from "../data/useTranslation";

interface Props {
  onChange?: (lng: Language) => void;
}

const LanguageSelect: React.FC<Props> = ({ onChange }) => {
  const { t, language, changeLanguage } = useTranslation();
  return (
    <IonItem>
      <IonLabel position="stacked">{t("selectLanguage")}</IonLabel>
      <IonSelect
        interface="action-sheet"
        value={language}
        onIonChange={v => {
          changeLanguage(v.detail.value);
          onChange && onChange(v.detail.value);
        }}
        okText={t("ok")}
        cancelText={t("cancel")}
      >
        {Languages.map(o => (
          <IonSelectOption key={o.value} value={o.value} title={o.name}>
            {o.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export default LanguageSelect;
