import React from "react";
import {
  faDownload,
  faEdit,
  faFilter,
  faPlus,
  faSave,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon";
import useTranslation from "../data/useTranslation";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

type ButtonTypes =
  | "cancel"
  | "createNew"
  | "add"
  | "addCase"
  | "edit"
  | "delete"
  | "save"
  | "filter"
  | "download"
  | "newProduct";
interface Props {
  button: ButtonTypes;
  loading?: boolean;
  iconClass?: string;
}

const getProps = (button: ButtonTypes) => {
  switch (button) {
    case "cancel":
      return {
        icon: faTimes,
        title: "cancel"
      };
    case "createNew":
      return {
        icon: faPlus,
        title: "createNew"
      };
    case "save":
      return {
        icon: faSave,
        title: "save"
      };
    case "add":
      return {
        icon: faPlus,
        title: "add"
      };
    case "addCase":
      return {
        icon: faPlus,
        title: "cases.new"
      };
    case "edit":
      return {
        icon: faEdit,
        title: "edit"
      };
    case "delete":
      return {
        icon: faTrash,
        title: "delete"
      };
    case "filter":
      return {
        icon: faFilter,
        title: "filters"
      };
    case "download":
      return {
        icon: faDownload,
        title: "saveAsPdf"
      };
    case "newProduct":
      return {
        icon: faPlus,
        title: "products.new"
      };
  }
};

const ButtonTextIcon: React.FC<Props> = ({ button, loading, iconClass }) => {
  const { t } = useTranslation();
  const { icon, title } = getProps(button);

  return (
    <>
      <span className="buttonTextIcon">
        {loading ? (
          <Icon spin icon={faSpinner} />
        ) : (
          <Icon icon={icon} className={iconClass ? iconClass : "12333"} />
        )}
      </span>
      <span className="ion-hide-sm-down">{t(title)}</span>
    </>
  );
};

export default ButtonTextIcon;
