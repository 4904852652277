import {
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
  IonRouterLink
} from "@ionic/react";
import React from "react";
import useTranslation from "../../data/useTranslation";
import DashboardDto from "../../models/DashboardDto";
import { CaseStatus, ProductionLogFormDto } from "../../models/Case";
import CaseFlagIcon from "../case/CaseFlagIcon";
import CaseStatusIcon from "../case/CaseStatusIcon";
import ScheduledDate from "../ScheduledDate";
import ProductionLogItem from "../productionLog/ProductionLogItem";

interface Props {
  dto: DashboardDto;
  setProductionLogInitialData: (v: ProductionLogFormDto) => void;
}

const DashboardCard: React.FC<Props> = ({
  dto,
  setProductionLogInitialData
}) => {
  const { t, tCaseStatus } = useTranslation();

  return (
    <div className="box">
      <IonCardHeader className="dashboard_card_header">
        <IonRouterLink routerLink={"/case/" + dto.caseId}>
          <IonCardTitle>
            <CaseFlagIcon flag={dto.flag} /> {dto.caseName}
            {/* <Icon size="xs" className="mediumColor" icon={faExternalLinkAlt} /> */}
          </IonCardTitle>
        </IonRouterLink>
        <IonCardSubtitle></IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
        <p>
          <CaseStatusIcon {...dto} title={tCaseStatus(dto.status)} />{" "}
          {tCaseStatus(dto.status)}
          {", "}
          <b>{dto.doctorName}</b>
          {dto.patient && <>, {dto.patient}</>}
        </p>
        {dto.pan?.length && (
          <b>
            {t("cases.pan")}: {dto.pan}
          </b>
        )}
        <p className="margin-top-6" />
        {dto.nextTryout && (
          <p>
            <ScheduledDate
              date={dto.nextTryout.date}
              ignoreColor={[CaseStatus.Finished, CaseStatus.Cancelled].includes(
                dto.status
              )}
              datePosition="after"
              customCSSClass="smaller-font"
            >
              {dto.nextTryout.name + ":"}
            </ScheduledDate>
          </p>
        )}
        {dto.finishDate && (
          <p>
            <ScheduledDate
              date={dto.finishDate}
              ignoreColor={[CaseStatus.Finished, CaseStatus.Cancelled].includes(
                dto.status
              )}
              datePosition="after"
              customCSSClass="smaller-font"
            >
              {t("dates.finishDate") + ":"}
            </ScheduledDate>
          </p>
        )}

        <div className="ion-margin-top">
          <h3
            className="form-header ion-no-margin"
            style={{ marginLeft: 0 }}
            hidden={!dto.logs.length}
          >
            {t("workLog.title")}
          </h3>
        </div>

        <IonCardSubtitle hidden className="ion-margin-top"></IonCardSubtitle>
        <IonList className="ion-no-padding">
          {dto.logs.slice(0, 2).map((l, i) => (
            <ProductionLogItem
              key={l.id}
              caseStatus={CaseStatus.InProgress}
              editProductionLog={l => setProductionLogInitialData({ ...l })}
              deleteProductionLog={id => {}}
              {...l}
            />
          ))}
        </IonList>
      </IonCardContent>
    </div>
  );
};

export default DashboardCard;
