import { IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { concatinate, getCaseColor } from "../../data/Helpers";
import useTranslation from "../../data/useTranslation";
import useDate from "../../hooks/useDate";
import CalendarCaseDto, {
  CalendarProductionLogDto
} from "../../models/CalendarDto";
import { ProductionLogFormDto } from "../../models/Case";
import CaseFlagIcon from "../case/CaseFlagIcon";
import CaseStatusIcon from "../case/CaseStatusIcon";
import ProductionLogItem from "../productionLog/ProductionLogItem";

interface Props {
  caseEvent?: CalendarCaseDto;
  plEvent?: CalendarProductionLogDto;
  editProductionLog: (l: ProductionLogFormDto) => void;
}

const CalendarListItem: React.FC<Props> = ({
  caseEvent,
  plEvent,
  editProductionLog
}) => {
  const { t, tInterpolated, tCaseStatus } = useTranslation();
  const { toTimeString, toDateString } = useDate();
  const colorClass = caseEvent ? getCaseColor(caseEvent)?.colorClass : "";

  return (
    <>
      {plEvent && (
        <ProductionLogItem
          {...plEvent}
          caseStatus={plEvent.case.status}
          $case={plEvent.case}
          showTimeOnly
          editProductionLog={() =>
            editProductionLog({
              ...plEvent,
              taskName: plEvent.task ? plEvent.task.name : ""
            })
          }
          deleteProductionLog={() => {}}
        />
      )}

      {caseEvent && (
        <IonItem button detail routerLink={"/case/" + caseEvent.caseId}>
          <p slot="start" className={colorClass}>
            {toTimeString(caseEvent.date)}
          </p>
          <IonLabel className="ion-text-wrap">
            {caseEvent.tryoutId ? (
              <>
                <p className={colorClass}>
                  <b>{t("cases.tryout")}</b> ({caseEvent.tryoutName})
                </p>

                {caseEvent.shipped ? (
                  <p>
                    {tInterpolated("cases.shippedOn", {
                      date: toDateString(caseEvent.shipped)
                    })}
                  </p>
                ) : (
                  <p className={colorClass}>{t("cases.notShipped")}</p>
                )}
                {caseEvent.shipped && caseEvent.received ? (
                  <p>
                    {tInterpolated("cases.receivedOn", {
                      date: toDateString(caseEvent.received)
                    })}
                  </p>
                ) : (
                  caseEvent.shipped && (
                    <p className={colorClass}>{t("cases.notReceived")}</p>
                  )
                )}
              </>
            ) : (
              <p className={colorClass}>
                <b>{t("dates.finishDate")}</b>
              </p>
            )}
            <p className="cal-list-case-title" title={caseEvent.caseName}>
              <CaseFlagIcon flag={caseEvent.flag} />
              {concatinate([caseEvent.caseName, caseEvent.pan])}{" "}
            </p>
            <p className="case-status ion-no-margin">
              <CaseStatusIcon
                {...caseEvent}
                title={tCaseStatus(caseEvent.status)}
              />{" "}
              {tCaseStatus(caseEvent.status)}
            </p>

            <p>
              {caseEvent.doctorName}
              <i>{caseEvent.patient && <>, {caseEvent.patient}</>}</i>
            </p>
          </IonLabel>
        </IonItem>
      )}
    </>
  );
};

export default CalendarListItem;
