import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon
} from "@ionic/react";
import { logOut } from "ionicons/icons";
import Logo from "../../components/Logo";
import { useAuthContext } from "../../context/AuthProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import MiddleBox from "../MiddleBox";

interface Props {
  code: string | null;
}

const VerifyEmail: React.FC<Props> = ({ code }) => {
  const { user, logout } = useAuthContext();
  const { showToast } = useNotificationContext();
  const { t, tInterpolated } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("verifyEmail.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MiddleBox>
          <Logo />
          <p>
            {tInterpolated("verifyEmail.description", {
              user: user ? ` (${user.email})` : ""
            })}
          </p>
          <IonButton
            expand="block"
            type="submit"
            onClick={() =>
              user
                ?.sendEmailVerification(code)
                .then(() => showToast({ message: t("verifyEmail.emailSent") }))
            }
          >
            {t("verifyEmail.resendVerification")}
          </IonButton>
          <IonButton expand="block" color="secondary" onClick={() => logout()}>
            <IonIcon icon={logOut}></IonIcon>
            {t("logout")}
          </IonButton>
        </MiddleBox>
      </IonContent>
    </IonPage>
  );
};

export default VerifyEmail;
