import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonList,
  IonItemDivider,
  IonToggle,
  IonButton,
  IonCol,
  IonRow
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import Employee from "../../models/Employee";
import Icon from "../../components/Icon";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { Permission } from "../../models/Permissions";
import useTranslation from "../../data/useTranslation";
import ValueLabel from "../ValueLabel";
import MiddleBox from "../MiddleBox";
import EmployeePermissionsUpsert from "./EmployeePermissionsUpsert";
import Role from "../../models/Role";
import ProductionLogTable from "../productionLog/ProductionLogTable";
import ButtonTextIcon from "../ButtonTextIcon";
import { useAuthContext } from "../../context/AuthProvider";
import Can from "../Can";
import EmployeeTasks from "../productionLog/EmployeeTasks";
import { ProductionLogStatus } from "../../models/Case";

interface EmployeeUrlProps
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const EmployeeProfile: React.FC<EmployeeUrlProps> = ({ match }) => {
  const [segment, setSegment] = useState<
    "info" | "stats" | "log" | "edit" | "permissions" | "tasks"
  >("log");
  const [showFilter, setShowFilter] = useState(false);
  const { apiGet, apiPost } = useApi();
  const [employee, setEmployee] = useState<Employee>();
  const { user } = useAuthContext();
  const [employeeId, setEmployeeId] = useState<number>();
  const { handleError, showAlert, showSuccessToast } = useNotificationContext();
  const { t, tRole } = useTranslation();

  const getEmployee = () => {
    apiGet<Employee>("employee/get?id=" + employeeId)
      .then(setEmployee)
      .catch(handleError);
  };

  useEffect(() => {
    employeeId && getEmployee();
  }, [employeeId]);

  useEffect(() => {
    user &&
      setEmployeeId(match.params.id ? parseInt(match.params.id) : user.userId);
  }, [match.params.id, user]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <Can permission={Permission.EmployeeUpdate}>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/employees" />
            </IonButtons>
          </Can>
          <IonTitle>
            {employee
              ? `${employee.firstName} ${employee.lastName}`
              : t("employeeProfile.title")}
          </IonTitle>
          {segment === "log" && (
            <IonButtons slot="primary">
              <IonButton
                color={showFilter ? "primary" : ""}
                onClick={() => setShowFilter(!showFilter)}
              >
                <ButtonTextIcon button="filter" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
        <IonToolbar className="header_toolbar2">
          <IonSegment
            onIonChange={e => setSegment(e.detail.value as any)}
            value={segment}
            mode="md"
          >
            <IonSegmentButton value="info">
              <IonLabel>{t("info")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="stats" hidden>
              <IonLabel>{t("stats")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="log">
              <IonLabel>{t("workLog.title")}</IonLabel>
            </IonSegmentButton>
            {user && (
              <IonSegmentButton
                hidden={user.userId === employeeId}
                value="tasks"
              >
                <IonLabel>{t("tasks")}</IonLabel>
              </IonSegmentButton>
            )}
            <Can permission={Permission.EmployeeUpdate}>
              <IonSegmentButton value="permissions">
                <IonLabel>{t("permissions.title")}</IonLabel>
              </IonSegmentButton>
            </Can>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {segment === "info" && employee && (
          <MiddleBox className="box ion-text-center">
            <Icon icon={faIdBadge} size="4x" className="secondaryColor" />
            <h3>
              {employee.firstName} {employee.lastName}
            </h3>
            <ValueLabel large title={t("role")}>
              {employee && tRole(employee.roleId)}
            </ValueLabel>
            <ValueLabel large title={t("email")}>
              {employee.email}
            </ValueLabel>
            {/* <ValueLabel large title={t("created")}>
              {employee.email}
            </ValueLabel> */}
            {employee.roleId !== Role.Owner && (
              <ValueLabel
                large
                title={t("employeeProfile.allowEmployeeAccess")}
              >
                <IonToggle
                  //onClick
                  checked={employee.allowAccess}
                  onClick={() => {
                    //setAllowAccess(e.detail.checked);
                    showAlert({
                      title: t("confirm"),
                      message: employee.allowAccess
                        ? t("employeeProfile.eadQuestion")
                        : t("employeeProfile.eaaQuestion"),
                      buttons: [
                        {
                          text: t("cancel"),
                          role: "cancel"
                        },
                        {
                          text: t("confirm"),
                          handler: () => {
                            // TODO: disable/enable access to this lab on api, in token etc.
                            if (employee.allowAccess) {
                              apiPost(
                                "employee/deactivate?id=" + employeeId,
                                {}
                              ).then(() => {
                                showSuccessToast(
                                  t("employeeProfile.employeeAccessDeactivated")
                                );
                                getEmployee();
                              });
                            } else {
                              apiPost(
                                "employee/activate?id=" + employeeId,
                                {}
                              ).then(() => {
                                showSuccessToast(
                                  t("employeeProfile.employeeAccessActivated")
                                );
                                getEmployee();
                              });
                            }
                          }
                        }
                      ]
                    });
                  }}
                />
              </ValueLabel>
            )}
          </MiddleBox>
        )}
        {segment === "edit" && (
          <IonList>
            <IonItemDivider>{"employeeProfile.employeeAccess"}</IonItemDivider>
          </IonList>
        )}
        {segment === "log" && employee && (
          <ProductionLogTable
            initialUrlProps={{
              status: null,
              taskId: null,
              employeeId: employee.id,
              page: 1,
              pageSize: 10
            }}
            mode="employeeLogs"
            showFilterProp={showFilter}
          />
        )}
        {segment === "tasks" && employee && (
          <IonRow>
            <IonCol className="flex_hide_if_empty">
              <EmployeeTasks
                urlProps={{
                  status: ProductionLogStatus.Started,
                  employeeId: employee.id
                  // getUnassigned: true
                }}
                mode="inProgress"
                editProductionLog={() => {}}
              />
            </IonCol>
            <IonCol>
              <EmployeeTasks
                urlProps={{
                  status: ProductionLogStatus.Scheduled,
                  employeeId: employee.id
                  // getUnassigned: true
                }}
                mode="scheduled"
                editProductionLog={() => {}}
              />
            </IonCol>
          </IonRow>
        )}
        {segment === "permissions" && employee && (
          <Can permission={Permission.EmployeeUpdate}>
            <EmployeePermissionsUpsert
              employeePermisssions={employee.permissions}
              employeeId={employee.id}
            />
          </Can>
        )}
      </IonContent>
    </IonPage>
  );
};

export default EmployeeProfile;
