import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

interface Props {
  value?: string;
  className?: string;
  dateAndTime?: boolean;
  minuteStep?: number;
  placeholder?: string;
  onChange: (v?: string) => void;
  onBlur?: () => void;
}

const PopupDatePicker: React.FC<Props> = ({
  value,
  dateAndTime,
  minuteStep = 5,
  onChange,
  ...rest
}) => (
  <DatePicker
    showTime={
      dateAndTime && {
        defaultValue: moment("12:00", "HH:mm"),
        format: "HH:mm",
        minuteStep
      }
    }
    size="large"
    format={dateAndTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
    value={value ? moment(value) : null}
    onChange={m => onChange(m?.toISOString())}
    {...rest}
  />
);

export default PopupDatePicker;
