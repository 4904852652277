import React from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  useIonViewWillEnter
} from "@ionic/react";
import useTranslation from "../../data/useTranslation";
import CaseFormDto, { CaseProductDto } from "../../models/Case";
import { ProductViewDto } from "../../models/Product";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import Icon from "../Icon";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import useDentalNotation from "../../hooks/useDentalNotation";

interface Props {
  $case: CaseFormDto;
  labProducts?: ProductViewDto[];
  showActions: boolean;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
}

const ProductList: React.FC<Props> = ({
  $case,
  labProducts,
  showActions,
  onEdit,
  onDelete
}) => {
  const { t, tProductType } = useTranslation();
  const { printCaseProductTeeth, printToothIds } = useDentalNotation();

  const fetchData = () => {};

  useIonViewWillEnter(() => {
    fetchData();
  });

  const printExtras = (row: CaseProductDto) =>
    row.extras
      .map(
        e =>
          `${e.extras.name} (${printToothIds(row.productTypeId, e.toothIds)})`
      )
      .join(", ");

  const columns: IDataTableColumn<CaseProductDto>[] = [
    {
      name: t("products.title"),
      selector: "productId",
      sortable: true,
      grow: 3,
      cell: row => (
        <IonItem
          key={row.id}
          lines="none"
          class="ion-no-padding"
          // routerLink={"/" + row.id}
        >
          <IonLabel>
            {labProducts && labProducts.find(p => p.id === row.productId)?.name}
            <p className="bold ion-hide-sm-up">{printExtras(row)}</p>
            <p className="ion-hide-sm-up">{printCaseProductTeeth(row)}</p>
            <p>{tProductType(row.productTypeId)}</p>
            <p className="ion-hide-sm-up">{row.shade}</p>
          </IonLabel>
        </IonItem>
      )
    },
    {
      name: t("cases.color"),
      selector: "shade",
      sortable: true,
      grow: 1,
      cell: row =>
        row.shade ? (
          row.shade
        ) : (
          <span className="warningColor">{t("cases.colorNotDefined")}</span>
        ),
      hide: "sm"
    },
    {
      name: t("teeth"),
      selector: "toothIds",
      sortable: true,
      grow: 1,
      cell: row => printCaseProductTeeth(row),
      hide: "sm"
    },
    {
      name: t("extras.title"),
      selector: "extras",
      sortable: false,
      grow: 1,
      cell: row => printExtras(row),
      hide: "sm"
    },
    {
      name: "",
      // selector: "",
      // sortable: true,
      grow: 1,
      cell: row => (
        <>
          <IonButton
            className="no-icon-margin"
            color="danger"
            fill="clear"
            onClick={() => onDelete && onDelete(row.id)}
          >
            <Icon icon={faTimes} />
          </IonButton>
          <IonButton
            className="no-icon-margin"
            fill="clear"
            onClick={() => onEdit && onEdit(row.id)}
          >
            <Icon icon={faEdit} />
          </IonButton>
        </>
      ),
      hide: "sm",
      omit: !showActions
    }
  ];

  return $case.products.length ? (
    <DataTable
      keyField="id"
      data={$case.products}
      columns={columns}
      className="l2ower-top-padding"
      noHeader
      noDataComponent={t("noRecords")}
      paginationComponentOptions={{
        rowsPerPageText: t("dataTable.rowsPerPageText"),
        rangeSeparatorText: t("dataTable.rangeSeparatorText")
      }}
    />
  ) : (
    <></>
  );
};

export default ProductList;
