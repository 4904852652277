import CalendarCaseDto from "../models/CalendarDto";
import { CaseStatus, ProductionLogStatus } from "../models/Case";
import moment from "moment";

const searchMatch = (searchingFor: string, searchingIn: string) =>
  !searchingFor ||
  searchingIn.toLowerCase().includes(searchingFor.toLowerCase());

const makeLength = (str: string, length: number) => {
  var padding = Array(length).join(" "); // make a string of white spaces
  return (str + padding).substring(0, length);
};

const isNumber = (x: any): x is number => typeof x === "number";

const isString = (x: any): x is string => typeof x === "string";

const getEnumValues = <TEnum>(enumObj: TEnum) =>
  (Object.keys(enumObj) as Array<keyof TEnum>)
    .filter(p => typeof enumObj[p] === "number")
    .map(p => enumObj[p]);

const getEnumValuesUnfiltered = <TEnum>(enumObj: TEnum) =>
  (Object.keys(enumObj) as Array<keyof TEnum>).map(p => enumObj[p]);

/**
 * Concatinates all non-empty values
 * @param values An array of values
 * @param separator The separator to be inserted between values
 * @returns A concatinated string
 */
const concatinate = (
  values: (string | undefined)[],
  separator: string = ", "
) => values.filter(v => v).join(separator);

/**
 * Devides y by x and returns both the quotient and the remainder.
 * @param x The divisor.
 * @param y The dividend.
 */
const divRem = (x: number, y: number) => {
  var div = Math.trunc(y / x);
  var rem = y % x;

  return { div, rem };
};

/**
 * Creates a random integer number between min and max
 * @param min The minimum number allowed
 * @param max The maximum number, but excluded
 */
const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

/**
 * Creates a random integer number that can be used as an ID
 * @param min List of existing IDs that makes sure the new ID does not repeat
 */
const getRandomId = (existingIds: number[]) => {
  while (true) {
    const suggestion = getRandomInt(1, 2000000000);
    if (!existingIds.includes(suggestion)) return suggestion;
  }
};

/**
 * Calculates the difference in days between two dates
 * @param a First date (usually current)
 * @param b Second date
 * @returns A number in days
 */
const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc1 - utc2) / _MS_PER_DAY);
};

interface DateColor {
  colorClass: string;
  bgClass: string;
}

interface PlColorProps {
  status: ProductionLogStatus;
  started?: string;
  finished?: string;
  scheduled?: string;
  created?: string;
}

const getScheduleDateColor = (color: string): DateColor => {
  if (color === "warning") {
    return {
      colorClass: "warningColor",
      bgClass: "warningBgColor"
    };
  } else if (color === "success") {
    return {
      colorClass: "successColor",
      bgClass: "successBgColor"
    };
  } else if (color === "danger") {
    return {
      colorClass: "dangerColor",
      bgClass: "dangerBgColor"
    };
  } else if (color === "primary") {
    return {
      colorClass: "primaryColor",
      bgClass: "primaryBgColor"
    };
  } else if (color === "grey") {
    return {
      colorClass: "greyColor",
      bgClass: "greyBgColor"
    };
  }

  return {
    colorClass: "",
    bgClass: ""
  };
};

const getPlColor = (data: PlColorProps): DateColor => {
  if (data.status === ProductionLogStatus.Scheduled) {
    if (timeInPast(new Date(data.scheduled!)))
      return getScheduleDateColor("danger");

    if (deadlineIn6h(new Date(data.scheduled!))) {
      return getScheduleDateColor("warning");
    }
  }

  if (data.status === ProductionLogStatus.Started) {
    if (data.scheduled) {
      if (timeInPast(new Date(data.scheduled)))
        return getScheduleDateColor("danger");

      if (deadlineIn6h(new Date(data.scheduled))) {
        return getScheduleDateColor("warning");
      }
    }
  }
  if (data.status === ProductionLogStatus.Done) {
    return getScheduleDateColor("success");
  }
  if (data.status === ProductionLogStatus.Failed) {
    return getScheduleDateColor("danger");
  }

  return getScheduleDateColor("grey");
};

const getCaseColor = (data: CalendarCaseDto) => {
  const eventDate = new Date(data.date);

  if (data.tryoutId) {
    if (data.received) return getScheduleDateColor("success");
    if (data.shipped) return getScheduleDateColor("success");

    if (timeInPast(eventDate) && !data.shipped)
      return getScheduleDateColor("danger");

    if (deadlineIn6h(new Date(eventDate)))
      return getScheduleDateColor("warning");
  } else {
    // finish date
    if (timeInPast(eventDate)) {
      if (![CaseStatus.Finished, CaseStatus.Cancelled].includes(data.status))
        return getScheduleDateColor("danger");
      if ([CaseStatus.Finished, CaseStatus.Cancelled].includes(data.status))
        return getScheduleDateColor("success");
    } else if (
      deadlineIn6h(new Date(eventDate)) &&
      ![CaseStatus.Finished].includes(data.status)
    ) {
      return getScheduleDateColor("warning");
    } else if (timeInFuture(eventDate) && data.status === CaseStatus.Finished) {
      return getScheduleDateColor("success");
    }
  }
  return getScheduleDateColor("grey");
};

const getPlDate = (data: PlColorProps) => {
  if (data.finished) return data.finished;
  if (data.started) return data.scheduled ? data.scheduled : data.started;
  if (data.scheduled) return data.scheduled;
  if (data.created) return data.created;
  return "";
};

const scheduledDayColorClasses = (date: Date): DateColor => {
  const dayDiff = dateDiffInDays(new Date(), date);
  if (dayDiff === 0) {
    return getScheduleDateColor("warning");
  } else if (dayDiff > 0) {
    return getScheduleDateColor("danger");
  } else {
    return getScheduleDateColor("primary");
  }
};

const timeInPast = (date: Date) => {
  const diff = Math.round((new Date().getTime() - date.getTime()) / (1000 * 0));
  return diff > 0 ? true : false;
};
const timeInFuture = (date: Date) => {
  const diff = Math.round(
    (new Date().getTime() - date.getTime()) / (1000 * 60)
  );
  return diff < 0 ? true : false;
};

const deadlineIn6h = (date: Date) => {
  const diff = Math.round(
    (new Date().getTime() - date.getTime()) / (1000 * 60)
  );
  return diff < 0 && diff > -360 ? true : false;
};

const dateRangeInPast = (current: moment.Moment) => {
  const customDate = moment().format("YYYY-MM-DD");
  return current && current < moment(customDate, "YYYY-MM-DD");
};

const formatToIso8601 = (date: string) => {
  return moment(date, moment.ISO_8601).format();
};

const scheduledTimeColorClasses = (date: Date): DateColor => {
  const now = new Date();
  const diff = Math.round((now.getTime() - date.getTime()) / (1000 * 60));
  //1440 minutes in one day
  if (diff < 0 && diff > -1440) getScheduleDateColor("warning");
  if (diff > 0) getScheduleDateColor("danger");

  return {
    colorClass: "",
    bgClass: ""
  };
};

export {
  searchMatch,
  makeLength,
  isNumber,
  isString,
  getEnumValues,
  getEnumValuesUnfiltered,
  concatinate,
  divRem,
  getRandomId,
  getRandomInt,
  dateDiffInDays,
  dateRangeInPast,
  formatToIso8601,
  scheduledDayColorClasses,
  scheduledTimeColorClasses,
  getPlColor,
  getCaseColor,
  getPlDate
};
