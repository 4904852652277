import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonBackButton
} from "@ionic/react";
import React from "react";
import Logo from "../../components/Logo";
import { useAuthContext } from "../../context/AuthProvider";
import useTranslation from "../../data/useTranslation";
import EmailFormItem from "../form/EmailFormItem";
import Form from "../form/Form";
import MiddleBox from "../MiddleBox";

const ForgotPassword: React.FC = () => {
  const { forgotPassword } = useAuthContext();
  const { t } = useTranslation();

  const sendResetPasswordEmail = (e: { email: string }) =>
    forgotPassword(e.email)
      .then(() => {
        alert("email is sent");
      })
      .catch((error: any) => {
        alert(error.message);
      });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("forgotYourPassword")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MiddleBox>
          <Logo />
          <Form
            onSubmit={sendResetPasswordEmail}
            submitButtonText={t("sendResetPasswordEmail")}
          >
            <EmailFormItem required />
          </Form>
          <IonButton routerLink="/login" expand="block" fill="clear">
            {t("goToLogin")}
          </IonButton>
        </MiddleBox>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
