import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import Form from "../form/Form";
import useTranslation from "../../data/useTranslation";
import ButtonTextIcon from "../ButtonTextIcon";
import TaskFormDto from "../../models/Task";
import { useAuthContext } from "../../context/AuthProvider";
import { getCurrencySymbol } from "../../models/Currency";
import InfoBox from "../InfoBox";
import ModalWrapper from "../ModalWrapper";
import FormInput from "../form/FormIonInput";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showTaskModal: boolean;
  initialData: TaskFormDto;
}
const TaskUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showTaskModal
}) => {
  const { lab } = useAuthContext();
  const currencySign = getCurrencySymbol(lab?.currency);

  const [submit, setSubmit] = useState<() => void>(() => {});
  const [isSubmitting, setSubmitting] = useState(false);
  const { apiPost } = useApi();
  const { t, tRequired, tPlaceholder } = useTranslation();

  const { handleError } = useNotificationContext();

  const upsert = (data: TaskFormDto) =>
    apiPost<number>("task/upsert", data)
      .then(id => onSuccess(id))
      .catch(handleError);

  return (
    <ModalWrapper
      modalOpened={showTaskModal}
      dismiss={onCancel}
      modal="tuUpsert"
    >
      <IonModal isOpen={showTaskModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {initialData.id > 0 ? t("task.edit") : t("task.new")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={submit} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Form
            initialData={initialData}
            onSubmit={upsert}
            submitButtonText={t("save")}
            exposeSubmit={setSubmit}
            setSubmitting={setSubmitting}
          >
            <FormInput name="id" label="Id" hidden />
            <FormInput
              name="name"
              label={t("name")}
              rules={{
                required: tRequired("name")
              }}
              placeholder={t("task.namePlaceholder")}
              clearInput
            />

            <FormInput
              hidden
              name="description"
              label={t("description")}
              placeholder={tPlaceholder("description")}
              clearInput
            />

            <FormInput
              name="pieceRate"
              label={t("task.pieceRate") + "(" + currencySign + ")"}
              rules={{ valueAsNumber: true }}
              type="number"
              inputMode="numeric"
              step="0.01"
              min="0"
              placeholder={t("task.pieceRatePlaceholder")}
              clearInput
            />
            <InfoBox text={t("task.pieceRateHint")}></InfoBox>
          </Form>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default TaskUpsertModal;
