import React, { useState, useContext } from "react";
import useTranslation from "../data/useTranslation";
import { UserError } from "../models/Errors";
import { useAuthContext } from "./AuthProvider";

interface ButtonProps {
  text: string;
  role?: string;
  handler?: () => void;
}

interface ToastProps {
  message: string;
  duration?: number;
  color?: string;
}

interface AlertProps {
  title?: string;
  message: string;
  buttons: ButtonProps[];
}

interface ContextProps {
  showToast: (p: ToastProps) => void;
  showSuccessToast: (message: string) => void;
  showErrorToast: (message: string) => void;
  showAlert: (p: AlertProps) => void;
  handleError: (e: Error) => void;
  hideToast: () => void;
  hideAlert: () => void;
  alert?: AlertProps;
  toast?: ToastProps;
}

const NotificationContext = React.createContext<ContextProps>({
  showToast: () => {},
  showSuccessToast: () => {},
  showErrorToast: () => {},
  showAlert: () => {},
  handleError: _ => {},
  hideToast: () => {},
  hideAlert: () => {}
});

const NotificationConsumer = NotificationContext.Consumer;
const useNotificationContext = () => {
  const {
    showAlert,
    showToast,
    showSuccessToast,
    showErrorToast,
    handleError
  } = useContext(NotificationContext);
  return {
    showAlert,
    showToast,
    showSuccessToast,
    showErrorToast,
    handleError
  };
};

const NotificationProvider: React.FC = ({ children }) => {
  const { ensureNoLoadingState } = useAuthContext();
  const [alert, setAlert] = useState<AlertProps>();
  const [toast, setToast] = useState<ToastProps>();
  const { tError } = useTranslation();

  const showAlert = (p: AlertProps) => {
    setAlert(p);
  };
  const showToast = (p: ToastProps) => {
    setToast(p);
  };
  const showSuccessToast = (message: string) => {
    setToast({
      message: message,
      duration: 3000,
      color: "success"
    });
  };
  const showErrorToast = (message: string) => {
    setToast({
      message: message,
      duration: 3000,
      color: "danger"
    });
  };

  const handleError = (e: Error) => {
    ensureNoLoadingState();
    if (e instanceof UserError) {
      const message = tError(e.status.toString());
      const props =
        e.status === 1500 || e.status === 1501
          ? {
              title: message,
              message: e.message
            }
          : {
              title: `${tError("title")} ${e.status}`,
              message: message ? message : e.message
            };
      showAlert({
        ...props,
        buttons: [{ text: "OK" }]
      });
    } else {
      showAlert({
        title: tError("title"),
        message: e.message,
        buttons: [{ text: "OK" }]
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        showAlert,
        showToast,
        showSuccessToast,
        showErrorToast,
        handleError,
        hideAlert: () => {
          setAlert(undefined);
          console.log(alert);
        },
        hideToast: () => setToast(undefined),
        alert,
        toast
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, NotificationConsumer, useNotificationContext };
