import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  IonList,
  IonItem,
  IonText,
  useIonViewWillEnter,
  IonListHeader,
  IonLabel,
  IonButton
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import useApi from "../../data/Api";
import { ProductViewDto } from "../../models/Product";
import { useNotificationContext } from "../../context/NotificationProvider";
import Icon from "../Icon";
import { faEdit, faTooth } from "@fortawesome/free-solid-svg-icons";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import useCurrency from "../../hooks/useCurrency";
import ProductUpsertModal from "./ProductUpsertModal";
import useTranslation from "../../data/useTranslation";
import ButtonTextIcon from "../ButtonTextIcon";
import MiddleBox from "../MiddleBox";
import ValueLabel from "../ValueLabel";
import InfoBox from "../InfoBox";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const ProductView: React.FC<Props> = ({ match }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [data, setData] = useState<ProductViewDto>();
  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { formatWithCurrencySign } = useCurrency();
  const { t } = useTranslation();

  const getProduct = () => {
    apiGet<ProductViewDto>("product/get?id=" + match.params.id)
      .then(setData)
      .catch(handleError);
  };
  useIonViewWillEnter(() => {
    getProduct();
  }, [match.params.id]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/products" />
          </IonButtons>
          <IonTitle>{t("product")}</IonTitle>
          <IonButtons slot="primary">
            <Can permission={Permission.ProductsUpdate}>
              <IonButton onClick={() => setShowEditModal(true)}>
                <ButtonTextIcon button="edit" />
              </IonButton>
            </Can>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data && (
          <>
            <MiddleBox className="box">
              <div className="ion-padding">
                <div className="ion-text-center ion-margin-top">
                  <Icon icon={faTooth} size="4x" />
                  <h3>{data.name}</h3>
                  <ValueLabel title={t("price")}>
                    {data.price !== undefined &&
                      formatWithCurrencySign(data.price)}
                  </ValueLabel>
                  {data.extras.length > 0 && (
                    <IonList>
                      <IonListHeader>{t("products.extras")}</IonListHeader>
                      {data.extras.map(e => (
                        <IonItem key={e.id}>
                          <IonLabel>{e.name}</IonLabel>
                          <IonText color="medium">
                            {formatWithCurrencySign(e.price)}
                          </IonText>
                        </IonItem>
                      ))}
                    </IonList>
                  )}

                  <InfoBox
                    hidden={data.extras.length > 0}
                    text={t("extras.noExtras")}
                  />

                  <Can permission={Permission.ProductsUpdate}>
                    <IonButton
                      color="secondary"
                      onClick={() => setShowEditModal(true)}
                    >
                      <Icon icon={faEdit} /> {t("edit")}
                    </IonButton>
                  </Can>
                </div>
              </div>
            </MiddleBox>
            <div className="ion-text-center ion-margin-top"></div>

            <ProductUpsertModal
              showModal={showEditModal}
              onCancel={() => setShowEditModal(false)}
              onSuccess={() => {
                getProduct();
                showSuccessToast("Product data updated");
                setShowEditModal(false);
              }}
              initialData={{ ...data, extras: data.extras.map(e => e.id) }}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withPermission(ProductView, Permission.ProductsRead);
