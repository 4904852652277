import i18n from "i18next";
import { useEffect } from "react";
import {
  useTranslation as usei18nextTranslation,
  initReactI18next
} from "react-i18next";
import useLocalStorage from "../hooks/useLocalStorage";
import { InvoiceStatus } from "../models/Invoice";
import { ProductionLogStatus, CaseStatus } from "../models/Case";
import PaymentType from "../models/PaymentType";
import Role from "../models/Role";
import { ProductType } from "../models/Teeth";
import en from "../translations/en.json";
import sr_cyrl from "../translations/sr-cyrl.json";
import sr from "../translations/sr.json";

const initTranslation = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: { common: en },
        sr_cyrl: { common: sr_cyrl },
        sr: { common: sr }
      },
      fallbackLng: "en",

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
};

export type Language = "en" | "sr_cyrl" | "sr";
interface LanguageOption {
  name: string;
  value: Language;
}
const Languages: LanguageOption[] = [
  {
    name: "English",
    value: "en"
  },
  {
    name: "Српски",
    value: "sr_cyrl"
  },
  {
    name: "Srpski",
    value: "sr"
  }
];

const useTranslation = () => {
  const { t: translate, i18n } = usei18nextTranslation("common");
  const [language, setLanguage] = useLocalStorage("lng", i18n.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const keyTransform = (key: string) =>
    key.includes(".") ? key : `general.${key}`;

  const changeLanguage = (lng: Language) => setLanguage(lng);
  const t = (key: string) => translate(keyTransform(key));
  const tPlural = (key: string, count: number) =>
    translate(keyTransform(key), { count });
  const tInterpolated = (key: string, data: { [key: string]: any }) =>
    translate(keyTransform(key), data);
  const tCaseStatus = (status: CaseStatus) => t(`caseStatus.${status}`);
  const tProductionLogStatus = (status: ProductionLogStatus) =>
    t(`productionLogStatus.${status}`);

  const tInvoiceStatus = (status: InvoiceStatus) =>
    t(`invoiceStatus.${status}`);
  const tRole = (role: Role) => t(`roles.${role}`);
  const tProductType = (type: ProductType) => t(`productType.${type}`);
  const tPaymentType = (type: PaymentType) => t(`paymentType.${type}`);
  const tPlaceholder = (propName: string) => t(propName);
  // tInterpolated("placeholder", { prop: t(propName) });
  const tRequired = (propName: string) =>
    tInterpolated("isRequired", { prop: t(propName) });
  const tError = (errorCode: string) => translate([`error.${errorCode}`, ""]);

  return {
    changeLanguage,
    t,
    tPlural,
    tInterpolated,
    tError,
    tCaseStatus,
    tProductionLogStatus,
    tInvoiceStatus,
    tRole,
    tProductType,
    tPaymentType,
    tPlaceholder,
    tRequired,
    language
  };
};

export default useTranslation;
export { initTranslation, Languages };
