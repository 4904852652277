import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonCol,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import useTranslation from "../../data/useTranslation";
import { useAuthContext } from "../../context/AuthProvider";
import { ProductionLogFormDto, ProductionLogStatus } from "../../models/Case";
import ProductionLogUpsertModal from "./ProductionLogUpsertModal";
import EmployeeTasks from "./EmployeeTasks";
import ButtonTextIcon from "../ButtonTextIcon";
import ProductionLogTable from "./ProductionLogTable";

const MyTasks: React.FC = () => {
  const { user } = useAuthContext();

  const [segment, setSegment] = useState<"myTasks" | "log">("myTasks");
  const [showFilter, setShowFilter] = useState(false);

  const [productionLogInitialData, setProductionLogInitialData] =
    useState<ProductionLogFormDto>();

  const [showProductionLogModal, setShowProductionLogModal] = useState(false);
  useEffect(() => {
    if (productionLogInitialData) setShowProductionLogModal(true);
    else setShowProductionLogModal(false);
  }, [productionLogInitialData]);
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("myTasks.title")}</IonTitle>
          {segment === "log" && (
            <IonButtons slot="primary">
              <IonButton
                color={showFilter ? "primary" : ""}
                onClick={() => setShowFilter(!showFilter)}
              >
                <ButtonTextIcon button="filter" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
        <IonToolbar className="header_toolbar2">
          <IonSegment
            onIonChange={e => setSegment(e.detail.value as any)}
            value={segment}
            mode="md"
          >
            <IonSegmentButton value="myTasks">
              <IonLabel>{t("myTasks.title")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="log">
              <IonLabel>{t("workLog.title")}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {user?.userId && segment === "myTasks" && (
          <IonRow>
            <IonCol className="flex_hide_if_empty">
              <EmployeeTasks
                urlProps={{
                  status: ProductionLogStatus.Started,
                  employeeId: user.userId
                  // getUnassigned: true
                }}
                mode="inProgress"
                editProductionLog={log => {
                  setProductionLogInitialData(log);
                }}
              />
            </IonCol>
            <IonCol>
              <EmployeeTasks
                urlProps={{
                  status: ProductionLogStatus.Scheduled,
                  employeeId: user.userId
                  // getUnassigned: true
                }}
                mode="scheduled"
                editProductionLog={log => {
                  setProductionLogInitialData(log);
                }}
              />
            </IonCol>
          </IonRow>
        )}

        {segment === "log" && user?.userId && (
          <ProductionLogTable
            initialUrlProps={{
              status: null,
              taskId: null,
              employeeId: user.userId,
              page: 1,
              pageSize: 10
            }}
            mode="employeeLogs"
            showFilterProp={showFilter}
          />
        )}

        {productionLogInitialData && (
          <ProductionLogUpsertModal
            showProductionLogModal={showProductionLogModal}
            initialData={productionLogInitialData}
            onSuccess={() => setProductionLogInitialData(undefined)}
            onCancel={() => setProductionLogInitialData(undefined)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default MyTasks;
