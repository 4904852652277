import React from "react";
import { CaseStatus } from "../../models/Case";
import Icon from "../Icon";
import {
  faCheckCircle,
  faCog,
  faDoorOpen,
  faPause,
  faTeethOpen,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";

interface Props {
  status: CaseStatus;
  underConstruction: boolean;
  title: string;
  className?: string;
}

const getProps = (status: CaseStatus, underConstruction: boolean) => {
  switch (status) {
    case CaseStatus.Finished:
      return {
        // hidden: true,
        className: "successColor",
        icon: faCheckCircle
      };
    case CaseStatus.OnHold:
      return {
        // hidden: true,
        className: "mediumColor",
        icon: faPause
      };
    case CaseStatus.InProgress:
      if (underConstruction)
        return {
          // hidden: true,
          className: "mediumColor",
          icon: faCog,
          spin: true
        };
      else
        return {
          hidden: true,
          className: "secondaryColor",
          icon: faTeethOpen
        };
    case CaseStatus.OutOfLab:
      return {
        // hidden: true,
        className: "mediumColor",
        icon: faDoorOpen
      };
    case CaseStatus.Cancelled:
      return {
        className: "dangerColor",
        icon: faWindowClose
      };
  }
};

const CaseStatusIcon: React.FC<Props> = ({
  status,
  underConstruction,
  title,
  className
}) => {
  const props = getProps(status, underConstruction);

  return (
    <Icon {...props} title={title} className={className ?? props.className} />
  );
};

export default CaseStatusIcon;
