import DoctorFormDto from "./Doctor";
import Employee from "./Employee";
import PagedData from "./PagedData";
import ProductDto, { ExtraDto } from "./Product";
import TaskFormDto from "./Task";
import { ProductType, ToothCondition } from "./Teeth";
import TryoutDto from "./Tryout";
import { RegisterData } from "./User";

export enum CaseStatus {
  OnHold = 0,
  InProgress = 1,
  OutOfLab = 2,
  Finished = 3,
  Cancelled = 10
}

export enum ProductionLogStatus {
  None = 0,
  Started = 1,
  Done = 2,
  Failed = 3,
  Scheduled = 4
}

export enum CaseFlag {
  None = "",
  Red = "red",
  Orange = "orange",
  Green = "green",
  Blue = "blue",
  Violet = "violet",
  Black = "black"
}

export interface CaseHasConditionDto {
  toothId: number;
  conditionId: ToothCondition;
}

export interface CaseFormDto {
  id: number;
  status?: CaseStatus;
  doctorId: number;
  patient?: string;
  name: string;
  autoName?: boolean;
  notes?: string;
  finishDate?: string;
  received: string;
  flag: CaseFlag;
  pan?: string;
  products: CaseProductDto[];
  teethCondition: CaseHasConditionDto[];
}

export interface CaseRecordDto extends CaseFormDto {
  created: string;
  updated: string;
}
export interface CaseListDto extends CaseFormDto {
  status: CaseStatus;
  underConstruction: boolean;
  finishDate?: string;
  created: string;
  doctor: DoctorFormDto;
  hasInvoice: boolean;
  updated?: string;
  createdBy: number;
}
export interface CaseViewDto extends Omit<CaseListDto, "products"> {
  createdByUser: RegisterData;
  tryouts: TryoutDto[];
  products: CaseProductViewDto[];
}

export interface CaseProductHasExtra {
  extras: ExtraDto;
  toothIds: number[];
}

export interface CaseProductDto {
  id: number;
  productTypeId: ProductType;
  productId: number;
  shade: string;
  quantity: number;
  toothIds: number[];
  extras: CaseProductHasExtra[];
}

export interface CaseProductViewDto extends CaseProductDto {
  product: ProductDto;
}

export interface ProductionLogFormDto {
  id: number;
  userId: number;
  caseId: number;
  taskId: number | null;
  taskName?: string;
  started?: string;
  finished?: string;
  scheduled?: string;
  notes?: string;
  status: ProductionLogStatus;
  created?: string;
  updated?: string;
}

export interface ProductionLogListDto extends ProductionLogFormDto {
  task?: TaskFormDto;
  user?: Employee;
  case?: CaseListDto;
  userName?: string;
  taskName?: string;
}

export interface ProductionLogTableRowDto extends ProductionLogListDto {
  caseName: string;
  bonus?: number;
}

export interface TaskCountDto {
  id: number;
  name: number;
  count: number;
  total: number;
}

export interface ProductCountDto {
  id: number;
  name: number;
  count: number;
}

export interface ProductTypeCountDto {
  type: ProductType;
  count: number;
}

export interface ProductionLogSummaryDto {
  data: PagedData<ProductionLogTableRowDto>;
  tasks: TaskCountDto[];
  total: number;
}
export interface ProductionLogStatsDto {
  tasks: TaskCountDto[];
}

export interface CaseChartsDto {
  dateDocHistogram: CaseDateHistogramPointDto[];
}

export interface CaseDateHistogramPointDto {
  date: string;
  count: number;
  name: string;
}

export interface CasesSummaryDto {
  data: PagedData<CaseListDto>;
  products: ProductCountDto[];
  extras: ProductCountDto[];
  productTypes: ProductTypeCountDto[];
  charts: CaseChartsDto;
}
export interface CasesStatsDto {
  products: ProductCountDto[];
  extras: ProductCountDto[];
  productTypes: ProductTypeCountDto[];
  charts: CaseChartsDto;
}

export default CaseFormDto;
