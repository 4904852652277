const baseUrl =
  process.env.REACT_APP_BASE_URL ?? "https://localhost:44309/api/";
const appUrl = process.env.REACT_APP_APP_URL ?? "http://localhost:8100/";

// const baseUrl = "https://devapi.dentallabguru.com/api/";
// const appUrl = "https://devapp.dentallabguru.com/";

// const baseUrl = "https://localhost:44309/api/";
// const appUrl = "http://localhost:8100/";

const APP_UPDATE_EVENT = "SW-UPDATE";

export { baseUrl, appUrl, APP_UPDATE_EVENT };
