import React from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel
} from "@ionic/react";
import { Route, Redirect } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Page } from "./navigation/Navigation";
import useTranslation from "../data/useTranslation";

interface Props {
  pages: Page[];
}

const MainTabs: React.FC<Props> = ({ pages }) => {
  const { t } = useTranslation();
  return (
    <IonTabs>
      <IonRouterOutlet>
        {pages.map(p => (
          <Route key={p.url} path={p.url} component={p.component} exact />
        ))}
        <Redirect exact path="/tabs" to="/" />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" class="ion-hide-lg-up" hidden={pages.length < 2}>
        {pages.map(p => (
          <IonTabButton
            key={p.url}
            tab={p.url.replace("/tabs/", "")}
            href={p.url}
          >
            <FontAwesomeIcon icon={p.icon!} size="lg" />
            <IonLabel>{t(p.title)}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
