import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonSkeletonText,
  IonFab,
  IonFabButton,
  IonIcon
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import { firestore } from "../../data/firebase";
import useTranslation from "../../data/useTranslation";
import { useAuthContext } from "../../context/AuthProvider";
import { CaseFlag, CaseStatus, ProductionLogFormDto } from "../../models/Case";
import NoResults from "../NoResults";
import ProductionLogUpsertModal from "../productionLog/ProductionLogUpsertModal";
import DashboardDto from "../../models/DashboardDto";
import DashboardCard from "./DashboardCard";
import CaseUpsertModal from "../case/CaseUpsertModal";
import Icon from "../Icon";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import Can from "../Can";
import { add } from "ionicons/icons";

const Dashboard: React.FC = () => {
  const history = useHistory();
  const [showAddCase, setShowAddCase] = useState(false);
  const { lab, user } = useAuthContext();

  const [productionLogInitialData, setProductionLogInitialData] =
    useState<ProductionLogFormDto>();
  const { t } = useTranslation();

  const [data, setData] = useState<DashboardDto[]>();
  const [myData, setMyData] = useState<DashboardDto[]>([]);
  const [restData, setRestData] = useState<DashboardDto[]>([]);

  const [showProductionLogModal, setShowProductionLogModal] = useState(false);
  useEffect(() => {
    if (productionLogInitialData) setShowProductionLogModal(true);
    else setShowProductionLogModal(false);
  }, [productionLogInitialData]);
  useEffect(
    () =>
      firestore
        .collection("dashboard")
        .doc(lab?.id.toString())
        .onSnapshot(
          {
            includeMetadataChanges: true
          },
          doc => {
            const dbData = doc.data();
            setData(dbData ? Object.values(dbData) : []);
          }
        ),
    [lab]
  );

  useEffect(() => {
    const hasUserData = (d: DashboardDto) =>
      d.logs.find(l => l.userId === user?.userId) !== undefined;
    const hasNoUserData = (d: DashboardDto) => !hasUserData(d);
    if (!data) {
      setMyData([]);
      setRestData([]);
    } else {
      setMyData(data.filter(hasUserData));
      setRestData(data.filter(hasNoUserData));
    }
  }, [data, user?.userId]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("dashboard.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!data && <IonSkeletonText animated title={t("loading")} />}
        {data && data.length === 0 && (
          <NoResults title={t("dashboard.noCases")}>
            <Can permission={Permission.CaseCreate}>
              <div className="ion-text-center ion-margin-top">
                <IonButton
                  color="secondary"
                  onClick={() => setShowAddCase(true)}
                >
                  <Icon icon={faPlus} />
                  {t("cases.new")}
                </IonButton>
              </div>
            </Can>
          </NoResults>
        )}
        {data && data.length > 0 && (
          <IonGrid>
            {myData.length > 0 && (
              <>
                {/* {restData.length > 0 && <h3>{t("cases.myCases")}</h3>} */}
                <IonRow className="dashboard-boxes">
                  {myData.map((d, i) => (
                    <IonCol
                      key={i}
                      size-xs="12"
                      size-xl="4"
                      size-lg="6"
                      size-md="6"
                    >
                      <DashboardCard
                        dto={d}
                        setProductionLogInitialData={
                          setProductionLogInitialData
                        }
                      />
                    </IonCol>
                  ))}
                </IonRow>
              </>
            )}

            {restData.length > 0 && (
              <>
                {myData.length > 0 && <h3>{t("cases.otherCases")}</h3>}
                <IonRow className="dashboard-boxes">
                  {restData.map((d, i) => (
                    <IonCol
                      key={i}
                      size-xs="12"
                      size-xl="4"
                      size-lg="6"
                      size-md="6"
                    >
                      <DashboardCard
                        dto={d}
                        setProductionLogInitialData={
                          setProductionLogInitialData
                        }
                      />
                    </IonCol>
                  ))}
                </IonRow>
              </>
            )}
          </IonGrid>
        )}

        <Can permission={Permission.CaseCreate}>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton
              onClick={() => setShowAddCase(true)}
              title={t("cases.new")}
            >
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </Can>

        {productionLogInitialData && (
          <ProductionLogUpsertModal
            showProductionLogModal={showProductionLogModal}
            initialData={productionLogInitialData}
            onSuccess={() => setProductionLogInitialData(undefined)}
            onCancel={() => setProductionLogInitialData(undefined)}
          />
        )}

        <CaseUpsertModal
          initialData={{
            id: 0,
            status: CaseStatus.InProgress,
            doctorId: 0,
            name: "",
            received: new Date().toISOString(),
            flag: CaseFlag.None,
            products: [],
            teethCondition: [],
            autoName: true
          }}
          showCaseModal={showAddCase}
          onSuccess={id => {
            setShowAddCase(false);
            history.replace("/case/" + id);
          }}
          onCancel={() => setShowAddCase(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default withPermission(Dashboard, Permission.DashboardRead);
