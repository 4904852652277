import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonContent,
  useIonViewWillEnter,
  IonButton,
  IonPopover,
  IonList,
  IonItem
} from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { CaseViewDto } from "../../models/Case";
import CaseInfoView from "./CaseInfoView";
import CaseUpsertModal from "./CaseUpsertModal";
import useTranslation from "../../data/useTranslation";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import CaseInvoices from "./CaseInvoices";
import Can from "../Can";
import ButtonTextIcon from "../ButtonTextIcon";
import { useAuthContext } from "../../context/AuthProvider";
import { concatinate } from "../../data/Helpers";
import CaseFlagIcon from "./CaseFlagIcon";
import Icon from "../Icon";
import {
  faDownload,
  faEdit,
  faEllipsisV,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const Case: React.FC<Props> = ({ match }) => {
  const [segment, setSegment] = useState<"info" | "invoices">("info");
  const history = useHistory();
  const { apiGet, apiDelete, apiBlobDownload } = useApi();
  const { handleError, showAlert, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const [downloading, setDownloading] = useState(false);
  const [showEditCase, setShowEditCase] = useState(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined
  });
  const [getSvg, setGetSvg] = useState<() => string | undefined>(
    () => undefined
  );

  const [$case, setCase] = useState<CaseViewDto>();
  const { user } = useAuthContext();

  const getCase = () =>
    apiGet<CaseViewDto>(`case/get?id=${match.params.id}`)
      .then(setCase)
      .catch(handleError);

  useIonViewWillEnter(getCase);

  const dismissPopover = () =>
    setShowPopover({ showPopover: false, event: undefined });

  const saveAsPdf = () => {
    setDownloading(true);
    apiBlobDownload("POST", `case/SaveAsPdf`, {
      id: match.params.id,
      arches: getSvg()
    })
      .catch(handleError)
      .finally(() => setDownloading(false));
    dismissPopover();
  };

  const onDeleteCaseClicked = () => {
    $case &&
      showAlert({
        title: t("warning"),
        message: t("cases.confirmDelete"),
        buttons: [
          {
            text: t("cancel"),
            role: "cancel"
          },
          {
            text: t("delete"),
            handler: () => {
              apiDelete("case/delete?id=" + $case.id)
                .then(() => {
                  history.replace("/tabs/dashboard");
                  showSuccessToast(t("cases.caseDeleted"));
                })
                .catch(handleError);
            }
          }
        ]
      });
    dismissPopover();
  };

  const renderSwitch = () => {
    if (!$case) return;

    switch (segment) {
      case "info":
        return (
          <CaseInfoView
            $case={$case}
            onChange={getCase}
            showEdit={() => setShowEditCase(true)}
            exposeGetSvg={s => setGetSvg(() => s)}
          />
        );
      case "invoices":
        return <CaseInvoices $case={$case} />;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle className="case-page-title">
            {$case && (
              <>
                <CaseFlagIcon flag={$case.flag} />
                {concatinate([$case.name, $case.pan])}
              </>
            )}
          </IonTitle>
          <IonButtons slot="primary">
            {$case && (
              <>
                <Can permission={Permission.CaseUpdate}>
                  <IonButton
                    className="ion-margin-start ion-hide-sm-down"
                    onClick={() => setShowEditCase(true)}
                  >
                    <ButtonTextIcon button="edit" />
                  </IonButton>
                </Can>
                <IonButton
                  className="ion-hide-sm-down"
                  hidden={segment !== "info"}
                  // hidden
                  onClick={saveAsPdf}
                  disabled={downloading}
                >
                  <ButtonTextIcon button="download" loading={downloading} />
                </IonButton>
                <Can permission={Permission.CaseDelete}>
                  <IonButton
                    className="ion-margin-start ion-hide-sm-down"
                    onClick={onDeleteCaseClicked}
                  >
                    <ButtonTextIcon button="delete" />
                  </IonButton>
                </Can>

                <IonButton
                  className="ion-hide-sm-up"
                  onClick={(e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e });
                  }}
                >
                  <Icon icon={faEllipsisV} />
                </IonButton>
              </>
            )}
          </IonButtons>
        </IonToolbar>
        <IonToolbar
          className="header_toolbar2"
          hidden={!user?.hasPermission(Permission.InvoiceRead)}
        >
          <IonSegment
            onIonChange={e => setSegment(e.detail.value as any)}
            value={segment}
          >
            <IonSegmentButton value="info">
              <IonLabel>{t("info")}</IonLabel>
            </IonSegmentButton>

            <Can permission={Permission.InvoiceRead}>
              <IonSegmentButton value="invoices">
                <IonLabel>{t("invoices.title")}</IonLabel>
              </IonSegmentButton>
            </Can>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>{renderSwitch()}</IonContent>
      <Can permission={Permission.CaseCreate}>
        {$case && (
          <CaseUpsertModal
            initialData={$case}
            showCaseModal={showEditCase}
            onSuccess={() => {
              setShowEditCase(false);
              getCase();
            }}
            onCancel={() => setShowEditCase(false)}
          />
        )}
      </Can>
      {$case && (
        <IonPopover
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={dismissPopover}
        >
          <IonList className="ion-padding" lines="inset">
            <Can permission={Permission.CaseUpdate}>
              <IonItem
                onClick={() => {
                  setShowEditCase(true);
                  dismissPopover();
                }}
              >
                <Icon icon={faEdit} />
                <IonLabel>{t("edit")}</IonLabel>
              </IonItem>
            </Can>
            <IonItem
              disabled={downloading}
              onClick={saveAsPdf}
              hidden={segment !== "info"}
            >
              <Icon icon={faDownload} />
              <IonLabel>{t("saveAsPdf")}</IonLabel>
            </IonItem>
            <Can permission={Permission.CaseDelete}>
              <IonItem onClick={onDeleteCaseClicked}>
                <Icon icon={faTrash} className="dangerColor" />
                <IonLabel>{t("delete")}</IonLabel>
              </IonItem>
            </Can>
          </IonList>
        </IonPopover>
      )}
    </IonPage>
  );
};

export default withPermission(Case, Permission.CaseRead);
