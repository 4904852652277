import React from "react";

interface Props {
  title: string;
  children: React.ReactNode;
  large?: boolean;
  bold?: boolean;
  notAvailable?: boolean;
}

const ValueLabel: React.FC<Props> = ({
  title,
  children,
  large,
  bold,
  notAvailable
}) => {
  const className =
    "label-value" +
    (large ? " large" : "") +
    (notAvailable ? " not-available" : "") +
    (bold ? " bold" : "");
  return (
    <>
      <label hidden={!title.length}>{title}:</label>
      <div className={className}>{children}</div>
    </>
  );
};

export default ValueLabel;
