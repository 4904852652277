import React from "react";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

interface DateRange {
  start?: string;
  end?: string;
}

interface Props {
  onChange: (v: DateRange) => void;
  onBlur?: () => void;
}

const PopupDateRangePicker: React.FC<Props> = ({ onChange, onBlur }) => (
  <RangePicker
    allowEmpty={[true, true]}
    onChange={r => {
      if (!r) {
        onChange({ start: undefined, end: undefined });
      } else {
        onChange({
          start: r[0] ? r[0].toISOString() : undefined,
          end: r[1] ? r[1].toISOString() : undefined
        });
      }
    }}
    onBlur={onBlur}
  />
);

export default PopupDateRangePicker;
