import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonButton,
  IonMenuButton
} from "@ionic/react";

import React, { useState } from "react";
import { SortDirection } from "../../models/PagedData";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import useTranslation from "../../data/useTranslation";
import InvoicesTable from "./InvoicesTable";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import ButtonTextIcon from "../ButtonTextIcon";
import SearchBar from "../SearchBar";

const Invoices: React.FC = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [saveAsPdf, setSaveAsPdf] = useState<() => void>(() => {});
  const [savingPdf, setSavingPdf] = useState(false);
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <SearchBar
            showSearchbar={showSearchbar}
            onSearch={setSearch}
            onCancel={() => {
              setShowSearchbar(false);
              setSearch("");
            }}
          >
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle onClick={() => setShowSearchbar(true)}>
              {t("invoices.title")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={() => setShowSearchbar(true)}>
                <Icon slot="icon-only" icon={faSearch} />
              </IonButton>
              <IonButton
                color={showFilter ? "primary" : ""}
                onClick={() => setShowFilter(!showFilter)}
              >
                <ButtonTextIcon button="filter" />
              </IonButton>
              <IonButton onClick={saveAsPdf} disabled={savingPdf}>
                <ButtonTextIcon button="download" loading={savingPdf} />
              </IonButton>
            </IonButtons>
          </SearchBar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <InvoicesTable
          initialUrlProps={{
            page: 1,
            pageSize: 10,
            status: null,
            sortBy: "created",
            sortDirection: SortDirection.Desc
          }}
          mode="allInvoices"
          showFilterProp={showFilter}
          searchProp={search}
          exposeSaveAsPdf={setSaveAsPdf}
          setSavingPdf={setSavingPdf}
        />
      </IonContent>
    </IonPage>
  );
};

export default withPermission(Invoices, Permission.InvoicesTableRead);
