import React from "react";
import useTranslation from "../../data/useTranslation";
import FormInput from "./FormIonInput";

interface Props {
  required?: boolean;
  disabled?: boolean;
}

const EmailFormItem: React.FC<Props> = ({ required, disabled }) => {
  const { t, tInterpolated, tRequired, tPlaceholder } = useTranslation();

  return (
    <FormInput
      name="email"
      label={t("email")}
      rules={{
        required: required ? tRequired("email") : undefined,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: tInterpolated("invalid", {
            prop: t("emailAddress")
          })
        }
      }}
      disabled={disabled}
      placeholder={tPlaceholder("email")}
      type="email"
      inputmode="email"
      autocomplete="off"
    />
  );
};

export default EmailFormItem;
