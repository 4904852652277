interface ToothPathData {
  id: number;
  path: string;
  gum: string;
  nameTextTransform: string;
  deadTooth: string;
  implant: string;
}

const ToothSvgPaths: ToothPathData[] = [
  {
    id: 18,

    path: "M63,295.5c-10.2-2-16.9-3.3-24.2-0.7c-7.5,2.5-9,8.2-10.2,12.6c-1.4,9.6,0.9,15.8,4.3,21   c1.1,5.1,1.6,10.2,13.2,13.8c10.1,4.8,20.2,3.1,30.4,1.7c8.6-1.3,14.1-4.2,16.8-8.5l4.2-7.4c1.5-4.6,3.4-8.9,0.9-14.8   c0.4-3.9-4.6-7.5-12.6-10.9C80.4,298.1,72.1,296.3,63,295.5L63,295.5z ",
    gum: "M22.8,287.8c-2.8,19.9-4.4,40.5-4.6,61.5h88.7c0.1-17.6,1.1-34.7,3-51.4L22.8,287.8z",
    deadTooth:
      "M78.1,320.5c-0.9,5.9-8.6,9.7-17.2,8.5c-8.7-1.2-15-7.1-14.1-13c0.9-5.9,8.6-9.7,17.2-8.5  C72.6,308.8,78.9,314.6,78.1,320.5z",
    implant:
      "M62.7,310.5c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S58.3,310.5,62.7,310.5z M58.9,318.5  c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8S58.9,316.4,58.9,318.5z",
    nameTextTransform: "matrix(1 0 0 1 0.9795 321.1232)"
  },
  {
    id: 17,
    path: "M39.3,264.3c-2.4,15.7,5.1,15.3,9.2,20.7c10.2,6.8,17.3,5,25.4,5.8c20.3,2,27.5-8.6,31.6-22   c1.8-8-1.5-10.1-5.2-14.7c-3.7-4.6-9.3-9.2-16.4-11.6c-22.6-12-37.1-8.6-45.2,6.8C36.7,255.4,36.3,260.7,39.3,264.3L39.3,264.3z ",

    gum: "M22.8,287.8l87,10.1c1.9-17.4,4.7-34.3,8.2-50.5l-81.9-21.1C30.4,246,25.9,266.6,22.8,287.8z",
    deadTooth:
      "M85,268.5c-1.7,5.8-9.8,8.5-18.2,6s-13.9-9.1-12.2-14.8c1.7-5.8,9.8-8.5,18.2-6  C81.3,256.1,86.7,262.8,85,268.5z",
    implant:
      "M69.7,256.3c4.4,0,8,3.6,8,8s-3.6,8-8,8c-4.4,0-8-3.6-8-8S65.2,256.3,69.7,256.3z M65.9,264.3  c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8C67.6,260.5,65.9,262.2,65.9,264.3z",
    nameTextTransform: "matrix(1 0 0 1 10.5629 257.8733)"
  },
  {
    id: 16,
    path: "M54.3,195.8c-4.3,16.3,3.2,28.7,23,37.1c5.6,2.5,9.4,7.7,18.9,3.5c2-1,4.3-3,6.9-5.4   c2.2-3.6,6.3-8.1,10.6-12.8c7-6.5,9-13.8-3.9-24c-4.2-3.1-7.3-6.3-10.4-9.4c-11.3-6.2-22.6-12.4-37.1-0.2   C57.8,186.3,54.6,189.5,54.3,195.8z ",

    gum: "M36.2,226.4l81.9,21.1c4.3-19.6,9.8-38.1,16.3-55.2l-75.1-25.9  C50.1,185.1,42.4,205.2,36.2,226.4z",
    deadTooth:
      "M99.5,212.8c-2.2,5.6-10.6,7.5-18.7,4.3s-12.9-10.3-10.7-15.9s10.6-7.5,18.7-4.3  S101.7,207.2,99.5,212.8z",
    implant:
      "M84.4,199.6c4.4,0,8,3.6,8,8s-3.6,8-8,8c-4.4,0-8-3.6-8-8S80,199.6,84.4,199.6z M80.6,207.6  c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8C82.3,203.8,80.6,205.5,80.6,207.6z",
    nameTextTransform: "matrix(1 0 0 1 29.1462 194.5399)"
  },
  {
    id: 15,
    path: "M96.7,141c8.3,0.7,15.7-0.1,29,9.4c6.8,4.7,7.3,11.1,4.5,18.3c-3.4,9-11.6,6.8-17.7,9.5   c-3.2,0.3-6.2,0.3-8.8-0.2c-1.8-1-4.4-1.2-7.4-1.1c-8.5-2.3-13-7.8-18-13C73.6,149.6,81.1,142.9,96.7,141L96.7,141z ",

    gum: "M59.2,166.3l75.1,25.9c4.5-12,9.5-23.3,14.9-33.8L82,126C73.7,138.7,66.1,152.1,59.2,166.3z",
    deadTooth:
      "M119.3,165.3c-1.8,5.7-10,8.2-18.4,5.6s-13.6-9.4-11.8-15.1s10-8.2,18.4-5.6  C115.9,152.8,121.2,159.6,119.3,165.3z",
    implant:
      "M103.8,152.3c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8  C95.8,155.9,99.4,152.3,103.8,152.3z M100,160.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8  C101.7,156.5,100,158.2,100,160.3z",
    nameTextTransform: "matrix(1 0 0 1 52.7295 144.4566)"
  },
  {
    id: 14,
    path: "M114.1,107.3c5.9-1.6,11.6-2.3,15.8,2c3.1,1.9,5.4,3.6,13,4.5c7.1,2.7,6.3,4.6,7.4,6.8   c1.5,3.8,1.1,6.7,0.1,9.4c-1.2,5-4.5,8.1-10.9,8.3c-3.5,1.1-7.9,1.6-14.3,0.7c-4.1,1.4-25,1.1-23.7-19.5   C101.6,114.9,102.5,110.3,114.1,107.3L114.1,107.3z ",

    gum: "M82,126l67.3,32.3c5.8-11.3,12.2-21.8,18.9-31.4l-57.6-38C100.3,100.3,90.8,112.7,82,126z",
    deadTooth:
      "M139.5,128.3c-1.9,5.6-9.8,8.1-17.6,5.4c-7.8-2.7-12.5-9.4-10.6-15c1.9-5.6,9.8-8.1,17.6-5.4  S141.4,122.7,139.5,128.3z",
    implant:
      "M125.6,114.6c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8  C117.6,118.2,121.2,114.6,125.6,114.6z M121.9,122.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8  C123.6,118.8,121.9,120.5,121.9,122.6z",
    nameTextTransform: "matrix(1 0 0 1 80.8129 102.8733)"
  },
  {
    id: 13,
    path: "M141.7,105.5c2.8,1.1,0.7,0.6,8.9,3.5c4.5,0.3,6.4,3.6,17-3.1c3.6-2.7,3.9-7.7,3.1-13.5   c-1.6-5.2-0.9-9.5-7.4-16.8c-1.9-1.9-4.7-4.9-12.5-4.6c-2.5,0.2-5.2,0.4-8.1,0.1c-3.2,0.5-6.4,0.1-9.7,7.7   c-3.7,4.9-4.8,9.9-3.8,15.1C130.2,99.3,133.8,103.3,141.7,105.5L141.7,105.5z ",

    gum: "M110.6,89l57.6,38c7.4-10.4,15.4-19.7,23.7-27.7l-46.4-42.7C133.2,66.1,121.5,76.9,110.6,89z",
    deadTooth:
      "M165.1,95.4c-1.9,5.6-9.8,8-17.6,5.3c-7.8-2.7-12.5-9.4-10.6-15.1c1.9-5.6,9.8-8,17.6-5.3  C162.3,83,167,89.7,165.1,95.4z",
    implant:
      "M150.3,82.7c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8  C142.2,86.3,145.8,82.7,150.3,82.7z M146.5,90.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8  C148.2,87,146.5,88.7,146.5,90.8z",
    nameTextTransform: "matrix(1 0 0 1 114.8133 66.7066)"
  },
  {
    id: 12,
    path: "M161.8,54.4c-2.2,2.3-3.2,4.7-2.8,7.6c1.8,4.3,3.3,8.9,8.6,12.1c2.5,1.4,5.1,3.1,8,2.7   l7.5,1.5c4.6,0.7,8.8,0.8,11.9-1c5.1-1.8,8-5.4,10.4-9.4c0.3-4.7,2.2-4.6,0.6-15.6c-0.4-1.2-0.2-2-3.6-5.5l-5-4.2   c-1.3-1.9-4-1.7-7.2-0.7c-9.1,2.7-4.7-0.8-26.2,11L161.8,54.4z ",

    gum: "M145.5,56.7L192,99.4c9.8-9.3,20.3-16.9,31.2-22.4l-27.9-49C177.8,35.1,161.1,44.7,145.5,56.7z",
    deadTooth:
      "M199.9,64.2c-1.4,5.8-9.1,8.9-17,6.9c-8-2-13.3-8.2-11.9-14c1.4-5.8,9.1-8.9,17-6.9  C196,52.1,201.3,58.4,199.9,64.2z",
    implant:
      "M185.8,52.4c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8C177.8,56,181.3,52.4,185.8,52.4  z M182,60.4c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8C183.7,56.6,182,58.3,182,60.4z",
    nameTextTransform: "matrix(1 0 0 1 160.4798 34.0399)"
  },
  {
    id: 11,
    path: "M206.2,35.8c0.1-7.2,5.2-6.6,9.1-8.1l10.7-2c11.3-2.9,15.2-1.5,21-1.1l9.9,2.2   c3.7,1.5,7.6,5.3,5.8,9.3l-2.5,5.5l-13.8,17.5c-5.6,6.7-12.3,6.8-19,7.5c-8.2-2-11.2-5.6-13.1-9.7c-1.5-3.7-7.1-12.1-7.2-12.9   C205,41.1,206.3,38.5,206.2,35.8z ",

    gum: "M266,13.9c-24.6,0-48.3,4.9-70.7,14.1l27.9,49c13.6-6.9,28-10.5,42.8-10.5c0,0,0.1,0,0.1,0V13.9  C266,13.9,266,13.9,266,13.9z",
    deadTooth:
      "M248.2,41.3c0.2,6-6.4,11-14.6,11.2c-8.2,0.2-15-4.4-15.2-10.4c-0.2-6,6.4-11,14.6-11.2  S248.1,35.4,248.2,41.3z",
    implant:
      "M232.4,33.4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S228,33.4,232.4,33.4z M228.6,41.4  c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8S228.6,39.3,228.6,41.4z",
    nameTextTransform: "matrix(1 0 0 1 216.4799 14.5399)"
  },
  {
    id: 21,
    path: "M324.6,44.2c-0.1,0.8-5.7,9.1-7.2,12.9c-2,4.1-5,7.7-13.1,9.7c-6.7-0.7-13.4-0.8-19-7.5   l-13.8-17.5l-2.5-5.5c-1.8-4,2.1-7.7,5.8-9.3l9.9-2.2c5.8-0.3,9.7-1.7,21,1.1l10.7,2c3.9,1.5,9,0.9,9.1,8.1   C325.5,38.6,326.8,41.1,324.6,44.2z ",

    gum: "M265.8,14c0,0-0.1,0-0.1,0v52.6c0,0,0.1,0,0.1,0c14.8,0,29.2,3.7,42.8,10.5L336.6,28  C314.2,18.9,290.4,14,265.8,14z",
    deadTooth:
      "M298.8,31c8.2,0.2,14.8,5.2,14.6,11.2s-7,10.6-15.2,10.4c-8.2-0.2-14.8-5.2-14.6-11.2  C283.7,35.5,290.5,30.8,298.8,31z",
    implant:
      "M299.2,33.2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S294.7,33.2,299.2,33.2z M295.4,41.2  c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8S295.4,39.1,295.4,41.2z",
    nameTextTransform: "matrix(1 0 0 1 296.23 14.2066)"
  },
  {
    id: 22,
    path: "M367.6,53c-21.4-11.8-17-8.3-26.2-11c-3.2-1-5.9-1.1-7.2,0.7l-5,4.2c-3.3,3.6-3.2,4.3-3.6,5.5   c-1.6,11,0.2,10.8,0.6,15.6c2.4,4,5.4,7.6,10.4,9.4c3.1,1.8,7.3,1.6,11.9,1l7.5-1.5c2.9,0.4,5.5-1.3,8-2.7   c5.4-3.2,6.8-7.7,8.6-12.1c0.4-2.9-0.6-5.3-2.8-7.6L367.6,53z ",

    gum: "M336.6,28l-27.9,49.1c10.9,5.5,21.3,13,31.2,22.4l46.4-42.7C370.7,44.8,354,35.2,336.6,28z",
    deadTooth:
      "M343.8,50.2c8-2,15.6,1.1,17,6.9s-3.9,12.1-11.9,14c-8,2-15.6-1.1-17-6.9  C330.5,58.5,335.8,52.2,343.8,50.2z",
    implant:
      "M354,60.5c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8S354,56.1,354,60.5z M346,56.7  c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C349.8,58.4,348.1,56.7,346,56.7z",
    nameTextTransform: "matrix(1 0 0 1 358.9796 37.3733)"
  },
  {
    id: 23,
    path: "M390.1,105.6c7.9-2.2,11.6-6.3,12.5-11.6c1-5.1-0.1-10.2-3.8-15.1c-3.3-7.6-6.5-7.2-9.7-7.7   c-2.9,0.3-5.5,0.2-8.1-0.1c-7.7-0.3-10.6,2.7-12.5,4.6c-6.5,7.3-5.8,11.6-7.4,16.8c-0.8,5.8-0.5,10.8,3.1,13.5   c10.7,6.7,12.6,3.4,17,3.1C389.4,106.2,387.3,106.7,390.1,105.6L390.1,105.6z ",

    gum: "M386.3,56.7l-46.4,42.7c8.4,8,16.3,17.2,23.7,27.7l57.6-38C410.3,77,398.6,66.2,386.3,56.7z",
    deadTooth:
      "M377.3,80.4c7.8-2.7,15.7-0.3,17.6,5.3s-2.8,12.4-10.6,15.1c-7.8,2.7-15.7,0.3-17.6-5.3  S369.5,83.1,377.3,80.4z",
    implant:
      "M389.6,90.8c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-4.4,3.6-8,8-8S389.6,86.4,389.6,90.8z M381.6,87  c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C385.3,88.7,383.6,87,381.6,87z",
    nameTextTransform: "matrix(1 0 0 1 403.5629 68.7899)"
  },
  {
    id: 24,
    path: "M417.7,107.4c11.6,2.9,12.5,7.5,12.7,12.2c1.3,20.6-19.6,21-23.7,19.5   c-6.4,0.9-10.8,0.4-14.3-0.7c-6.5-0.2-9.8-3.4-10.9-8.3c-1-2.7-1.4-5.6,0.1-9.4c1.1-2.2,0.2-4.1,7.4-6.8c7.6-1,9.9-2.7,13-4.5   C406.1,105.1,411.8,105.8,417.7,107.4L417.7,107.4z ",

    gum: "M421.2,89.1l-57.6,38c6.8,9.5,13.1,20,18.9,31.4l67.3-32.3C441,112.8,431.5,100.4,421.2,89.1z",
    deadTooth:
      "M402.9,113.4c7.8-2.7,15.7-0.2,17.6,5.4s-2.8,12.4-10.6,15c-7.8,2.7-15.7,0.2-17.6-5.4  C390.4,122.7,395.1,116,402.9,113.4z",
    implant:
      "M414.2,122.7c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-4.4,3.6-8,8-8S414.2,118.3,414.2,122.7z   M406.2,118.9c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C409.9,120.6,408.3,118.9,406.2,118.9z",
    nameTextTransform: "matrix(1 0 0 1 438.4796 106.9566)"
  },
  {
    id: 25,
    path: "M435.1,141.1c15.7,1.9,23.2,8.6,18.4,22.7c-5,5.1-9.5,10.7-18,13c-3-0.1-5.6,0.2-7.4,1.1   c-2.6,0.6-5.6,0.6-8.8,0.2c-6.1-2.7-14.3-0.5-17.7-9.5c-2.9-7.2-2.4-13.5,4.5-18.3C419.4,141,426.7,141.7,435.1,141.1L435.1,141.1z ",

    gum: "M449.8,126.1l-67.3,32.3c5.4,10.6,10.4,21.9,14.9,33.8l75.1-25.9  C465.7,152.2,458.1,138.7,449.8,126.1z",
    deadTooth:
      "M424.3,150.3c8.3-2.6,16.6-0.2,18.4,5.6c1.8,5.7-3.5,12.5-11.8,15.1  c-8.3,2.6-16.6,0.2-18.4-5.6C410.6,159.7,415.9,152.9,424.3,150.3z",
    implant:
      "M436,160.4c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8C432.4,152.4,436,156,436,160.4z   M428,156.6c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8C431.8,158.3,430.1,156.6,428,156.6z",
    nameTextTransform: "matrix(1 0 0 1 463.3129 145.7899)"
  },
  {
    id: 26,
    path: "M469.6,184.6c-14.5-12.2-25.8-6-37.1,0.2c-3.1,3.1-6.2,6.4-10.4,9.4c-13,10.1-10.9,17.5-3.9,24   c4.3,4.7,8.5,9.2,10.6,12.8c2.5,2.5,4.8,4.4,6.9,5.4c9.5,4.2,13.4-1,18.9-3.5c19.8-8.3,27.3-20.7,23-37.1   C477.2,189.6,474,186.4,469.6,184.6z ",

    gum: "M472.6,166.4l-75.1,25.9c6.5,17.2,11.9,35.7,16.3,55.2l81.9-21.1  C489.4,205.3,481.7,185.2,472.6,166.4z",
    deadTooth:
      "M443,197c8.1-3.2,16.5-1.3,18.7,4.3s-2.6,12.7-10.7,15.9s-16.5,1.3-18.7-4.3  S434.9,200.2,443,197z",
    implant:
      "M455.4,207.7c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8s3.6-8,8-8C451.8,199.7,455.4,203.3,455.4,207.7  z M447.4,203.9c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8S449.5,203.9,447.4,203.9z",
    nameTextTransform: "matrix(1 0 0 1 485.2305 190.8733)"
  },
  {
    id: 27,
    path: "M492.5,264.4c3.1-3.6,2.7-8.9,0.6-14.9c-8.1-15.5-22.6-18.8-45.2-6.8   c-7.1,2.4-12.7,7-16.4,11.6s-7,6.7-5.2,14.7c4.2,13.5,11.3,24,31.6,22c8.1-0.8,15.2,1,25.4-5.8   C487.3,279.7,494.9,280.1,492.5,264.4L492.5,264.4z ",

    gum: "M495.7,226.5l-81.9,21.1c3.6,16.2,6.3,33,8.2,50.5l87-10.1  C505.9,266.7,501.4,246.1,495.7,226.5z",
    deadTooth:
      "M459,253.8c8.4-2.4,16.6,0.3,18.2,6c1.7,5.8-3.8,12.4-12.2,14.8c-8.4,2.4-16.6-0.3-18.2-6  C445.1,262.8,450.6,256.2,459,253.8z",
    implant:
      "M470.2,264.4c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8S470.2,259.9,470.2,264.4z M462.1,260.6  c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S464.2,260.6,462.1,260.6z",
    nameTextTransform: "matrix(1 0 0 1 504.8972 255.8733)"
  },
  {
    id: 28,
    path: "M468.8,295.6c-9.1,0.7-17.4,2.5-22.9,6.7c-7.9,3.4-13,7-12.6,10.9c-2.5,6-0.6,10.3,0.9,14.8   l4.2,7.4c2.7,4.3,8.2,7.2,16.8,8.5c10.2,1.4,20.3,3.1,30.4-1.7c11.6-3.6,12.1-8.7,13.2-13.8c3.4-5.2,5.7-11.4,4.3-21   c-1.2-4.4-2.7-10-10.2-12.6C485.7,292.3,479,293.6,468.8,295.6L468.8,295.6z ",

    gum: "M509,287.9l-87,10.1c1.8,16.6,2.8,33.8,3,51.3h88.7C513.4,328.3,511.8,307.8,509,287.9z",
    deadTooth:
      "M467.9,307.6c8.7-1.2,16.4,2.5,17.2,8.5s-5.5,11.8-14.1,13c-8.7,1.2-16.4-2.5-17.2-8.5  C452.9,314.7,459.2,308.9,467.9,307.6z",
    implant:
      "M477.1,318.6c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8  C473.5,310.6,477.1,314.2,477.1,318.6z M469.1,314.8c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8  C472.9,316.5,471.2,314.8,469.1,314.8z",
    nameTextTransform: "matrix(1 0 0 1 513.7305 318.4566)"
  },
  {
    id: 38,
    path: "M498.9,403.7c0.9-5.6,4.5-6.1,1.7-18.5c-2-8.7-6-17.5-17.4-20.4c-6.4-1.6-20-5.3-27.5,0.1   c-2.3,1.6-4,2.4-5.4,3.4c-3.4,2.4-10.4,2.7-11.8,13.7c-0.5,4,1,5.7-0.9,14.5c-0.7,3.3-3.6,6-0.4,11.8c1.8,3.3,2.7,4.7,9.5,8.5   c2.2,1.3,24.1,3.2,29.2,3.1c5.1-0.1,6.4,2,13.7-5.2C492.9,411.3,495.4,409.7,498.9,403.7L498.9,403.7z ",

    gum: "M424.9,357.3c-0.1,20.8-1.5,41.1-4,60.6l87.1,7.5c3.5-22,5.4-44.8,5.5-68.1H424.9z",
    deadTooth:
      "M452.3,388.5c0.4-6,7.8-10.4,16.5-9.8c8.7,0.5,15.5,5.8,15.1,11.8s-7.8,10.4-16.5,9.8  C458.7,399.8,451.9,394.5,452.3,388.5z",
    implant:
      "M475.8,390.3c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8S475.8,385.8,475.8,390.3z M467.8,386.5  c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S469.9,386.5,467.8,386.5z",
    nameTextTransform: "matrix(1 0 0 1 514.8138 396.0399)"
  },
  {
    id: 37,
    path: "M473.2,425.7c4.9,2.1,4,3.7,9,7.8c3.6,3,6.8,7.6,7.4,17.2c0.4,6.7,3.3,14.3-2.9,20.8   c-3.7,3.9-1.4,4.8-13.4,9.9c-5.9,2.5-10.3,7-19.6,4.3c-4.4-1.3-5.8-1.3-13.7-3.5c-3.2-0.9-5.6-2.5-7.4-6.2   c-1.4-2.7-3.6-5.8-3.9-10.9c-0.2-4.3,1.2-6,1.7-8.3c1.4-5.8,0.3-8.2,0-10c-0.5-2.9,1-5.6,2.7-8.8c1.5-3,1.9-5.9,8.1-9.7   C446.8,424.8,456,421.6,473.2,425.7L473.2,425.7z ",

    gum: "M508,425.5l-87.1-7.5c-2.7,21-6.6,41.1-11.7,60l81.1,19.3C498.1,474.6,504.1,450.5,508,425.5z",
    deadTooth:
      "M443.2,452.4c1.3-5.9,9.2-9.1,17.8-7.2c8.5,1.9,14.4,8.1,13.2,14c-1.3,5.9-9.2,9.1-17.8,7.2  C447.9,464.5,442,458.2,443.2,452.4z",
    implant:
      "M467.1,454.8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8  C463.5,446.8,467.1,450.4,467.1,454.8z M459.1,451.1c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8  C462.9,452.7,461.2,451.1,459.1,451.1z",
    nameTextTransform: "matrix(1 0 0 1 503.8138 468.0399)"
  },
  {
    id: 36,
    path: "M430,489.5c-3.1-0.2-6.5,0.3-9.5,2.7c-1.3,1-3.3,3.5-4.4,4.6c-3,2.8-4.4,5.5-4.2,8.1   c0.3,4.7-1.8,9-4.6,14.2c-2.9,5.4-3.3,11.1,0.2,15.6c2.7,3.6,5.1,7.8,10.9,10.2c5.9,2.4,11.2,5.4,18.4,6.1   c4.7,0.4,6.8,2.3,14.1-1.5c2.7-1.4,7.7-4.7,10.3-6.3c1.7-1.1,4.2-1.5,6.1-9c1.6-6.5,4-12.9,0.2-20.2c-1.8-3.4-4.5-10.5-6.5-13.7   c-2.6-3.9-3.2-7.6-9.8-9.7c-3.8-1.2-6.5-3.1-13.8-2.3C435.5,488.7,431.9,489.6,430,489.5z ",

    gum: "M409.2,478c-5.5,20.6-12.3,39.8-20.2,57.2l70.8,29.7c12-20.7,22.3-43.4,30.6-67.6L409.2,478z",
    deadTooth:
      "M424,517.5c1.8-5.7,10-8.3,18.3-5.7c8.4,2.6,13.7,9.3,11.9,15.1c-1.8,5.7-10,8.3-18.3,5.7  C427.5,530,422.2,523.3,424,517.5z",
    implant:
      "M447.3,521.4c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-4.4,3.6-8,8-8S447.3,517,447.3,521.4z M439.3,517.6  c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C443.1,519.3,441.4,517.6,439.3,517.6z",
    nameTextTransform: "matrix(1 0 0 1 478.8138 542.0399)"
  },
  {
    id: 35,
    path: "M403.2,552.5c-13.1,5.8-13.4,8.9-13.2,13.3c0.3,5.4,0.7,7.3,0.7,9.8c-0.1,5.4,3.4,7,6,9.8   c7.1,7.4,11.6,5.6,17.1,5.8c5,0.2,8-1.4,10.9-2.1c8.1-1.9,7.7-4.1,10.5-6.8c4.5-4.2,4.7-10.9,2.2-17.7c-1.5-4.2-4.8-7.7-8.8-10.2   c-2.1-1.3-6.7-3.1-8.9-4C412.9,547.7,405,551.3,403.2,552.5L403.2,552.5z ",

    gum: "M369.4,571.3l59.1,38.3c11.4-13.6,21.8-28.5,31.2-44.7L389,535.2  C383.1,548.3,376.5,560.3,369.4,571.3z",
    deadTooth:
      "M400.3,564.8c2.3-5.6,10.6-7.4,18.8-4.1c8.1,3.3,12.8,10.5,10.6,16c-2.3,5.6-10.6,7.4-18.8,4.1  C402.8,577.5,398,570.4,400.3,564.8z",
    implant:
      "M423.7,570.3c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-4.4,3.6-8,8-8S423.7,565.8,423.7,570.3z   M415.7,566.5c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S417.8,566.5,415.7,566.5z",
    nameTextTransform: "matrix(1 0 0 1 447.4796 598.3732)"
  },
  {
    id: 34,
    path: "M401.4,596.4c4.7,5.5,5.9,9.5,5.3,12.7c-1.1,5.7-2.1,11.9-9,14c-11,3.4-13.6,0.8-18-0.6   c-5.3-1.8-8.7-5.3-12.2-8.1c-2-1.6-2.2-2.7-2.2-4c0-2.5-0.3-4.2-0.5-6.1c-0.4-3.9,0.7-7.1,2.8-9.7c2.4-3.1,5.6-5.5,11-5.7   c2.3-0.1,6.9-0.1,9.3-0.2C393.5,588.4,397.3,592.5,401.4,596.4L401.4,596.4z ",

    gum: "M347.4,600l47.4,43.6c12-10,23.3-21.4,33.8-34l-59.1-38.3C362.6,582,355.2,591.6,347.4,600z",
    deadTooth:
      "M371.8,602.1c1.9-5.6,9.8-8,17.6-5.4c7.8,2.7,12.5,9.4,10.6,15.1c-1.9,5.6-9.8,8-17.6,5.4  C374.6,614.5,369.9,607.7,371.8,602.1z",
    implant:
      "M394.5,607.5c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-4.4,3.6-8,8-8S394.5,603.1,394.5,607.5z   M386.4,603.7c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S388.5,603.7,386.4,603.7z",
    nameTextTransform: "matrix(1 0 0 1 412.4796 642.0399)"
  },
  {
    id: 33,
    path: "M359.1,619.4c-7.5-0.9-13.8-0.9-15.3,4.2c-0.8,2.6-3,7.5-3.8,10.1c-1.8,6.5-0.5,6.4,0.6,8.5   c0.9,1.8,4.2,4.5,5.6,5.9c2.1,2.1,4.5,3.9,9.5,3.9c7.5-0.1,15.5-0.3,16.6-10c0.3-2.8,1-8.5,0.7-11.3   C372.4,625.1,369.7,620,359.1,619.4L359.1,619.4z ",

    gum: "M322.5,621.8l34.5,47.6c13.2-7.2,25.8-15.8,37.8-25.8L347.4,600  C339.5,608.5,331.2,615.8,322.5,621.8z",
    deadTooth:
      "M343.8,633.7c0.7-5.5,7.4-9.2,15-8.2c7.6,1,13.2,6.2,12.5,11.7c-0.7,5.5-7.4,9.2-15,8.2  C348.7,644.5,343.1,639.2,343.8,633.7z",
    implant:
      "M365.9,635.5c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-4.4,3.6-8,8-8S365.9,631.1,365.9,635.5z   M357.9,631.7c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S360,631.7,357.9,631.7z",
    nameTextTransform: "matrix(1 0 0 1 376.1462 670.3733)"
  },
  {
    id: 32,
    path: "M314.4,641c2.5-6.4,9.2-6.9,13.1-3.4c1.7,1.5,4.5,5.2,6.1,6.8c1.7,1.6,5.8,4.2,7.1,6.1   c1.7,2.5,4,2.8,0.6,13c-1.9,5.6-5.9,8-10.7,9.2c-6.2,1.6-11.9,1.2-16.3-2c-3.6-2.6-9.1-4.6-7.1-11.3c1-3.3,2-3.5,4.4-9.8   C312.8,646.8,313.5,645.4,314.4,641L314.4,641z ",

    gum: "M293.7,636.2l20.9,50.4c14.6-4,28.8-9.8,42.4-17.1l-34.5-47.6  C313.3,628.1,303.6,633,293.7,636.2z",
    deadTooth:
      "M312.5,657.7c0.2-5.4,6.3-9.5,13.7-9.3c7.4,0.2,13.3,4.7,13.1,10.1s-6.3,9.5-13.7,9.3  C318.2,667.6,312.3,663,312.5,657.7z",
    implant:
      "M333.3,657.7c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-4.4,3.6-8,8-8S333.3,653.3,333.3,657.7z   M325.3,653.9c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S327.4,653.9,325.3,653.9z",
    nameTextTransform: "matrix(1 0 0 1 333.1467 693.3733)"
  },
  {
    id: 31,
    path: "M296.3,658.6c2.3,3.5,4,7.3,7.7,9.9c1.8,1.2,4.5,3.3,1.7,10.1c-1.3,3.3-5.2,5.3-10.5,5.2   c-4.3-0.1-8-0.4-14.7-0.5c-3.3-0.1-6.5,0.8-10.3-3.5c-1.5-1.8-4.3-3.6-0.4-9.6c2-3.1,6.8-8.6,9.6-11c2.4-2.1,2.8-4.4,9.2-4.7   C293.7,654,294.7,656.5,296.3,658.6L296.3,658.6z ",

    gum: "M265.8,640.5c0,18.9,0,36.6,0,52.6c16.7,0,33-2.3,48.8-6.6l-20.9-50.4  C284.6,639,275.3,640.5,265.8,640.5z",
    deadTooth:
      "M275.6,671.8c-0.1-4.8,5.1-8.8,11.7-8.9c6.6-0.2,12,3.6,12.1,8.3c0.1,4.8-5.1,8.8-11.7,8.9  C281.1,680.3,275.7,676.6,275.6,671.8z",
    implant:
      "M295.3,670.4c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-4.4,3.6-8,8-8S295.3,665.9,295.3,670.4z   M287.3,666.6c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S289.4,666.6,287.3,666.6z",
    nameTextTransform: "matrix(1 0 0 1 284.1467 706.0399)"
  },
  {
    id: 41,
    path: "M235.5,658.5c1.5-2,2.5-4.5,7.6-4.2c6.4,0.4,6.8,2.7,9.2,4.7c2.8,2.4,7.6,7.9,9.6,11   c4,6,1.1,7.9-0.4,9.6c-3.7,4.3-6.9,3.5-10.3,3.5c-6.7,0.1-10.4,0.4-14.7,0.5c-5.3,0.1-9.2-1.9-10.5-5.2c-2.8-6.8-0.1-8.9,1.7-10.1   C231.6,665.8,233.3,662,235.5,658.5L235.5,658.5z ",

    gum: "M266,640.4c-9.5,0-18.8-1.5-27.8-4.4l-20.9,50.4c15.8,4.3,32.1,6.6,48.8,6.6  C266,677,266,659.4,266,640.4z",
    deadTooth:
      "M244.1,680c-6.6-0.2-11.8-4.2-11.7-8.9c0.1-4.8,5.6-8.5,12.1-8.3c6.6,0.2,11.8,4.2,11.7,8.9  C256.1,676.5,250.7,680.2,244.1,680z",
    implant:
      "M244.5,662.3c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8  C236.5,665.9,240.1,662.3,244.5,662.3z M240.8,670.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8  C242.5,666.5,240.8,668.2,240.8,670.3z",
    nameTextTransform: "matrix(1 0 0 1 231.1466 706.2066)"
  },
  {
    id: 42,
    path: "M217.4,640.9c1,4.4,1.6,5.8,2.7,8.6c2.4,6.3,3.4,6.5,4.4,9.8c2.1,6.7-3.5,8.7-7.1,11.3   c-4.4,3.2-10.1,3.6-16.3,2c-4.8-1.2-8.8-3.6-10.7-9.2c-3.4-10.2-1.2-10.5,0.6-13c1.3-1.9,5.5-4.4,7.1-6.1c1.6-1.6,4.4-5.3,6.1-6.8   C208.2,634,214.9,634.5,217.4,640.9L217.4,640.9z ",
    gum: "M209.3,621.7l-34.5,47.6c13.6,7.4,27.8,13.1,42.4,17.1l20.9-50.4  C228.2,632.9,218.6,628.1,209.3,621.7z",
    deadTooth:
      "M206.2,667.7c-7.4,0.2-13.5-3.9-13.7-9.3c-0.2-5.4,5.7-9.9,13.1-10.1c7.4-0.2,13.5,3.9,13.7,9.3  C219.5,663,213.6,667.5,206.2,667.7z",
    implant:
      "M206.4,648.7c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8s-8-3.6-8-8C198.3,652.3,201.9,648.7,206.4,648.7  z M202.6,656.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8S202.6,654.7,202.6,656.8z",
    nameTextTransform: "matrix(1 0 0 1 184.4799 694.0399)"
  },
  {
    id: 43,
    path: "M172.7,619.3c-10.6,0.6-13.2,5.7-13.9,11.3c-0.3,2.8,0.3,8.5,0.7,11.3   c1.1,9.7,9.1,9.9,16.6,10c5,0,7.4-1.8,9.5-3.9c1.5-1.4,4.7-4.1,5.6-5.9c1.1-2.2,2.4-2,0.6-8.5c-0.7-2.6-3-7.5-3.8-10.1   C186.5,618.4,180.2,618.4,172.7,619.3L172.7,619.3z ",
    gum: "M184.4,599.9L137,643.5c12,10,24.6,18.7,37.8,25.8l34.5-47.6  C200.6,615.7,192.3,608.4,184.4,599.9z",
    deadTooth:
      "M175.5,645.3c-7.6,1-14.3-2.7-15-8.2c-0.7-5.5,4.9-10.7,12.5-11.7c7.6-1,14.3,2.7,15,8.2  C188.7,639.1,183.1,644.4,175.5,645.3z",
    implant:
      "M173.9,627.4c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8  C165.9,631,169.5,627.4,173.9,627.4z M170.1,635.4c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8  C171.8,631.6,170.1,633.3,170.1,635.4z",
    nameTextTransform: "matrix(1 0 0 1 140.1467 670.5399)"
  },
  {
    id: 44,
    path: "M130.4,596.3c4-3.9,7.9-8,13.6-7.8c2.3,0.1,6.9,0.1,9.3,0.2c5.4,0.2,8.6,2.6,11,5.7   c2,2.6,3.1,5.8,2.8,9.7c-0.2,1.9-0.5,3.7-0.5,6.1c0,1.3-0.2,2.3-2.2,4c-3.5,2.8-6.8,6.3-12.2,8.1c-4.4,1.5-7,4-18,0.6   c-7-2.1-7.9-8.2-9-14C124.5,605.8,125.8,601.8,130.4,596.3L130.4,596.3z ",
    gum: "M162.4,571.2l-59.1,38.3c10.5,12.6,21.8,23.9,33.8,34l47.4-43.6  C176.6,591.5,169.2,581.9,162.4,571.2z",
    deadTooth:
      "M149.4,617.1c-7.8,2.7-15.7,0.3-17.6-5.4c-1.9-5.6,2.8-12.4,10.6-15.1  c7.8-2.7,15.7-0.3,17.6,5.4C161.9,607.7,157.2,614.4,149.4,617.1z",
    implant:
      "M145.4,599.4c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8s-8-3.6-8-8C137.3,603,140.9,599.4,145.4,599.4z   M141.6,607.4c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8S141.6,605.3,141.6,607.4z",
    nameTextTransform: "matrix(1 0 0 1 103.3134 641.7066)"
  },
  {
    id: 45,
    path: "M128.6,552.4c-1.8-1.2-9.7-4.8-16.5-2c-2.3,0.9-6.8,2.7-8.9,4c-4,2.5-7.3,6-8.8,10.2   c-2.5,6.8-2.3,13.4,2.2,17.7c2.8,2.6,2.4,4.8,10.5,6.8c2.9,0.7,5.8,2.2,10.9,2.1c5.5-0.2,10,1.6,17.1-5.8c2.6-2.7,6.1-4.3,6-9.8   c0-2.6,0.4-4.4,0.7-9.8C142,561.3,141.6,558.2,128.6,552.4L128.6,552.4z ",
    gum: "M142.8,535.1l-70.8,29.7c9.4,16.2,19.8,31.1,31.2,44.7l59.1-38.3  C155.3,560.3,148.7,548.2,142.8,535.1z",
    deadTooth:
      "M120.9,580.7c-8.1,3.3-16.5,1.4-18.8-4.1s2.5-12.7,10.6-16c8.1-3.3,16.5-1.4,18.8,4.1  C133.8,570.3,129,577.5,120.9,580.7z",
    implant:
      "M116.1,562.1c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8  C108.1,565.7,111.7,562.1,116.1,562.1z M112.3,570.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8  C114,566.4,112.3,568.1,112.3,570.2z",
    nameTextTransform: "matrix(1 0 0 1 67.3129 600.8732)"
  },
  {
    id: 46,
    path: "M94.5,488.4c-7.3-0.8-9.9,1.1-13.8,2.3c-6.7,2.1-7.3,5.8-9.8,9.7c-2.1,3.2-4.8,10.3-6.5,13.7   c-3.8,7.3-1.4,13.7,0.2,20.2c1.9,7.4,4.3,7.8,6.1,9c2.5,1.6,7.6,4.9,10.3,6.3c7.3,3.7,9.5,1.9,14.1,1.5c7.2-0.7,12.6-3.7,18.4-6.1   c5.8-2.4,8.2-6.6,10.9-10.2c3.4-4.6,3.1-10.2,0.2-15.6c-2.8-5.2-4.9-9.4-4.6-14.2c0.2-2.7-1.2-5.3-4.2-8.1   c-1.2-1.1-3.2-3.7-4.4-4.6c-3-2.3-6.4-2.9-9.5-2.7C99.9,489.5,96.3,488.6,94.5,488.4z ",
    gum: "M122.6,477.9l-81.1,19.3c8.3,24.2,18.6,46.9,30.6,67.6l70.8-29.7  C134.9,517.7,128.1,498.5,122.6,477.9z",
    deadTooth:
      "M95.9,532.5c-8.4,2.6-16.6,0.1-18.3-5.7c-1.8-5.7,3.6-12.5,11.9-15.1s16.6-0.1,18.3,5.7  C109.6,523.2,104.3,529.9,95.9,532.5z",
    implant:
      "M92.5,513.3c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8  C84.5,516.9,88.1,513.3,92.5,513.3z M88.7,521.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8  C90.4,517.6,88.7,519.2,88.7,521.3z",
    nameTextTransform: "matrix(1 0 0 1 37.1462 546.0399)"
  },
  {
    id: 47,
    path: "M58.6,425.6c17.1-4.1,26.4-0.9,32.2,2.7c6.1,3.8,6.5,6.7,8.1,9.7c1.7,3.2,3.2,5.9,2.7,8.8   c-0.3,1.8-1.4,4.2,0,10c0.6,2.3,2,4,1.7,8.3c-0.3,5.1-2.5,8.3-3.9,10.9c-1.9,3.7-4.2,5.3-7.4,6.2c-7.9,2.2-9.3,2.2-13.7,3.5   c-9.4,2.7-13.7-1.8-19.6-4.3c-12-5.1-9.6-6-13.4-9.9c-6.2-6.4-3.3-14-2.9-20.8c0.6-9.6,3.8-14.2,7.4-17.2   C54.7,429.4,53.7,427.7,58.6,425.6L58.6,425.6z ",
    gum: "M110.9,417.8l-87.1,7.5c3.9,25.1,9.9,49.1,17.7,71.8l81.1-19.3  C117.5,458.9,113.6,438.8,110.9,417.8z",
    deadTooth:
      "M75.4,466.3c-8.5,1.9-16.5-1.4-17.8-7.2c-1.3-5.9,4.6-12.1,13.2-14s16.5,1.4,17.8,7.2  C89.8,458.1,83.9,464.4,75.4,466.3z",
    implant:
      "M72.7,446.7c4.4,0,8,3.6,8,8s-3.6,8-8,8c-4.4,0-8-3.6-8-8S68.3,446.7,72.7,446.7z M68.9,454.8  c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8C70.6,451,68.9,452.7,68.9,454.8z",
    nameTextTransform: "matrix(1 0 0 1 11.6462 474.0399)"
  },
  {
    id: 48,
    path: "M32.9,403.6c3.4,6,6,7.6,9.4,11c7.3,7.2,8.6,5,13.7,5.2c5.1,0.1,27-1.8,29.2-3.1   c6.8-3.8,7.6-5.2,9.5-8.5c3.2-5.8,0.3-8.5-0.4-11.8c-1.9-8.8-0.4-10.5-0.9-14.5c-1.5-11-8.4-11.3-11.8-13.7   c-1.3-0.9-3.1-1.7-5.4-3.4c-7.5-5.4-21.1-1.7-27.5-0.1c-11.4,2.9-15.4,11.7-17.4,20.4C28.4,397.5,32,398,32.9,403.6L32.9,403.6z ",
    gum: "M23.8,425.4l87.1-7.5c-2.5-19.5-3.8-39.7-4-60.5H18.2C18.4,380.7,20.3,403.4,23.8,425.4z",
    deadTooth:
      "M64.4,400.2c-8.7,0.5-16.1-3.9-16.5-9.8c-0.4-6,6.4-11.3,15.1-11.8c8.7-0.5,16.1,3.9,16.5,9.8  C79.9,394.4,73.1,399.7,64.4,400.2z",
    implant:
      "M64,382.1c4.4,0,8,3.6,8,8s-3.6,8-8,8c-4.4,0-8-3.6-8-8S59.6,382.1,64,382.1z M60.2,390.2  c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8C61.9,386.4,60.2,388.1,60.2,390.2z",
    nameTextTransform: "matrix(1 0 0 1 0.4795 400.0399)"
  }
];

const ToothIds = ToothSvgPaths.map(s => s.id);

// Bridge index = sum of connecting tooth IDs (example 11+12=23, 12+13=25 etc.)
let BridgeSvgPaths: string[] = [];

BridgeSvgPaths[23] = "M187.3,61.2c14.2-9.3,29.3-16.1,44.9-20.2";
BridgeSvgPaths[25] = "M151.5,91.4c11.2-11.8,23.1-22,35.8-30.2";
BridgeSvgPaths[27] = "M126.6,122.5c7.8-11.3,16.1-21.8,25-31.1";
BridgeSvgPaths[29] = "M104.3,160.7c6.8-13.6,14.2-26.4,22.3-38.2";
BridgeSvgPaths[31] = "M84.9,207.9c5.6-16.7,12.1-32.5,19.4-47.3";
BridgeSvgPaths[33] = "M70.3,263.7c3.7-19.4,8.6-38.1,14.6-55.8";
BridgeSvgPaths[35] = "M63.1,318.7c1.4-18.9,3.8-37.2,7.3-55";
BridgeSvgPaths[32] =
  "M232.2,40.9c11-2.9,22.4-4.4,33.9-4.3c11.3,0,22.3,1.5,33.1,4.2";
BridgeSvgPaths[43] = "M299.2,40.8c15.9,4.1,31.2,11,45.6,20.5";
BridgeSvgPaths[45] = "M344.9,61.3c12.5,8.2,24.3,18.3,35.4,30";
BridgeSvgPaths[47] = "M380.3,91.4c9,9.5,17.4,20.1,25.2,31.6";
BridgeSvgPaths[49] = "M405.5,123c7.8,11.5,15,23.9,21.6,37.2";
BridgeSvgPaths[51] = "M427.1,160.1c7.4,14.9,13.9,30.8,19.5,47.6";
BridgeSvgPaths[53] = "M446.7,207.7c6,18,11,37.1,14.7,56.9";
BridgeSvgPaths[55] = "M461.4,264.6c3.3,17.4,5.6,35.4,7,54";

BridgeSvgPaths[75] = "M468.5,390.1c-1.6,22.4-4.6,44.1-9,64.9";
BridgeSvgPaths[73] = "M459.4,455c-5,23.6-11.7,46-19.9,66.8";
BridgeSvgPaths[71] = "M439.5,521.9c-6.9,17.5-14.8,33.8-23.7,48.9";
BridgeSvgPaths[69] = "M415.9,570.7c-8.4,14.2-17.5,27.3-27.4,38.9";
BridgeSvgPaths[67] = "M388.4,609.7c-9,10.6-18.6,20.1-28.6,28.3";
BridgeSvgPaths[65] = "M359.8,638c-10.8,8.8-22.1,16.1-33.9,21.8";
BridgeSvgPaths[63] = "M325.9,659.8c-12.4,6-25.3,10.2-38.6,12.4";
BridgeSvgPaths[72] =
  "M287.3,672.2c-7.2,1.2-14.6,1.8-22,1.8c-7.3,0-14.6-0.6-21.7-1.8";
BridgeSvgPaths[83] = "M243.6,672.2c-13.5-2.2-26.5-6.5-39.1-12.7";
BridgeSvgPaths[85] = "M204.5,659.5c-11.6-5.7-22.7-12.9-33.2-21.5";
BridgeSvgPaths[87] = "M171.2,638c-10.2-8.3-19.8-17.9-28.9-28.6";
BridgeSvgPaths[89] = "M142.3,609.4c-9.8-11.6-18.9-24.6-27.2-38.8";
BridgeSvgPaths[91] = "M115.1,570.6c-8.8-15.1-16.7-31.5-23.6-49";
BridgeSvgPaths[93] = "M91.5,521.6c-8.1-20.8-14.8-43.1-19.7-66.6";
BridgeSvgPaths[95] = "M71.8,455c-4.4-20.8-7.4-42.5-8.9-64.9";

const VisilLowerJaw =
  "M425,357.8l-318.2-0.4c0,0.8,0,1.5,0,2.3C106.8,515.1,178,641,265.9,641S425,515.1,425,359.8  C425,359.1,425,358.5,425,357.8z";
const VisilUpperJaw =
  "M106.7,349.2l318.5,0.4c0-0.8,0-1.5,0-2.3c0-155.2-71.3-281-159.3-281s-159.3,125.8-159.3,281  C106.7,347.9,106.7,348.5,106.7,349.2z";

export {
  ToothSvgPaths,
  ToothIds,
  BridgeSvgPaths,
  VisilLowerJaw,
  VisilUpperJaw
};
