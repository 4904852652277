import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPicker,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  IonSkeletonText
} from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import ButtonTextIcon from "../ButtonTextIcon";
import {
  TaskListDto,
  SchedulingTaskDto,
  SchedulingTemplateListDto
} from "../../models/Task";
import Icon from "../Icon";
import {
  faFileImport,
  faPlus,
  faSave,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { ItemReorderEventDetail } from "@ionic/core";
import Employee from "../../models/Employee";
import NoResults from "../NoResults";
import InfoBox from "../InfoBox";
import ItemLabel from "../ItemLabel";
import ModalWrapper from "../ModalWrapper";
import PopupDatePicker from "../PopupDatePicker";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showSchedulingModal: boolean;
  caseId: number;
  initialData?: SchedulingTaskDto[];
}

const SchedulingUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  showSchedulingModal,
  caseId,
  initialData
}) => {
  const [submit] = useState<() => void>(() => {});
  const [loading, setLoading] = useState(false);
  const [showTemplatesPicker, setShowTemplatesPicker] = useState(false);

  const [isSubmitting] = useState(false);
  const { apiPost, apiGet } = useApi();
  const { t } = useTranslation();

  const { handleError } = useNotificationContext();
  const [employees, setEmployees] = useState<Employee[]>();

  const [isFormValid, setIsFormValid] = useState(false);
  const [tasks, setTasks] = useState<TaskListDto[]>([]);
  const [schedulingTemplates, setSchedulingTemplates] =
    useState<SchedulingTemplateListDto[]>();
  const [items, setItems] = useState<SchedulingTaskDto[]>(
    initialData ? initialData : []
  );

  useEffect(() => {
    apiGet<Employee[]>(`employee/getAll`).then(setEmployees).catch(handleError);
  }, []);

  const setItem = (index: number, item: SchedulingTaskDto) =>
    setItems(items => items.map((d, i) => (i === index ? item : d)));

  const reorderItems = (event: CustomEvent<ItemReorderEventDetail>) =>
    setItems(event.detail.complete(items));

  const upsert = () => {
    setLoading(true);
    apiPost<number>("productionLog/schedule/", {
      caseId: caseId,
      tasks: items
    })
      .then(id => onSuccess(id))
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const fetchTemplates = () =>
    apiGet<SchedulingTemplateListDto[]>(`schedulingTemplate/getAll`)
      .then(data => {
        setSchedulingTemplates(data);
      })
      .catch(handleError);

  const fetchTasks = () =>
    apiGet<TaskListDto[]>(`task/getAll`).then(setTasks).catch(handleError);

  const deleteItem = (i: number) => {
    let itemsNew = items;
    itemsNew.splice(i, 1);
    setItems(items => [...itemsNew]);
  };

  const addItem = () => {
    setItems(items => [
      ...items,
      {
        taskId: null,
        userId: null
      }
    ]);
  };

  useEffect(() => {
    setIsFormValid(!items.find(i => !i.taskId));
  }, [items]);

  useEffect(() => {
    fetchTasks();
    fetchTemplates();
  }, []);

  return (
    <ModalWrapper
      modalOpened={showSchedulingModal}
      dismiss={onCancel}
      modal="schedulingUpsert"
    >
      <IonModal
        isOpen={showSchedulingModal}
        onDidDismiss={onCancel}
        cssClass="big-modal"
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t("scheduling.modalTitle")}</IonTitle>
            <IonButtons slot="primary" hidden={!isFormValid}>
              <IonButton
                onClick={submit}
                disabled={isSubmitting || !isFormValid}
              >
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div
            hidden={tasks === undefined || schedulingTemplates === undefined}
          >
            {!tasks.length && (
              <NoResults title={t("scheduling.noTasksTitle")}>
                {t("scheduling.noTasks")}
              </NoResults>
            )}
            {!items.length && tasks.length > 0 && (
              <InfoBox text={t("scheduling.AddTaskHint")}></InfoBox>
            )}

            {(items === undefined ||
              tasks === undefined ||
              employees === undefined) && (
              <IonSkeletonText animated title={t("loading")} />
            )}

            {schedulingTemplates && tasks && (
              <>
                <IonList>
                  <IonReorderGroup
                    onIonItemReorder={reorderItems}
                    disabled={false}
                  >
                    {items &&
                      tasks &&
                      employees &&
                      items.map((item, i) => (
                        <IonRow
                          key={item.taskId === null ? i : i + 100 * item.taskId}
                          className="ion-align-items-center"
                        >
                          <IonCol size="auto">
                            <IonItem>
                              <IonReorder />
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem lines="none">
                              <IonLabel position="stacked">
                                {t("task.title")}
                              </IonLabel>
                              <IonSelect
                                value={item.taskId}
                                placeholder={t("scheduling.taskPlaceholder")}
                                interface="action-sheet"
                                cancelText={t("cancel")}
                                onIonChange={e =>
                                  setItem(i, {
                                    ...item,
                                    taskId: e.detail.value!
                                  })
                                }
                              >
                                {tasks.map(d => (
                                  <IonSelectOption key={d.id} value={d.id}>
                                    {d.name}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          </IonCol>

                          <IonCol>
                            <IonItem lines="none">
                              <IonLabel position="stacked">
                                {t("technician")}
                              </IonLabel>
                              <IonSelect
                                interface="action-sheet"
                                cancelText={t("cancel")}
                                placeholder={t("scheduling.chooseTechnician")}
                                value={item.userId ?? null}
                                onIonChange={e =>
                                  setItem(i, {
                                    ...item,
                                    userId: e.detail.value!
                                  })
                                }
                              >
                                <IonSelectOption key={0} value={null}>
                                  {t("scheduling.anyTechnician")}
                                </IonSelectOption>
                                {employees?.map(e => (
                                  <IonSelectOption key={e.id} value={e.id}>
                                    {`${e.firstName} ${e.lastName}`}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          </IonCol>

                          <IonCol>
                            <ItemLabel label={t("scheduling.deadline")}>
                              <PopupDatePicker
                                dateAndTime
                                value={item.scheduled}
                                onChange={v =>
                                  setItem(i, {
                                    ...item,
                                    scheduled: v
                                  })
                                }
                              />
                            </ItemLabel>
                            <IonItem
                              hidden
                              lines="none"
                              className="ion-margin-none"
                            >
                              <IonLabel position="stacked">
                                {t("scheduling.deadline")}
                              </IonLabel>
                              <IonDatetime
                                displayFormat="DD MMM YYYY HH:mm"
                                placeholder={t(
                                  "scheduling.scheduledPlaceholder"
                                )}
                                minuteValues="00, 05, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55"
                                value={item.scheduled}
                                min={new Date().toISOString()}
                                onIonChange={e =>
                                  setItem(i, {
                                    ...item,
                                    scheduled: e.detail.value!
                                  })
                                }
                              />
                            </IonItem>
                          </IonCol>

                          <IonCol size="auto">
                            <IonButton
                              onClick={() => deleteItem(i)}
                              size="small"
                              color="danger"
                              class="no-icon-margin ion-text-center"
                            >
                              <Icon
                                icon={faTimes}
                                className="white-icon"
                                title={t("delete")}
                              />
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      ))}
                  </IonReorderGroup>
                </IonList>
                <div className="ion-margin-top ion-text-center">
                  <IonButton
                    hidden={!tasks.length}
                    color="secondary"
                    disabled={items.length > 0 && !isFormValid}
                    fill="solid"
                    onClick={e => {
                      addItem();
                    }}
                  >
                    <Icon icon={faPlus} />
                    {t("scheduling.addTask")}
                  </IonButton>
                  <IonButton
                    hidden={!schedulingTemplates.length}
                    color="secondary"
                    fill="outline"
                    onClick={() => {
                      setShowTemplatesPicker(true);
                    }}
                  >
                    <Icon icon={faFileImport} />
                    {t("scheduling.importFromTemplate")}
                  </IonButton>
                </div>

                <IonButton
                  color="success"
                  expand="block"
                  className="ion-margin-top ion-margin-bottom"
                  onClick={() => {
                    upsert();
                  }}
                  disabled={loading || !isFormValid}
                >
                  {loading ? (
                    <Icon spin icon={faSpinner} />
                  ) : (
                    <Icon icon={faSave} />
                  )}
                  {t("save")}
                </IonButton>
              </>
            )}

            {!schedulingTemplates?.length && tasks.length > 0 && (
              <InfoBox text={t("scheduling.noTemplates")}></InfoBox>
            )}

            {schedulingTemplates && (
              <IonPicker
                isOpen={showTemplatesPicker}
                columns={[
                  {
                    name: "templates",
                    options: schedulingTemplates.map(t => {
                      return { text: t.name, value: t.id };
                    })
                  }
                ]}
                buttons={[
                  {
                    text: t("cancel"),
                    role: "cancel",
                    handler: () => {
                      setShowTemplatesPicker(false);
                    }
                  },
                  {
                    text: t("scheduling.importFromTemplate"),
                    handler: value => {
                      apiGet<SchedulingTemplateListDto>(
                        `schedulingTemplate/get?id=` + value.templates.value
                      )
                        .then(data => {
                          const newItems: SchedulingTaskDto[] = data.tasks.map(
                            t => {
                              return {
                                taskId: t.taskId,
                                userId: t.userId ? t.userId : null
                              };
                            }
                          );
                          setItems(items => items.concat(newItems));
                        })
                        .catch(handleError);
                      setShowTemplatesPicker(false);
                    }
                  }
                ]}
              ></IonPicker>
            )}
          </div>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default SchedulingUpsertModal;
