import React from "react";
import { IonInput } from "@ionic/react";
import { RegisterOptions } from "react-hook-form";
import FormItem from "./FormItem";

export interface FormItemProps {
  name: string;
  label: string;
  rules?: RegisterOptions;
  hidden?: boolean;
  disabled?: boolean;
  clearInput?: boolean;
  placeholder?: string;
  debaunce?: number;
  className?: string;
  min?: string;
  max?: string;
  step?: string;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  type?:
    | "date"
    | "email"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "url"
    | "time"
    | "week"
    | "month"
    | "datetime-local";
  inputmode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  autocomplete?:
    | "on"
    | "off"
    | "name"
    | "honorific-prefix"
    | "given-name"
    | "additional-name"
    | "family-name"
    | "honorific-suffix"
    | "nickname"
    | "email"
    | "username"
    | "new-password"
    | "current-password"
    | "one-time-code"
    | "organization-title"
    | "organization"
    | "street-address"
    | "address-line1"
    | "address-line2"
    | "address-line3"
    | "address-level4"
    | "address-level3"
    | "address-level2"
    | "address-level1"
    | "country"
    | "country-name"
    | "postal-code"
    | "cc-name"
    | "cc-given-name"
    | "cc-additional-name"
    | "cc-family-name"
    | "cc-family-name"
    | "cc-number"
    | "cc-exp"
    | "cc-exp-month"
    | "cc-exp-year"
    | "cc-csc"
    | "cc-type"
    | "transaction-currency"
    | "transaction-amount"
    | "language"
    | "bday"
    | "bday-day"
    | "bday-month"
    | "bday-year"
    | "sex"
    | "tel"
    | "tel-country-code"
    | "tel-national"
    | "tel-area-code"
    | "tel-local"
    | "tel-extension"
    | "impp"
    | "url"
    | "photo";
}

const FormInput: React.FC<FormItemProps> = ({
  name,
  label,
  rules,
  hidden,
  disabled,
  ...props
}) => (
  <FormItem
    name={name}
    label={label}
    rules={rules}
    hidden={hidden}
    disabled={disabled}
    input={({ onChange, onBlur, ...rest }) => (
      <IonInput
        onIonChange={e => onChange(e.detail.value)}
        onIonBlur={onBlur}
        {...rest}
        {...props}
      />
    )}
  />
);

export default FormInput;
