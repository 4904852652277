import { getRandomId } from "../../data/Helpers";
import { toothIdInLower, toothIdInUpper } from "../../hooks/useDentalNotation";
import { CaseHasConditionDto, CaseProductDto } from "../../models/Case";
import { ToothCondition, ProductType, ToothProps } from "../../models/Teeth";

type Action =
  | {
      type: "setCondition";
      data: ToothCondition;
    }
  | { type: "deleteCondition" }
  | { type: "setType"; data: ProductType }
  | { type: "fullUpper" }
  | { type: "fullLower" }
  | { type: "unselect" }
  | { type: "toothSelectChange"; toothId: number }
  | { type: "reset"; data: ArchesData }
  | { type: "deleteSelected" }
  | { type: "deleteProduct"; id: number }
  | { type: "onProductSelectClose" }
  | { type: "onProductUpsert"; data: CaseProductDto };

interface SelectProduct {
  toothIds: number[];
  type: ProductType;
}

interface ArchesData {
  teeth: ToothProps[];
  products: CaseProductDto[];
  teethConditions: CaseHasConditionDto[];
  anySelected: boolean;
  productSelected: number;
  anyConditionSelected: boolean;
  conditionSelected?: number;
  canBuildBridge: boolean;
  product?: SelectProduct;
}

const toothInUpper = (tooth: ToothProps) => toothIdInUpper(tooth.id);
const toothInLower = (tooth: ToothProps) => toothIdInLower(tooth.id);

const findSelectedIndices = (teeth: ToothProps[]) =>
  teeth
    .map((t, i) => ({ index: i, selected: t.selected }))
    .filter(t => t.selected)
    .map(t => t.index)
    .sort((a, b) => a - b);

const findFirstAndLastIndex = (teeth: ToothProps[]) => {
  const selectedIndices = findSelectedIndices(teeth);
  if (selectedIndices.length <= 1) return undefined;

  const first = selectedIndices[0];
  const last = selectedIndices[selectedIndices.length - 1];
  return { first, last };
};

const hasProductInBetweenSelected = (teeth: ToothProps[]) => {
  const firstLast = findFirstAndLastIndex(teeth);
  if (!firstLast) return false;

  return (
    teeth
      .slice(firstLast.first, firstLast.last)
      .find(t => t.productId !== undefined && t.type !== ProductType.Visil) !==
    undefined
  );
};

const canBuildBridge = (teeth: ToothProps[]) => {
  const selected = teeth.filter(t => t.selected);
  return (
    !hasProductInBetweenSelected(teeth) &&
    selected.length === 2 &&
    (selected.every(toothInUpper) || selected.every(toothInLower))
  );
};

const calculateQuantity = (product: CaseProductDto) => {
  return product.productTypeId === ProductType.Bridge ||
    product.productTypeId === ProductType.Crown
    ? product.toothIds.length
    : 1;
};

const teethStateUpdate: (data: ArchesData) => ArchesData = (
  data: ArchesData
) => {
  const anySelected = data.teeth.find(t => t.selected) !== undefined;
  return {
    ...data,
    anySelected: anySelected,
    productSelected:
      findSelectedProductsIds(data.teeth, data.products).find(pid => pid) ?? 0,
    anyConditionSelected: anySelected
      ? data.teeth.find(t =>
          t.selected === true ? t.condition !== undefined : false
        ) !== undefined
      : false,
    canBuildBridge: canBuildBridge(data.teeth)
  };
};

/**
 * Creates new teeth and conditions.
 * @param data The current state of the data.
 * @param conditionChanged Wether the teeth conditions have changed or we should take them from the teethConditions list.
 * @return The new state of the data.
 */
const buildTeeth = (data: ArchesData, conditionChanged: boolean) => {
  const newTeeth = data.teeth.map(t => {
    const product = data.products.find(product =>
      product.toothIds.includes(t.id)
    );
    // the condition is either in the conditions or provided by the tooth itself
    const conditionId = conditionChanged
      ? t.condition
      : data.teethConditions.find(c => c.toothId === t.id)?.conditionId;

    return {
      ...t,
      selected: false,
      type: product?.productTypeId,
      shade: product?.shade,
      productId: product?.productId,
      condition: conditionId
    };
  });

  return teethStateUpdate({
    ...data,
    teeth: newTeeth,
    teethConditions: newTeeth
      .filter(t => t.condition)
      .map(t => ({ toothId: t.id, conditionId: t.condition! }))
  });
};

const findSelectedProductsIds = (
  teeth: ToothProps[],
  products: CaseProductDto[]
) => {
  const selectedIds = teeth.filter(t => t.selected).map(t => t.id);
  return products
    .filter(p => p.toothIds.find(ptId => selectedIds.includes(ptId)))
    .map(p => p.id);
};

const findTeethForProductSelect = (
  data: ArchesData,
  productType: ProductType
) => {
  switch (productType) {
    case ProductType.Bridge:
      const firstLast = findFirstAndLastIndex(data.teeth);
      if (!firstLast) return undefined;
      const { first, last } = firstLast;

      const bridgeTeeth = data.teeth
        .filter((_, i) => i >= first && i <= last)
        .map(t => t.id);

      return bridgeTeeth;

    case ProductType.Denture:
    case ProductType.Miscellaneous:
    case ProductType.Crown:
    case ProductType.Visil:
    default:
      return data.teeth.filter(p => p.selected).map(t => t.id);
  }
};

const archesReducer = (data: ArchesData, action: Action): ArchesData => {
  switch (action.type) {
    case "setCondition":
      return buildTeeth(
        {
          ...data,
          teeth: data.teeth.map(t =>
            t.selected ? { ...t, condition: action.data, selected: false } : t
          )
        },
        true
      );
    case "deleteCondition":
      return buildTeeth(
        {
          ...data,
          teeth: data.teeth.map(t =>
            t.selected ? { ...t, condition: undefined, selected: false } : t
          )
        },
        true
      );

    case "setType":
      const toothIds = findTeethForProductSelect(data, action.data);
      if (!toothIds) return data;
      return {
        ...data,
        product: { toothIds, type: action.data }
      };

    case "toothSelectChange":
      const teeth = data.teeth.map(t =>
        t.id === action.toothId ? { ...t, selected: !t.selected } : t
      );
      return teethStateUpdate({
        ...data,
        teeth
      });
    case "unselect":
      return {
        ...data,
        teeth: data.teeth.map(t => ({ ...t, selected: false })),
        anySelected: false,
        canBuildBridge: false,
        productSelected: 0,
        anyConditionSelected: false
      };
    case "fullUpper":
      return teethStateUpdate({
        ...data,
        teeth: data.teeth.map(t => ({ ...t, selected: toothInUpper(t) }))
      });
    case "fullLower":
      return teethStateUpdate({
        ...data,
        teeth: data.teeth.map(t => ({ ...t, selected: toothInLower(t) }))
      });

    case "onProductSelectClose":
      return data;
    case "onProductUpsert":
      const newProduct = {
        ...action.data,
        id: getRandomId(data.products.map(p => p.id)),
        quantity: calculateQuantity(action.data)
      };
      const newProducts =
        action.data.id === 0
          ? [...data.products, newProduct]
          : data.products.map(p => (p.id === action.data.id ? newProduct : p));
      return buildTeeth(
        {
          ...data,
          products: newProducts,
          anySelected: false,
          productSelected: 0,
          canBuildBridge: false,
          product: undefined
        },
        false
      );

    case "reset":
      return buildTeeth(action.data, false);
    case "deleteProduct":
      return buildTeeth(
        { ...data, products: data.products.filter(p => p.id !== action.id) },
        false
      );
    case "deleteSelected":
      const selectedProducts = findSelectedProductsIds(
        data.teeth,
        data.products
      );

      const newData = {
        ...data,
        teeth: data.teeth.map(t =>
          t.selected ? { ...t, condition: undefined } : t
        ),
        products: data.products.filter(p => !selectedProducts.includes(p.id))
      };

      return buildTeeth(newData, true);
  }
};

export default archesReducer;
