export default interface PagedData<T> {
  data: T[];
  page: number;
  pageCount: number;
  count: number;
}

export enum SortDirection {
  Asc = 0,
  Desc = 1
}
