import { UserError } from "../models/Errors";
import { baseUrl } from "./Constants";
import { useAuthContext } from "../context/AuthProvider";
import { saveAs } from "file-saver";
import useTranslation from "./useTranslation";

const createTokenHeader: (token?: string) => HeadersInit | undefined = (
  token?: string
) => (token ? { Authorization: `Bearer ${token}` } : undefined);
const createContentHeader: (nonJsonBody?: boolean) => HeadersInit | undefined =
  (nonJsonBody?: boolean) =>
    nonJsonBody ? undefined : { "Content-Type": "application/json" };

const createHeaders = (token?: string, nonJsonBody?: boolean) => ({
  headers: new Headers({
    Accept: "application/json",
    ...createTokenHeader(token),
    ...createContentHeader(nonJsonBody)
  })
});

const extractError = async (r: Response) => {
  if (r.status > 500) {
    throw new UserError(
      r.status,
      "An unexpected error has occured, please write to our support with the description and the steps to reproduce it."
    );
  }

  if (r.status === 400) {
    const error = await r.json();
    throw new UserError(error.code, error.message);
  }
  if (r.status === 403) {
    throw new UserError(r.status, "You are not allowed to see this resource!");
  }
  if (r.status === 404) {
    throw new UserError(r.status, "The resource does not exist");
  }

  const errorText = await r.text();
  throw new Error(errorText);
};

const unwrapResponse = async <TResult,>(r: Response) => {
  if (!r.ok) await extractError(r);

  const json = await r.json();
  return json as TResult;
};

const unwrapBlobResponse = async (r: Response) => {
  if (!r.ok) await extractError(r);

  const headers = r.headers.get("content-disposition");
  var filename = headers?.match(/filename="(.+)"/);
  // sometimes the filename omits the ""
  if (!filename) filename = headers?.match(/filename=(.+)/);

  const blob = await r.blob();

  // Download the file
  saveAs(blob, filename ? filename[1] : undefined);
};

const unwrapImageResponse = async (r: Response) => {
  if (!r.ok) await extractError(r);
  if (r.status === 204) return undefined;

  const headers = r.headers.get("content-disposition");
  var filename = headers?.match(/filename="(.+)"/);
  // sometimes the filename omits the ""
  if (!filename) filename = headers?.match(/filename=(.+)/);

  const blob = await r.blob();

  return new File([blob], filename ? filename[1] : "logo");
};

const fetchNoUnwrap = async (
  token: string | undefined,
  body: any,
  path: string,
  method: string,
  nonJsonBody?: boolean,
  language?: string
) => {
  const headers = createHeaders(token, nonJsonBody);
  language && headers.headers.set("lng", language);

  const bodyString = body
    ? nonJsonBody
      ? body
      : JSON.stringify(body)
    : undefined;

  return fetch(baseUrl + path, {
    ...headers,
    method,
    body: bodyString
  });
};

const apiFetch = async <TResult,>(
  method: string,
  path: string,
  token?: string,
  body?: any,
  nonJsonBody?: boolean,
  language?: string
) => {
  const result = await fetchNoUnwrap(
    token,
    body,
    path,
    method,
    nonJsonBody,
    language
  );
  return await unwrapResponse<TResult>(result);
};

const apiBlobDownload = async (
  method: string,
  path: string,
  token?: string,
  body?: any,
  language?: string
) => {
  const result = await fetchNoUnwrap(
    token,
    body,
    path,
    method,
    false,
    language
  );
  return await unwrapBlobResponse(result);
};

const apiGetImage = async (path: string, token?: string) => {
  const result = await fetch(baseUrl + path, {
    ...createHeaders(token, true),
    method: "GET"
  });

  return await unwrapImageResponse(result);
};

const useApi = () => {
  const { user } = useAuthContext();
  const { language } = useTranslation();

  const apiGet = async <TResult,>(path: string) => {
    const token = await user?.getIdToken();
    return await apiFetch<TResult>(
      "GET",
      path,
      token,
      undefined,
      undefined,
      language
    );
  };
  const apiPost = async <TResult,>(path: string, body: any) => {
    const token = await user?.getIdToken();
    return await apiFetch<TResult>(
      "POST",
      path,
      token,
      body,
      undefined,
      language
    );
  };
  const apiPut = async <TResult,>(path: string, body: any) => {
    const token = await user?.getIdToken();
    return await apiFetch<TResult>(
      "PUT",
      path,
      token,
      body,
      undefined,
      language
    );
  };
  const apiDelete = async <TResult,>(path: string) => {
    const token = await user?.getIdToken();
    return await apiFetch<TResult>(
      "DELETE",
      path,
      token,
      undefined,
      undefined,
      language
    );
  };

  const apiBFetch = async (method: string, path: string, body: any) => {
    const token = await user?.getIdToken();
    return await apiBlobDownload(method, path, token, body, language);
  };

  const apiPostMultiForm = async <TResult,>(path: string, body: FormData) => {
    const token = await user?.getIdToken();
    return await apiFetch<TResult>("POST", path, token, body, true, language);
  };
  const apiPutMultiForm = async <TResult,>(path: string, body: FormData) => {
    const token = await user?.getIdToken();
    return await apiFetch<TResult>("PUT", path, token, body, true, language);
  };
  const apiGetImg = async (path: string) => {
    const token = await user?.getIdToken();
    return await apiGetImage(path, token);
  };

  return {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
    apiBlobDownload: apiBFetch,
    apiGetImage: apiGetImg,
    apiPostMultiForm,
    apiPutMultiForm
  };
};

export { apiFetch, apiGetImage };
export default useApi;
