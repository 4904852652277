import { useEffect, useState } from "react";
import useTranslation from "../data/useTranslation";

interface DateMethods {
  toDateString: (input: string) => string;
  toDateTimeString: (input: string) => string;
  toTimeString: (input: string) => string;
}

const checkInput = (callback: (v: string) => string) => (input: string) =>
  input ? callback(input) : "";

const chooseLanguage = (lng?: string) => {
  if (!lng) return "de-DE";

  const locales =
    lng === "en"
      ? "en-US"
      : lng === "sr"
      ? "sr-Latn-RS"
      : lng === "sr_cyrl"
      ? "sr-Cyrl-RS"
      : lng;

  return locales ? [locales, "de-DE"] : "de-DE";
};

const toLocaleDateString =
  (options: Intl.DateTimeFormatOptions) => (lng?: string) =>
    checkInput(input =>
      new Date(input).toLocaleDateString(chooseLanguage(lng), options)
    );
const toLocaleString =
  (options: Intl.DateTimeFormatOptions) => (lng?: string) =>
    checkInput(input =>
      new Date(input).toLocaleString(chooseLanguage(lng), options)
    );

const toDateString = toLocaleDateString({
  month: "2-digit",
  day: "2-digit",
  year: "numeric"
});

const toDateTimeString = toLocaleString({
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit"
});

const toTimeString = toLocaleString({
  hour: "2-digit",
  minute: "2-digit"
});

const useDate = () => {
  const { language } = useTranslation();
  const [exports, setExports] = useState<DateMethods>({
    toDateString: toDateString(),
    toDateTimeString: toDateTimeString(),
    toTimeString: toTimeString()
  });

  useEffect(() => {
    setExports({
      toDateString: toDateString(language),
      toDateTimeString: toDateTimeString(language),
      toTimeString: toTimeString(language)
    });
  }, [language]);

  return exports;
};

export default useDate;
