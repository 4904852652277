import React, { useCallback, useEffect, useState } from "react";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSkeletonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import { Permission } from "../../models/Permissions";
import { ExtraDto, ProductViewDto } from "../../models/Product";
import { getMatchingProductTypes } from "../../models/Teeth";
import Can from "../Can";
import Icon from "../Icon";
import ProductUpsertModal from "../product/ProductUpsertModal";
import useDentalNotation from "../../hooks/useDentalNotation";
import Select, { OptionTypeBase } from "react-select";
import CreatableSelect from "react-select/creatable";
import ButtonTextIcon from "../ButtonTextIcon";
import { CaseProductDto, CaseProductHasExtra } from "../../models/Case";
import useApi from "../../data/Api";
import InfoBox from "../InfoBox";
import ExtrasUpsertModal from "../product/ExtrasUpsertModal";
import { useAuthContext } from "../../context/AuthProvider";
import SelectAndButton from "../SelectAndButton";
import ModalWrapper from "../ModalWrapper";

interface Props {
  isOpen: boolean;
  initialData: CaseProductDto;
  labProducts: ProductViewDto[];
  onLabProductUpdate: () => void;
  onCancel: () => void;
  onSuccess: (v: CaseProductDto) => void;
}

interface SelectOption extends OptionTypeBase {
  label: string;
  value: string;
}
interface GroupedOption {
  label: string;
  options: SelectOption[];
}

const ProductSelectModal: React.FC<Props> = ({
  isOpen,
  initialData,
  labProducts,
  onLabProductUpdate,
  onCancel,
  onSuccess
}) => {
  const { user } = useAuthContext();
  const [showAddModal, setShowAddModal] = useState(false);
  const [product, setProduct] = useState<CaseProductDto>(initialData);
  const [productExtras, setProductExtras] = useState<ExtraDto[]>([]);
  const [groupedExtras, setGroupedExtras] = useState<GroupedOption[]>();
  const [allExtras, setAllExtras] = useState<ExtraDto[]>();
  const [newExtraIndex, setNewExtraIndex] = useState<number>();
  const [initialExtrasModalData, setInitialExtrasModalData] =
    useState<ExtraDto>({ id: 0, price: 0, name: "" });
  const { printCaseProductTeeth, toothIdToName } = useDentalNotation();
  const { showSuccessToast, showErrorToast, handleError } =
    useNotificationContext();
  const { t, tProductType } = useTranslation();
  const { apiGet } = useApi();

  const [teeth, setTeeth] = useState("");

  const loadExtras = () =>
    apiGet<ExtraDto[]>(`extras/getAll`).catch(e => {
      handleError(e);
      return [];
    });

  useEffect(() => {
    setProduct(initialData);
    loadExtras().then(setAllExtras);
  }, [initialData]);

  useEffect(() => {
    if (!allExtras) return;
    setGroupedExtras([
      {
        label: t("extras.productExtras"),
        options: productExtras.map(e => ({
          label: e.name,
          value: e.id.toString()
        }))
      },
      {
        label: t("extras.otherExtras"),
        options: allExtras
          .filter(e => !productExtras.find(p => p.id === e.id))
          .map(e => ({
            label: e.name,
            value: e.id.toString()
          }))
      }
    ]);
  }, [productExtras, allExtras]);

  useEffect(() => {
    if (product.productId !== initialData.productId)
      setProduct(product => ({ ...product, extras: [] }));
    if (product.productId <= 0) return;

    setProductExtras(
      labProducts.find(lp => lp.id === product.productId)?.extras ?? []
    );
  }, [product.productId, labProducts]);

  useEffect(() => {
    setTeeth(printCaseProductTeeth(initialData));
  }, [initialData, printCaseProductTeeth]);

  const onSubmit = useCallback(() => onSuccess(product), [product]);

  const onProductAdded = async (id: number) => {
    showSuccessToast(t("products.added"));
    setShowAddModal(false);
    onLabProductUpdate();
    setProduct(p => ({ ...p, productId: id }));
  };

  const replaceExtra = (extra: CaseProductHasExtra, index: number) =>
    setProduct({
      ...product,
      extras: product.extras.map((e, i) => (i === index ? extra : e))
    });

  const deleteExtra = (i: number) =>
    setProduct(product => ({
      ...product,
      extras: product.extras.filter((_, index) => index !== i)
    }));

  return (
    <ModalWrapper modalOpened={isOpen} dismiss={onCancel} modal="productSelect">
      <IonModal isOpen={isOpen} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {product && tProductType(product.productTypeId)}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={onSubmit}>
                <ButtonTextIcon button="save" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem lines="none">
            <IonLabel position="stacked">
              {t("products.selectedTeeth")}
            </IonLabel>
            {teeth}
          </IonItem>

          {labProducts && (
            <>
              <SelectAndButton
                label={t("products.title") + "*"}
                select={
                  <Select
                    placeholder={t("products.selectPlaceholder")}
                    classNamePrefix={"rselect"}
                    value={
                      product.productId > 0
                        ? {
                            value: product.productId,
                            label: product.productId
                              ? labProducts.find(
                                  lp => lp.id === product.productId
                                )?.name
                              : ""
                          }
                        : null
                    }
                    onChange={v => {
                      v &&
                        setProduct({
                          ...product,
                          productId: v.value
                        });
                    }}
                    options={labProducts.map(p => ({
                      value: p.id,
                      label: p.name
                    }))}
                    noOptionsMessage={() => t("noRecords")}
                  />
                }
                button={
                  <Can permission={Permission.ProductsCreate}>
                    <IonButton
                      color="secondary"
                      fill="outline"
                      onClick={() => setShowAddModal(true)}
                    >
                      <ButtonTextIcon button="newProduct" />
                    </IonButton>
                  </Can>
                }
              />
            </>
          )}
          <IonItem lines="none">
            <IonLabel position="stacked">{t("cases.color")}</IonLabel>
            <IonInput
              placeholder={t("cases.colorPlaceholder")}
              clearInput
              value={product.shade}
              onIonChange={e =>
                setProduct(p => ({ ...p, shade: e.detail.value! }))
              }
            />
          </IonItem>

          <IonGrid>
            <IonItem lines="none" hidden={!product.extras.length}>
              <IonLabel position="stacked">{t("extras.title")}</IonLabel>
            </IonItem>
            {product.extras.map((extra, i) => (
              <IonRow key={i + 3000}>
                <IonCol size-xs="10" size-md="">
                  {allExtras ? (
                    <CreatableSelect
                      placeholder={t("extras.selectExtraPlaceholder")}
                      classNamePrefix={"rselect"}
                      options={groupedExtras}
                      value={
                        extra.extras.id > 0
                          ? {
                              value: allExtras
                                .find(ae => ae.id === extra.extras.id)!
                                .id.toString(),
                              label: allExtras.find(
                                ae => ae.id === extra.extras.id
                              )!.name
                            }
                          : null
                      }
                      onChange={newValue => {
                        if (newValue !== null && newValue.value) {
                          const newExtra = {
                            ...extra,
                            extras: {
                              id: parseInt(newValue.value),
                              name: allExtras.find(
                                extra => extra.id.toString() === newValue.value
                              )!.name
                            }
                          };
                          replaceExtra(newExtra, i);
                        }
                      }}
                      loadingMessage={() => t("loading")}
                      onCreateOption={inputValue => {
                        if (user?.hasPermission(Permission.ProductsCreate)) {
                          setInitialExtrasModalData({
                            id: 0,
                            price: 0,
                            name: inputValue
                          });
                          setNewExtraIndex(i);
                        } else {
                          showErrorToast(t("noPermissionError"));
                        }
                      }}
                      allowCreateWhileLoading={false}
                      formatCreateLabel={o =>
                        `${t("permissions.types.create")}: "${o}"`
                      }
                      noOptionsMessage={() => t("noRecords")}
                    />
                  ) : (
                    <IonSkeletonText animated />
                  )}
                </IonCol>
                <IonCol size="auto" hidden={product.toothIds.length <= 1}>
                  {product.toothIds.map(t => (
                    <span
                      className={
                        extra.toothIds.includes(t)
                          ? "toothSelected"
                          : "toothNotSelected pointer"
                      }
                      onClick={() => {
                        const newExtra = {
                          ...extra,
                          toothIds: extra.toothIds.includes(t)
                            ? extra.toothIds.filter(tooth => tooth !== t)
                            : [...extra.toothIds, t]
                        };
                        replaceExtra(newExtra, i);
                      }}
                      key={t}
                    >
                      {toothIdToName(t)}
                    </span>
                  ))}
                </IonCol>
                <Can permission={Permission.ProductsDelete}>
                  <IonCol>
                    <IonButton
                      size="small"
                      color="danger"
                      onClick={() => deleteExtra(i)}
                      className="delete-extra-btnw ion-float-right"
                    >
                      <ButtonTextIcon button="delete" />
                    </IonButton>
                  </IonCol>
                </Can>
              </IonRow>
            ))}
            <IonRow hidden={!product.productId}>
              <IonButton
                fill="outline"
                className="ion-margin-top"
                onClick={() => {
                  setProduct({
                    ...product,
                    extras: [
                      ...product.extras,
                      {
                        toothIds: product.toothIds,
                        extras: { id: 0, name: "" }
                      }
                    ]
                  });
                }}
              >
                <Icon icon={faPlus} /> {t("extras.add")}
              </IonButton>
            </IonRow>
          </IonGrid>

          <InfoBox
            hidden={
              !product.id || !productExtras.length || product.extras.length > 0
            }
            text={t("extras.noProductExtras")}
          />

          <IonButton
            class="ion-margin-top"
            color="success"
            expand="block"
            type="submit"
            disabled={product.productId <= 0}
            onClick={onSubmit}
          >
            <Icon icon={faSave} />
            {t("save")}
          </IonButton>

          <ProductUpsertModal
            showModal={showAddModal}
            initialData={{
              id: 0,
              name: "",
              price: 0,
              productTypes: getMatchingProductTypes(product.productTypeId),
              extras: []
            }}
            onSuccess={onProductAdded}
            onCancel={() => setShowAddModal(false)}
          />

          <ExtrasUpsertModal
            showModal={newExtraIndex !== undefined}
            initialData={initialExtrasModalData}
            onCancel={() => {
              setInitialExtrasModalData({ id: 0, price: 0, name: "" });
              setNewExtraIndex(undefined);
            }}
            onSuccess={async id => {
              try {
                const allExtras = await loadExtras();
                setAllExtras(allExtras);
                const newOption = allExtras.find(e => e.id === id)!;
                setProduct(product => ({
                  ...product,
                  extras: product.extras.map((e, i) =>
                    i === newExtraIndex ? { ...e, extras: newOption } : e
                  )
                }));
              } finally {
                setInitialExtrasModalData({ id: 0, price: 0, name: "" });
                setNewExtraIndex(undefined);
              }
            }}
          />
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default ProductSelectModal;
