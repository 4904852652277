import React, { useCallback, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import CaseFormDto, { CaseFlag, CaseStatus } from "../../models/Case";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import ButtonTextIcon from "../ButtonTextIcon";
import Icon from "../Icon";
import { faSave, faSpinner, faTag } from "@fortawesome/free-solid-svg-icons";
import { getEnumValues, getEnumValuesUnfiltered } from "../../data/Helpers";
import Arches from "../arches/Arches";
import SelectDoctor from "../doctor/SelectDoctor";
import ItemLabel from "../ItemLabel";
import ModalWrapper from "../ModalWrapper";
import PopupDatePicker from "../PopupDatePicker";

const minDate = new Date(
  new Date().setFullYear(new Date().getFullYear() - 1)
).toISOString();

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showCaseModal: boolean;
  initialData: CaseFormDto;
}

const CaseUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showCaseModal
}) => {
  const [loading, setLoading] = useState(false);

  const { apiPost } = useApi();
  const { handleError, showSuccessToast, showErrorToast } =
    useNotificationContext();

  const [$case, setCase] = useState<CaseFormDto>(initialData);

  const { t, tPlaceholder, tCaseStatus } = useTranslation();

  useEffect(() => {
    setCase(initialData);
  }, [initialData.id]);

  const archesUpdated = useCallback((data: Partial<CaseFormDto>) => {
    setCase($case => ({
      ...$case,
      ...data
    }));
  }, []);

  const upsertCase = useCallback(() => {
    if (!$case.name) {
      showErrorToast(t("cases.noNameError"));
      return;
    }
    setLoading(true);
    apiPost<number>("case/upsert", $case)
      .then(id => {
        showSuccessToast(t("saved"));
        onSuccess(id);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [$case]);

  const handleDoctorChange = (id: number | undefined) =>
    setCase($case => ({ ...$case, doctorId: id ?? 0 }));

  return (
    <>
      <ModalWrapper
        modalOpened={showCaseModal}
        dismiss={onCancel}
        modal="caseUpsert"
      >
        <IonModal
          cssClass="big-modal"
          isOpen={showCaseModal}
          onDidDismiss={onCancel}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={onCancel}>
                  <ButtonTextIcon button="cancel" />
                </IonButton>
              </IonButtons>
              <IonTitle>
                {initialData.id > 0 ? t("cases.edit") : t("cases.new")}
              </IonTitle>
              <IonButtons slot="primary">
                <IonButton disabled={loading} onClick={upsertCase}>
                  <ButtonTextIcon button="save" loading={loading} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <SelectDoctor
              creatable
              initialId={initialData.doctorId}
              onChange={handleDoctorChange}
            />

            <IonItem lines="none">
              <IonLabel position="stacked">{t("patient")}</IonLabel>
              <IonInput
                placeholder={t("selectPatient")}
                value={$case.patient}
                onIonChange={e =>
                  setCase(o => ({ ...o, patient: e.detail.value! }))
                }
              />
            </IonItem>

            {/* <h3 className="form-header">{t("products")}</h3> */}

            <Arches
              $case={$case}
              onUpdate={archesUpdated}
              onCancel={onCancel}
            />

            <IonItem className="wion-margin-top">
              <IonLabel position="stacked">
                <p>{t("notes")}</p>
              </IonLabel>
              <IonTextarea
                placeholder={t("enterMoreInfo")}
                value={$case.notes}
                rows={6}
                onIonChange={e =>
                  setCase($case => ({ ...$case, notes: e.detail.value! }))
                }
              />
            </IonItem>
            <h3 className="form-header">{t("cases.scheduling")}</h3>

            <IonGrid>
              <IonRow>
                <IonCol>
                  <ItemLabel label={t("dates.received")}>
                    <PopupDatePicker
                      dateAndTime
                      minuteStep={5}
                      value={$case.received}
                      onChange={c =>
                        setCase(o => ({
                          ...o,
                          received: c ?? ""
                        }))
                      }
                    />
                  </ItemLabel>
                  <IonItem lines="none" hidden>
                    <IonLabel position="stacked">
                      <p>{t("dates.received")} *</p>
                    </IonLabel>
                    <IonDatetime
                      min={minDate}
                      className="12333"
                      max={new Date().toISOString()}
                      displayFormat="DD MMM YYYY HH:mm"
                      minuteValues="00, 05, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55"
                      placeholder={tPlaceholder("dates.enterReceivedDate")}
                      value={$case.received}
                      onIonChange={e =>
                        setCase(o => ({ ...o, received: e.detail.value! }))
                      }
                    />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <ItemLabel label={t("dates.finishDate")}>
                    <PopupDatePicker
                      dateAndTime
                      value={$case.finishDate}
                      onChange={v => {
                        setCase(o => ({
                          ...o,
                          finishDate: v
                        }));
                      }}
                    />
                  </ItemLabel>

                  {/* <IonDatetime
                  min={minDate}
                  max={maxDate}
                  displayFormat="DD MMM YYYY HH:mm"
                  minuteValues="00, 05, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55"
                  placeholder={tPlaceholder("dates.enterFinishDate")}
                  value={$case.finishDate}
                  onIonChange={e => {
                    setCase(o => ({ ...o, finishDate: e.detail.value! }));
                  }}
                /> */}
                </IonCol>
              </IonRow>
            </IonGrid>

            <h3 className="form-header">{t("other")}</h3>

            <IonGrid>
              <IonRow>
                <IonCol size-xs="6">
                  <IonItem lines="none">
                    <IonLabel position="stacked">{t("cases.pan")}</IonLabel>
                    <IonInput
                      placeholder={t("cases.pan")}
                      value={$case.pan}
                      onIonChange={e =>
                        setCase(o => ({ ...o, pan: e.detail.value! }))
                      }
                    />
                  </IonItem>
                </IonCol>
                <IonCol size-xs="6">
                  <IonItem className="ion-margin-top">
                    <IonLabel position="stacked">{t("status")}</IonLabel>
                    <IonSelect
                      value={$case.status}
                      onIonChange={e =>
                        setCase(o => ({ ...o, status: e.detail.value! }))
                      }
                    >
                      {getEnumValues(CaseStatus)
                        .filter(s => s !== CaseStatus.OutOfLab)
                        .map(status => (
                          <IonSelectOption key={status} value={status}>
                            {tCaseStatus(status)}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12">
                  <IonLabel>
                    <p>{t("cases.flag")}</p>
                  </IonLabel>
                  {getEnumValuesUnfiltered(CaseFlag).map(v => (
                    <Icon
                      key={v}
                      icon={faTag}
                      onClick={() =>
                        setCase(o => ({
                          ...o,
                          flag: o.flag === v ? CaseFlag.None : v
                        }))
                      }
                      className={`case-flag-${v} flag-select-icon ${
                        $case.flag === v ? "selected" : "not-selected"
                      }`}
                    />
                  ))}
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonButton
              color="success"
              expand="block"
              onClick={upsertCase}
              disabled={loading}
              className="ion-margin-top"
            >
              {loading ? (
                <Icon spin icon={faSpinner} />
              ) : (
                <Icon icon={faSave} />
              )}
              {t("save")}
            </IonButton>
          </IonContent>
        </IonModal>
      </ModalWrapper>
    </>
  );
};

export default CaseUpsertModal;
