import React, { useState } from "react";
import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import {
  allPermisssions,
  Permission,
  unpackPermissionsFromString
} from "../../models/Permissions";
import PermissionCheckbox from "./PermissionCheckbox";
import { faSpinner, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import InfoBox from "../InfoBox";

interface Props {
  employeePermisssions: string;
  employeeId: number;
}
const EmployeePermissionsUpsert: React.FC<Props> = ({
  employeePermisssions,
  employeeId
}) => {
  const [loading, setLoading] = useState(false);

  const { apiPost } = useApi();
  const { t } = useTranslation();

  const [permissions, setPermissions] = useState<Permission[]>(
    unpackPermissionsFromString(employeePermisssions)
  );
  const { handleError, showSuccessToast } = useNotificationContext();

  const check = (id: number) => {
    setPermissions([...permissions, id]);
  };
  const uncheck = (id: number) => {
    setPermissions(permissions.filter(p => p !== id));
  };

  const save = () => {
    setLoading(true);
    apiPost<number>("employee/changePermissions", {
      id: employeeId,
      permissions: permissions
    })
      .then(() => showSuccessToast(t("permissions.permissionsUpdated")))
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  return (
    <>
      <InfoBox
        text={t("permissions.userHaveAllPermissions")}
        hidden={!permissions.includes(Permission.AccessAll as number)}
      />
      <IonGrid hidden={permissions.includes(Permission.AccessAll as number)}>
        <IonRow hidden>
          <IonCol size="4"></IonCol>

          <IonCol size="2" className="ion-text-center">
            {t("permissions.types.view")}
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {t("permissions.types.create")}
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {t("permissions.types.update")}
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {t("permissions.types.delete")}
          </IonCol>
        </IonRow>

        {allPermisssions.map((p, i) => {
          if (p.permissions === undefined)
            return (
              <div key={i}>
                <h3 className="ion-margin-top">
                  {t("permissions." + p.title)}
                </h3>
                {p.desc && (
                  <span className="smaller-font ion-margin-bottom">
                    {t("permissions." + p.desc)}
                  </span>
                )}
                <IonRow key={i} hidden={p.title === "pagesSubtitle"}>
                  <IonCol size="4"></IonCol>

                  <IonCol size="2" className="ion-text-center">
                    {t("permissions.types.view")}
                  </IonCol>
                  <IonCol size="2" className="ion-text-center">
                    {t("permissions.types.create")}
                  </IonCol>
                  <IonCol size="2" className="ion-text-center">
                    {t("permissions.types.update")}
                  </IonCol>
                  <IonCol size="2" className="ion-text-center">
                    {t("permissions.types.delete")}
                  </IonCol>
                </IonRow>
              </div>
            );
          else
            return (
              <IonRow key={i}>
                <IonCol size="4">
                  {t("permissions.titles." + p.title)}
                  {p.desc && (
                    <span className="smaller-font">
                      {t("permissions.titles." + p.desc)}
                    </span>
                  )}
                </IonCol>
                <IonCol size="2" className="ion-text-center">
                  <PermissionCheckbox
                    value={p.permissions.read}
                    color="success"
                    checked={permissions.includes(p.permissions.read as number)}
                    onCheck={check}
                    onUncheck={uncheck}
                  />
                </IonCol>
                <IonCol size="2" className="ion-text-center">
                  <PermissionCheckbox
                    value={p.permissions.create}
                    color="secondary"
                    checked={permissions.includes(
                      p.permissions.create as number
                    )}
                    onCheck={check}
                    onUncheck={uncheck}
                  />
                </IonCol>
                <IonCol size="2" className="ion-text-center">
                  <PermissionCheckbox
                    value={p.permissions.update}
                    color="warning"
                    checked={permissions.includes(
                      p.permissions.update as number
                    )}
                    onCheck={check}
                    onUncheck={uncheck}
                  />
                </IonCol>
                <IonCol size="2" className="ion-text-center">
                  <PermissionCheckbox
                    value={p.permissions.delete}
                    color="danger"
                    checked={
                      p.permissions.delete
                        ? permissions.includes(p.permissions.delete)
                        : false
                    }
                    onCheck={check}
                    onUncheck={uncheck}
                  />
                </IonCol>
              </IonRow>
            );
        })}

        <IonRow>
          <IonCol size="12">
            <IonButton
              color="success"
              onClick={() => save()}
              expand="block"
              disabled={loading}
            >
              {loading ? (
                <Icon spin icon={faSpinner} />
              ) : (
                <Icon icon={faUserEdit} />
              )}
              {t("permissions.updatePermissions")}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default EmployeePermissionsUpsert;
