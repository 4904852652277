import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonPage,
  IonTitle,
  IonMenuButton,
  IonFab,
  IonFabButton,
  IonIcon
} from "@ionic/react";
import React, { useState } from "react";
import Icon from "../Icon";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import { SortDirection } from "../../models/PagedData";
import { CaseFlag, CaseStatus } from "../../models/Case";
import CaseUpsertModal from "./CaseUpsertModal";
import { useHistory } from "react-router";
import useTranslation from "../../data/useTranslation";
import Can from "../Can";
import CasesTable from "./CasesTable";
import { CasesUrlProps } from "../../models/UrlProps";
import ButtonTextIcon from "../ButtonTextIcon";
import { add } from "ionicons/icons";
import SearchBar from "../SearchBar";

const Cases: React.FC = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [showAddCase, setShowAddCase] = useState(false);
  const [triggerSaveAsPdf, setTriggerSaveAsPdf] = useState<() => void>(
    () => {}
  );
  const [savingPdf, setSavingPdf] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const [urlProps] = useState<CasesUrlProps>({
    page: 1,
    pageSize: 10,
    status: null,
    sortBy: "received",
    sortDirection: SortDirection.Desc
  });

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <SearchBar
              showSearchbar={showSearchbar}
              onSearch={setSearch}
              onCancel={() => {
                setShowSearchbar(false);
                setSearch("");
              }}
            >
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle onClick={() => setShowSearchbar(true)}>
                {t("cases.menuTitle")}
              </IonTitle>
              <IonButtons slot="primary">
                <IonButton onClick={() => setShowSearchbar(true)}>
                  <Icon slot="icon-only" icon={faSearch} />
                </IonButton>
                <IonButton
                  color={showFilter ? "primary" : ""}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <ButtonTextIcon button="filter" />
                </IonButton>
                <IonButton onClick={triggerSaveAsPdf} disabled={savingPdf}>
                  <ButtonTextIcon button="download" loading={savingPdf} />
                </IonButton>
              </IonButtons>
            </SearchBar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <CasesTable
            initialUrlProps={urlProps}
            mode="cases"
            showFilterProp={showFilter}
            searchProp={search}
            exposeSaveAsPdf={s => setTriggerSaveAsPdf(() => s)}
            setSavingPdf={setSavingPdf}
          />

          <Can permission={Permission.CaseCreate}>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton
                onClick={() => setShowAddCase(true)}
                title={t("cases.new")}
              >
                <IonIcon icon={add} />
              </IonFabButton>
            </IonFab>
          </Can>

          <CaseUpsertModal
            initialData={{
              id: 0,
              status: CaseStatus.InProgress,
              doctorId: 0,
              name: "",
              received: new Date().toISOString(),
              flag: CaseFlag.None,
              products: [],
              teethCondition: [],
              autoName: true
            }}
            showCaseModal={showAddCase}
            onSuccess={id => {
              setShowAddCase(false);
              history.replace("/case/" + id);
            }}
            onCancel={() => setShowAddCase(false)}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default withPermission(Cases, Permission.CasesTableRead);
