import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonBackButton,
  IonButtons,
  IonCol,
  IonRow,
  IonGrid,
  IonLoading,
  useIonViewWillEnter
} from "@ionic/react";
import React, { useState } from "react";
import Logo from "../Logo";
import Form from "../form/Form";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../data/useTranslation";
import useApi from "../../data/Api";
import { useAuthContext } from "../../context/AuthProvider";
import { useHistory } from "react-router";
import FormInput from "../form/FormIonInput";

interface Props {
  code: string | null;
}

const AcceptInvitation: React.FC<Props> = ({ code }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { resetUser, authenticated } = useAuthContext();
  const { apiPost } = useApi();
  const history = useHistory();
  const { showSuccessToast, handleError } = useNotificationContext();
  const { t, tInterpolated, tRequired, tPlaceholder } = useTranslation();

  useIonViewWillEnter(() => {
    if (isSubmitting) return;
    if (!authenticated) {
      history.replace(code ? `/login?code=${code}` : "/login");
      return;
    }

    if (code) {
      setSubmitting(true);
      acceptInvitation({ code });
    }
  }, [isSubmitting, authenticated, code]);

  const acceptInvitation = (data: { code: string }) =>
    apiPost<string>(`invitation/accept?code=${data.code}`, {})
      .then(name => {
        resetUser().then(() => {
          showSuccessToast(
            tInterpolated("invitation.acceptedMessage", { name })
          );

          history.replace("");
        });
      })
      .catch(e => {
        setSubmitting(false);
        handleError(e);
      });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("invitation.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={isSubmitting} message={t("invitation.accepting")} />
        <IonGrid>
          <IonRow>
            <IonCol
              size-sm="10"
              offset-sm="1"
              size-md="6"
              offset-md="3"
              size-xl="4"
              offset-xl="4"
            >
              <Logo />

              <Form
                onSubmit={acceptInvitation}
                submitButtonText={t("invitation.submit")}
                initialData={{ code: code ? code : "" }}
              >
                <FormInput
                  name="code"
                  label={t("invitation.code")}
                  rules={{
                    required: tRequired("invitation.code")
                  }}
                  placeholder={tPlaceholder("invitation.code")}
                  type="text"
                />
              </Form>
              <IonButton routerLink="new-lab" expand="full" fill="clear">
                {t("invitation.createLab")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AcceptInvitation;
