import React, { useRef, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonButton,
  IonBackButton,
  IonButtons,
  IonIcon
} from "@ionic/react";
import { useAuthContext } from "../../context/AuthProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import Logo from "../../components/Logo";

import { logoGoogle } from "ionicons/icons";
import useTranslation from "../../data/useTranslation";
import MiddleBox from "../MiddleBox";
import LanguageSelect from "../LanguageSelect";
import { useHistory } from "react-router";
import Icon from "../Icon";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import SimpleReactValidator from "simple-react-validator";

interface UserPass {
  email: string;
  password: string;
}

interface Props {
  code: string | null;
}

const Login: React.FC<Props> = ({ code }) => {
  const { login, googleLogin } = useAuthContext();
  const [credentials, setCredentials] = useState<UserPass>({
    email: "",
    password: ""
  });
  const { handleError } = useNotificationContext();
  const { t, tRequired, tPlaceholder } = useTranslation();
  const history = useHistory();

  const [, forceUpdateValidator] = useState(false);
  const validator = useRef(new SimpleReactValidator());

  const googleLoginLocal = () => {
    googleLogin()
      .then(() => code && history.push(`/invitation?code=${code}`))
      .catch(handleError);
  };

  const loginLocal = (data: UserPass) => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      return forceUpdateValidator(true);
    }
    login(data.email, data.password)
      .then(() => {
        code && history.push(`/invitation?code=${code}`);
      })
      .catch(handleError);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("login")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MiddleBox>
          <Logo />
          <div>
            <IonInput
              placeholder={tPlaceholder("email")}
              type="email"
              inputmode="email"
              value={credentials.email}
              autocomplete="honorific-suffix"
              name="email123"
              id="email123"
              onIonChange={e =>
                setCredentials(c => ({ ...c, email: e.detail.value! }))
              }
            />
            {validator.current.message(
              t("email"),
              credentials.email,
              "required|email",
              { className: "dangerColor" }
            )}
          </div>
          <div>
            <IonInput
              autocomplete="new-password"
              name="new-password"
              id="new-password"
              value={credentials.password}
              placeholder={tPlaceholder("password")}
              clearInput
              type="password"
              onKeyUp={e => {
                if (
                  e.code === "Enter" &&
                  credentials.password.length &&
                  credentials.email.length
                )
                  loginLocal(credentials);
              }}
              onIonChange={e =>
                setCredentials(c => ({ ...c, password: e.detail.value! }))
              }
            />
            {validator.current.message(
              "password",
              credentials.password,
              "required",
              { className: "dangerColor" }
            )}
          </div>
          <IonButton
            class="ion-margin-vertical"
            expand="block"
            type="submit"
            onClick={() => loginLocal(credentials)}
          >
            <Icon icon={faSignInAlt} slot="start" />
            {t("login")}
          </IonButton>

          <IonButton
            style={{
              "--background": "#de5246"
            }}
            class="ion-margin-vertical"
            expand="block"
            type="submit"
            onClick={googleLoginLocal}
          >
            <IonIcon icon={logoGoogle} slot="start" />
            Google {t("login")}
          </IonButton>
          <IonButton routerLink="forgot-password" expand="full" fill="clear">
            {t("forgotYourPassword")}
          </IonButton>

          <IonButton
            routerLink={code ? `register?code=${code}` : "register"}
            expand="full"
            fill="clear"
          >
            {t("dontHaveAccount")}
          </IonButton>
          <LanguageSelect />
        </MiddleBox>
      </IonContent>
    </IonPage>
  );
};

export default Login;
