import { useCallback, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import useApi from "./Api";
import { firebaseMessaging, firebase } from "./firebase";
import { VAPID_KEY } from "./firebaseConfig";

export enum TokenSentToServer {
  No = 0,
  Yes = 1
}

const usePushNotifications = () => {
  //to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
  //We initialize the userConsent with that value
  const [userConsent, setUserConsent] = useState(firebase.messaging.isSupported() ? Notification.permission : "denied");
  //to manage async actions
  const [loading, setLoading] = useState(false);
  const [tokenSentToServer, setTokenSentToServer] =
    useLocalStorage<TokenSentToServer>(
      "tokenSentToServer",
      TokenSentToServer.No
    );
  const [notificationsSupported, setNotificationsSupported] = useState(firebase.messaging.isSupported());
  const [notificationsGranted, setNotificationsGranted] = useState(false);
  const { apiPost } = useApi();

  useEffect(
    () =>
      setNotificationsGranted(
        notificationsSupported &&
        tokenSentToServer === TokenSentToServer.Yes &&
        userConsent === "granted"
      ),
    [notificationsSupported, tokenSentToServer, userConsent]
  );

  // Send the registration token your application server, so that it can:
  // - send messages back to this app
  // - subscribe/unsubscribe the token from topics
  const sendTokenToServer = useCallback(async () => {
    if (firebaseMessaging) {
      const currentToken = await firebaseMessaging.getToken({
        vapidKey: VAPID_KEY
      });
      if (!currentToken) return false;

      if (tokenSentToServer !== TokenSentToServer.Yes) {
        // Send the current token to your server.
        await apiPost(`pushNotification/subscribe?token=${currentToken}`, {});
        setTokenSentToServer(TokenSentToServer.Yes);
      } else {
        console.log(
          "Token already sent to server so won't send it again unless it changes"
        );
      }
      return true;
    }
  }, [setTokenSentToServer, tokenSentToServer]);

  const checkToken = useCallback(async () => {
    // If the push notifications are not supported do nothing
    if (!notificationsSupported || loading) return;

    setLoading(true);

    try {
      const tokenSent = await sendTokenToServer();
      if (tokenSent) return;

      // Show permission request.
      console.log(
        "No registration token available. Request permission to generate one.",
        userConsent
      );

      // user has already granted the permission
      if (userConsent === "granted") return;

      const consent = await Notification.requestPermission();
      // Retrieve a registration token for use with FCM
      setUserConsent(consent);
      if (consent !== "granted") {
        console.log({
          name: "Consent denied",
          message: `You denied the consent to receive notifications (${consent})`,
          code: 0
        });
        setTokenSentToServer(TokenSentToServer.No);
      } else {
        await sendTokenToServer();
      }
    } finally {
      setLoading(false);
    }
  }, [
    userConsent,
    notificationsSupported,
    loading,
    sendTokenToServer,
    setTokenSentToServer
  ]);

  const deleteToken = useCallback(async () => {
    if (firebaseMessaging) {
      // Delete registration token.
      try {
        await firebaseMessaging.deleteToken();
        setTokenSentToServer(TokenSentToServer.No);

        // Once token is deleted update UI.
        await checkToken();
      } catch (err) {
        console.log("Error retrieving registration token. ", err);
      }
    }
  }, [checkToken, setTokenSentToServer]);

  /**
   * returns all the stuff needed by a Component
   */
  return {
    notificationsSupported,
    notificationsGranted,
    tokenSentToServer,
    userConsent,
    checkToken,
    deleteToken
  };
};

export default usePushNotifications;
