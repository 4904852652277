import { IonSearchbar } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import useTranslation from "../data/useTranslation";

interface Props {
  showSearchbar: boolean;
  onSearch: (value: string) => void;
  onCancel: () => void;
  children: React.ReactNode;
}

const SearchBar: React.FC<Props> = ({
  showSearchbar,
  onSearch,
  onCancel,
  children
}) => {
  const { t } = useTranslation();
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);

  useEffect(() => {
    searchBarRef.current && showSearchbar && searchBarRef.current.setFocus();
  }, [searchBarRef, showSearchbar]);

  return (
    <>
      {showSearchbar ? (
        <IonSearchbar
          ref={searchBarRef}
          showCancelButton="always"
          placeholder={t("search")}
          debounce={200}
          onIonChange={(e: CustomEvent) => onSearch(e.detail.value!)}
          onIonCancel={onCancel}
        />
      ) : (
        children
      )}
    </>
  );
};

export default SearchBar;
