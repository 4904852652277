import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";
interface IconProps extends FontAwesomeIconProps {
  slot?: string;
}

const Icon: React.FC<IconProps> = ({ ...rest }) => {
  return <FontAwesomeIcon {...rest} />;
};

export default Icon;
