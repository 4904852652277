import React from "react";

interface Props {
  label: string;
  children: React.ReactNode;
}

const ItemLabel: React.FC<Props> = ({ label, children }) => {
  return (
    <>
      <p className="label_style">{label}</p>
      {children}
    </>
  );
};

export default ItemLabel;
