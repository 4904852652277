import React, { useEffect, useReducer, useState } from "react";
import { ToothCondition, ProductType } from "../../models/Teeth";
import ToothComp from "./Tooth";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle
} from "@ionic/react";
import Icon from "../Icon";
import {
  faChevronDown,
  faChevronUp,
  faEraser,
  faInfoCircle,
  faUndo
} from "@fortawesome/free-solid-svg-icons";
import archesReducer from "./archesReducer";
import useTranslation from "../../data/useTranslation";
import ProductSelectModal from "./ProductSelectModal";
import { ProductViewDto } from "../../models/Product";
import useDentalNotation from "../../hooks/useDentalNotation";
import CaseFormDto, { CaseProductDto } from "../../models/Case";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import ProductList from "./ProductList";
import ButtonTextIcon from "../ButtonTextIcon";
import {
  BridgeSvgPaths,
  VisilLowerJaw,
  VisilUpperJaw
} from "../../data/arches/SvgPaths";
import BridgeConnection from "./BridgeConnection";
import { toothIdInUpper } from "../../hooks/useDentalNotation";

interface Props {
  $case: CaseFormDto;
  onUpdate: (data: Partial<CaseFormDto>) => void;
  onCancel: () => void;
}

const Arches: React.FC<Props> = ({ $case, onUpdate }) => {
  const { t, tProductType } = useTranslation();

  const [labProducts, setLabProducts] = useState<ProductViewDto[]>();

  const [localCase, setLocalCase] = useState($case);

  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();

  const fetchLabProducts = () =>
    apiGet<ProductViewDto[]>(`product/getAll`)
      .then(setLabProducts)
      .catch(handleError);

  useEffect(() => {
    fetchLabProducts();
    setLocalCase($case);
  }, []);

  useEffect(() => {
    onUpdate(localCase);
  }, [localCase]);

  const { teeth: starterTeeth } = useDentalNotation();
  const [
    {
      teeth,
      products,
      teethConditions,
      anySelected,
      productSelected,
      anyConditionSelected,
      canBuildBridge,
      product
    },
    dispatch
  ] = useReducer(archesReducer, {
    teeth: [],
    products: $case.products,
    teethConditions: $case.teethCondition,
    anySelected: false,
    productSelected: 0,
    anyConditionSelected: false,
    canBuildBridge: false
  });

  useEffect(() => {
    product &&
      setProductSelectData({
        ...productSelectData,
        toothIds: product.toothIds,
        productTypeId: product.type
      });
  }, [product]);

  const setCondition = (status: ToothCondition) => {
    dispatch({ type: "setCondition", data: status });
  };
  const deleteCondition = () => {
    dispatch({ type: "deleteCondition" });
  };

  const [productSelectOpen, setProductSelectOpen] = useState(false);

  const [productSelectData, setProductSelectData] = useState<CaseProductDto>({
    id: 0,
    productId: 0,
    toothIds: [],
    productTypeId: 1,
    shade: "",
    quantity: 0,
    extras: []
  });

  const editCaseProduct = (id: number) => {
    const p = products.find(p => p.id === id);
    if (p) {
      setProductSelectData(p);
      setProductSelectOpen(true);
    }
  };

  const addCaseProduct = (type: ProductType) => {
    dispatch({ type: "setType", data: type });
    setProductSelectData({
      id: 0,
      productId: 0,
      toothIds: [],
      productTypeId: type,
      shade: "",
      quantity: 0,
      extras: []
    });

    setProductSelectOpen(true);
  };

  const onToothClick = (id: number) =>
    dispatch({ type: "toothSelectChange", toothId: id });

  const fullUpper = () => dispatch({ type: "fullUpper" });
  const fullLower = () => dispatch({ type: "fullLower" });

  const unselect = () => dispatch({ type: "unselect" });

  const reset = () =>
    dispatch({
      type: "reset",
      data: {
        teeth: starterTeeth,
        anySelected: false,
        productSelected: 0,
        anyConditionSelected: false,
        canBuildBridge: false,
        products: localCase.products,
        teethConditions: localCase.teethCondition
      }
    });

  const deleteSelected = () => dispatch({ type: "deleteSelected" });
  const deleteCaseProduct = (id: number) =>
    dispatch({ type: "deleteProduct", id: id });
  const onProductSelectClose = () => {
    dispatch({ type: "onProductSelectClose" });
    setProductSelectOpen(false);
  };

  const onProductUpsert = (v: CaseProductDto) => {
    dispatch({ type: "onProductUpsert", data: v });
    setProductSelectOpen(false);
  };

  useEffect(() => {
    onUpdate({
      products,
      teethCondition: teethConditions
    });
  }, [products, teethConditions, labProducts]);

  useEffect(() => {
    if ($case.autoName) {
      onUpdate({
        name: !products?.length
          ? $case.name
          : Array.from(
              new Set(
                products.map(
                  p =>
                    `${tProductType(p.productTypeId)} ${
                      labProducts?.find(pr => pr.id === p.productId)?.name
                    }`
                )
              )
            ).join(", ")
      });
    }
  }, [$case.autoName, products]);

  useEffect(() => {
    reset();
  }, [starterTeeth]);

  return (
    <>
      <IonItem lines="none">
        <IonLabel position="stacked">{t("cases.name2")} *</IonLabel>
        <IonInput
          value={$case.name}
          autocomplete="on"
          placeholder={t("cases.namePlaceholder")}
          onIonChange={e => {
            onUpdate({
              name: e.detail.value!
            });
          }}
          onKeyUp={() => {
            onUpdate({
              autoName: false
            });
          }}
        />
      </IonItem>
      <IonItem lines="none">
        <IonLabel
          style={{
            fontSize: "smaller"
          }}
        >
          {t("cases.autoGenerateName")}
        </IonLabel>
        <IonToggle
          color="success"
          checked={$case.autoName}
          onIonChange={e => onUpdate({ autoName: e.detail.checked })}
        />
      </IonItem>
      <IonGrid>
        <IonRow>
          <IonCol></IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-xs="12" size-sm="10" size-md="6" size-lg="7" size-xl="4">
            <IonButton
              size="small"
              fill="clear"
              hidden={anySelected}
              color="medium"
              className="select-full-upper no-icon-margin"
              onClick={fullUpper}
            >
              <Icon size="2x" icon={faChevronUp} />
            </IonButton>
            <IonButton
              size="small"
              fill="clear"
              hidden={anySelected}
              color="medium"
              className="select-full-lower no-icon-margin"
              onClick={fullLower}
            >
              <Icon size="2x" icon={faChevronDown} />
            </IonButton>
            <IonButton
              size="small"
              fill="clear"
              hidden={!anySelected}
              color="medium"
              className="unselect-tooth-arches no-icon-margin"
              onClick={unselect}
            >
              <Icon size="2x" icon={faUndo} />
            </IonButton>
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 535 712">
              {products
                .filter(p => p.productTypeId === ProductType.Bridge)
                .map(bridge =>
                  bridge.toothIds.map((t, i) => (
                    <BridgeConnection
                      key={bridge.toothIds[i] + bridge.toothIds[i + 1]}
                      path={
                        BridgeSvgPaths[
                          bridge.toothIds[i] + bridge.toothIds[i + 1]
                        ]
                      }
                    />
                  ))
                )}
              {products
                .filter(p => p.productTypeId === ProductType.Visil)
                .map(visil => (
                  <path
                    key={visil.toothIds[0]}
                    d={
                      toothIdInUpper(visil.toothIds[0])
                        ? VisilUpperJaw
                        : VisilLowerJaw
                    }
                    strokeWidth={1}
                    stroke="#eee"
                    fill="#C0C0C0"
                    strokeLinecap="round"
                  />
                ))}
              {teeth.map(tooth => (
                <ToothComp key={tooth.id} {...tooth} onClick={onToothClick} />
              ))}
            </svg>
          </IonCol>
          <IonCol
            size-xs="12"
            size-sm="2"
            size-md="6"
            size-lg="5"
            size-xl="8"
            className="ion-no-padding"
          >
            <div
              hidden={anySelected}
              style={{ marginTop: 60 }}
              className="ion-text-center ion-margin-top border"
            >
              <h5 className="opacity-07">
                <Icon icon={faInfoCircle} className="secondaryColor" />{" "}
                {t("arches.selectTeeth")}
              </h5>
            </div>
            <span hidden={!anySelected}>
              <span hidden={!productSelected}>
                <h3 className="form-header-2">{t("product")}</h3>
                <IonList className="ion-no-padding">
                  <IonButton
                    size="small"
                    fill="outline"
                    color="secondary"
                    onClick={() => editCaseProduct(productSelected)}
                  >
                    <ButtonTextIcon button="edit" />
                  </IonButton>

                  <IonButton
                    size="small"
                    fill="outline"
                    color="danger"
                    onClick={deleteSelected}
                  >
                    <ButtonTextIcon button="delete" />
                  </IonButton>
                </IonList>
              </span>
              <IonList className="ion-no-margin" hidden={productSelected > 0}>
                <h3 className="form-header-2">{t("arches.addNewProduct")}</h3>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => addCaseProduct(ProductType.Crown)}
                >
                  {tProductType(ProductType.Crown)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!canBuildBridge}
                  onClick={() => addCaseProduct(ProductType.Bridge)}
                >
                  {tProductType(ProductType.Bridge)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => addCaseProduct(ProductType.Denture)}
                >
                  {tProductType(ProductType.Denture)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => addCaseProduct(ProductType.Visil)}
                >
                  {tProductType(ProductType.Visil)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => addCaseProduct(ProductType.Miscellaneous)}
                >
                  {tProductType(ProductType.Miscellaneous)}
                </IonButton>
              </IonList>

              <h3 className="form-header-2">{t("arches.describeCondition")}</h3>
              <IonList className="ion-no-padding">
                <IonButton
                  size="small"
                  disabled={!anySelected}
                  onClick={() => setCondition(ToothCondition.NoTooth)}
                >
                  {t("arches.noTooth")}
                </IonButton>
                <IonButton
                  size="small"
                  disabled={!anySelected}
                  onClick={() => setCondition(ToothCondition.DeadTooth)}
                >
                  {t("arches.deadTooth")}
                </IonButton>
                <IonButton
                  size="small"
                  disabled={!anySelected}
                  onClick={() => {
                    setCondition(ToothCondition.Implant);
                  }}
                >
                  {t("arches.implant")}
                </IonButton>

                <IonButton
                  hidden
                  size="small"
                  fill="outline"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => setCondition(ToothCondition.Hook)}
                >
                  {t("arches.hook")}
                </IonButton>
                <IonButton
                  size="small"
                  fill="outline"
                  color="danger"
                  hidden={!anyConditionSelected}
                  onClick={() => deleteCondition()}
                >
                  <Icon icon={faEraser} />
                  {t("arches.deleteCondition")}
                </IonButton>
              </IonList>
            </span>
            <h3 hidden={!$case.products.length} className="form-header">
              {t("products")}
            </h3>
            <ProductList
              $case={$case}
              labProducts={labProducts}
              showActions
              onEdit={id => editCaseProduct(id)}
              onDelete={id => deleteCaseProduct(id)}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      {labProducts && (
        <ProductSelectModal
          isOpen={productSelectOpen}
          initialData={productSelectData}
          labProducts={labProducts}
          onCancel={onProductSelectClose}
          onSuccess={onProductUpsert}
          onLabProductUpdate={fetchLabProducts}
        />
      )}
    </>
  );
};

export default Arches;
