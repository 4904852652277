import { IonRow, IonSelectOption, IonSkeletonText } from "@ionic/react";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import Form from "../form/Form";
import LabDto, { DentalNotation } from "../../models/Lab";
import { currencies } from "../../models/Currency";
import EmailFormItem from "../form/EmailFormItem";
import useTranslation from "../../data/useTranslation";
import { getEnumValues } from "../../data/Helpers";
import Icon from "../Icon";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../form/FormIonInput";
import FormIonSelect from "../form/FormIonSelect";

export const convertToFormData = (lab: LabDto) => {
  const formData = new FormData();
  lab.logo && formData.append("logo", lab.logo, lab.logo.name);
  for (let [key, value] of Object.entries(lab)) {
    if (key === "logo" || !value) continue;
    formData.append(key, value.toString());
  }

  return formData;
};

interface Props {
  submitButtonText: string;
  newLab?: boolean;
  onSubmit: (d: LabDto) => Promise<void>;
}

const LabForm: React.FC<Props> = ({ submitButtonText, newLab, onSubmit }) => {
  const { t, tRequired, tPlaceholder } = useTranslation();
  const { apiGet, apiGetImage } = useApi();
  const { handleError, showAlert } = useNotificationContext();

  const [loadingLab, setLoadingLab] = useState(true);
  const [lab, setLab] = useState<LabDto>();

  const logoRef = useRef<HTMLInputElement>(null);
  const [logo, setLogo] = useState<File>();
  const [logoImg, setLogoImg] = useState<string>();

  const onUploadClick = useCallback(() => {
    logoRef?.current?.click();
  }, [logoRef]);

  const fetchLab = async () => {
    try {
      setLoadingLab(true);

      const [lab, logo] = await Promise.all([
        apiGet<LabDto>("lab/get"),
        apiGetImage("lab/getLogo")
      ]);
      setLogo(logo);
      setLogoImg(logo ? URL.createObjectURL(logo) : undefined);
      setLab({ ...lab, logo });
    } catch (err) {
      handleError(err as Error);
    } finally {
      setLoadingLab(false);
    }
  };

  useEffect(() => {
    if (newLab) {
      setLab({
        id: 0,
        name: "",
        phone: "",
        email: "",
        website: "",
        currency: "EUR",
        taxRate: 20,
        dentalNotation: DentalNotation.ISO
      });
      setLoadingLab(false);
    } else {
      fetchLab();
    }
  }, [newLab]);

  const handleSubmit = (lab: LabDto) => onSubmit({ ...lab, logo });

  const onLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      var fileSize = img.size / 1024 / 1024; // MB
      if (fileSize <= 2) {
        setLogo(img);
        setLogoImg(URL.createObjectURL(img));
      } else {
        showAlert({
          title: t("error.title"),
          message: t("error.maxFileSize"),
          buttons: [{ text: "OK" }]
        });
      }
    }
  };

  return loadingLab ? (
    <IonSkeletonText animated />
  ) : (
    <>
      <input
        ref={logoRef}
        type="file"
        accept="image/png, image/jpeg"
        onChange={onLogoChange}
        hidden
      />
      <IonRow
        className="ion-justify-content-center company-logo-select"
        style={{
          backgroundImage: `url(${logoImg ?? "assets/img/no-lab-logo.png"})`
        }}
        onClick={onUploadClick}
      >
        <Icon icon={faUpload} className="photo-image-logo-select" />
      </IonRow>

      <Form
        onSubmit={handleSubmit}
        submitButtonText={submitButtonText}
        initialData={lab}
        ignoreFields={["logo"]}
      >
        <FormInput name="id" label="Id" hidden />
        <FormInput
          name="name"
          label={t("labName")}
          rules={{
            required: tRequired("labName")
          }}
          placeholder={tPlaceholder("labName")}
          clearInput
        />
        <FormInput
          name="phone"
          label={t("phone")}
          rules={{
            maxLength: 15
          }}
          placeholder={tPlaceholder("phone")}
          clearInput
        />
        <FormInput
          name="website"
          label={t("website")}
          rules={{
            maxLength: 25
          }}
          placeholder={tPlaceholder("website")}
          clearInput
        />
        <EmailFormItem />

        <FormIonSelect
          name="currency"
          label={t("currency")}
          rules={{
            required: tRequired("currency")
          }}
          placeholder={tPlaceholder("currency")}
          interface="action-sheet"
        >
          {currencies.map(c => (
            <IonSelectOption value={c.code} key={c.code} title={c.name}>
              ({c.symbol}) {c.name}
            </IonSelectOption>
          ))}
        </FormIonSelect>
        <FormIonSelect
          name="dentalNotation"
          label={t("notation")}
          rules={{
            required: tRequired("notation"),
            valueAsNumber: true
          }}
          placeholder={tPlaceholder("notation")}
          okText={t("ok")}
          cancelText={t("dismiss")}
          interface="action-sheet"
        >
          {getEnumValues(DentalNotation).map(c => (
            <IonSelectOption
              value={c}
              key={c}
              title={t(`dentalNotations.${c}`)}
            >
              {t(`dentalNotations.${c}`)}
            </IonSelectOption>
          ))}
        </FormIonSelect>
        <FormInput
          name="taxRate"
          label={t("taxRate")}
          rules={{
            required: tRequired("taxRate"),
            valueAsNumber: true
          }}
          placeholder={tPlaceholder("taxRate")}
          clearInput
          type="number"
          step="1"
        />
      </Form>
    </>
  );
};

export default LabForm;
