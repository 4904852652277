import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthProvider";
import { getCurrencySymbol } from "../models/Currency";

export const formatCurrencyValue = (value: number) => value.toFixed(2);

const useCurrency = () => {
  const { lab } = useAuthContext();
  const [currency, setCurrency] = useState<string>();
  const [currencySign, setCurrencySign] = useState<string>();

  useEffect(() => {
    setCurrency(lab?.currency);
    setCurrencySign(getCurrencySymbol(lab?.currency));
  }, [lab]);

  const formatWithCurrencySign = (value: number | undefined) => {
    if (value === undefined) return "";
    if (!currency) return formatCurrencyValue(value);

    // Symbol to the left of the amount, no space
    if (currency === "USD" || currency === "GBP" || currency === "SGD")
      return `${currencySign}${formatCurrencyValue(value)}`;

    // Symbol to the left of the amount, non-breaking space
    if (
      currency === "ZAR" ||
      currency === "CHF" ||
      currency === "TWD" ||
      currency === "NZD" ||
      currency === "NOK" ||
      currency === "PHP" ||
      currency === "MXN" ||
      currency === "MYR" ||
      currency === "KRW" ||
      currency === "JPY" ||
      currency === "ILS" ||
      currency === "INR" ||
      currency === "HKD" ||
      currency === "DKK" ||
      currency === "CNY" ||
      currency === "BRL" ||
      currency === "CAD" ||
      currency === "AUD" ||
      currency === "ARS"
    )
      return `${currencySign} ${formatCurrencyValue(value)}`;

    // Symbol to the right of the amount, non-breaking space
    return `${formatCurrencyValue(value)} ${currencySign}`;
  };

  return { formatWithCurrencySign, currencySign };
};

export default useCurrency;
