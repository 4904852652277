import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import ItemLabel from "./ItemLabel";

interface Props {
  label: string;
  select: React.ReactNode;
  button: React.ReactNode;
}

const SelectAndButton: React.FC<Props> = ({ label, select, button }) => {
  return (
    <ItemLabel label={label}>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol className="ion-no-padding">{select}</IonCol>
          <IonCol size="auto" style={{ paddingRight: 0 }}>
            {button}
          </IonCol>
        </IonRow>
      </IonGrid>
    </ItemLabel>
  );
};

export default SelectAndButton;
