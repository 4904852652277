import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonButton
} from "@ionic/react";
import React, { useState } from "react";

import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";

import useTranslation from "../../data/useTranslation";
import Icon from "../Icon";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import Calendar from "./Calendar";

const CalendarAllCases: React.FC = () => {
  const [title, setTitle] = useState<string>();
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");

  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="ion-text-capitalize">
            {title ?? t("calendar.title")}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setPrev(new Date().toString())}>
              <Icon icon={faChevronLeft} />
            </IonButton>
            <IonButton onClick={() => setNext(new Date().toString())}>
              <Icon icon={faChevronRight} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Calendar
          type="cases"
          showFilter
          onTitleChange={title => setTitle(title)}
          nextClicked={next}
          prevClicked={prev}
        />
      </IonContent>
    </IonPage>
  );
};

export default withPermission(CalendarAllCases, Permission.CaseRead);
