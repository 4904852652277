import React, { useEffect, useState, ReactNode, useContext } from "react";
import {
  firebaseAuth,
  extractUserInfo,
  hasIdInToken,
  signInWithGoogle,
  currentUser
} from "../data/firebase";
import firebase from "firebase/app";
import { RegisterData, User } from "../models/User";
import LabDto from "../models/Lab";
import { apiFetch } from "../data/Api";
import { appUrl } from "../data/Constants";
import usePushNotifications from "../data/pushNotifications";

interface ContextProps {
  user: User | undefined;
  authenticated: boolean;
  loadingAuthState: boolean;
  lab: LabDto | undefined;
  register: (data: RegisterData, code: string | null) => Promise<void>;
  login: (email: string, pass: string) => Promise<void>;
  googleLogin: () => Promise<void>;
  logout: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetUser: () => Promise<void>;
  resetLab: (l: LabDto) => void;
  ensureNoLoadingState: () => void;
}

const AuthContext = React.createContext<ContextProps>({
  user: undefined,
  authenticated: false,
  loadingAuthState: false,
  lab: undefined,
  register: _ => Promise.resolve(),
  login: _ => Promise.resolve(),
  googleLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  forgotPassword: _ => Promise.resolve(),
  resetUser: () => Promise.resolve(),
  resetLab: () => {},
  ensureNoLoadingState: () => {}
});

const useAuthContext = () => useContext(AuthContext);

const ensureUserId = async (fbUser: firebase.User, data?: RegisterData) => {
  // check if the user has an ID
  if (await hasIdInToken(fbUser)) return;

  // create user in our database
  const token = await fbUser.getIdToken(true);
  await apiFetch<number>("POST", "user/create", token);
};

interface Props {
  children: ReactNode | ReactNode[] | Element;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [lab, setLab] = useState<LabDto>();
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const { deleteToken } = usePushNotifications();

  useEffect(
    () =>
      firebaseAuth().onIdTokenChanged(async (fbUser: firebase.User | null) => {
        if (fbUser && !user) {
          // this is a login
          await ensureUserId(fbUser);
        }
        await setUserInfoFromUser(fbUser);
        setLoadingAuthState(false);
      }),
    []
  );

  const fetchLab = async (fbUser: firebase.User, labId?: number) => {
    if (!labId) return undefined;
    try {
      const token = await fbUser.getIdToken();
      return await apiFetch<LabDto>("GET", "lab/get", token);
    } catch {
      return undefined;
    }
  };

  const setUserInfoFromUser = async (fbUser: firebase.User | null) => {
    if (!fbUser) {
      setUser(undefined);
      setLab(undefined);
      return;
    }

    const localUser = await extractUserInfo(fbUser);
    const lab = await fetchLab(fbUser, localUser.labId);

    setUser(localUser);
    setLab(lab);
  };
  const login = async (email: string, pass: string) => {
    setLoadingAuthState(true);
    await firebaseAuth()
      .signInWithEmailAndPassword(email, pass)
      .catch(e => {
        setLoadingAuthState(false);
        throw e;
      });
  };

  const register = async (data: RegisterData, code: string | null) => {
    try {
      setLoadingAuthState(true);
      const { user } = await firebaseAuth().createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      if (user && user !== null) {
        await user.updateProfile({
          displayName: `${data.firstName} ${data.lastName}`
        });

        // TODO: Check this for more options https://firebase.google.com/docs/auth/web/passing-state-in-email-actions
        const actionCodeSettings = {
          url: code ? `${appUrl}invitation?code=${code}` : `${appUrl}new-lab/`
          // TODO: Add options for opening Continue button in the app
        };
        // we can send the email and create the user in the same time
        await user.sendEmailVerification(actionCodeSettings);
      }
    } catch (e) {
      setLoadingAuthState(false);
      throw e;
    }
  };

  const googleLogin = () => {
    setLoadingAuthState(true);
    return signInWithGoogle()
      .then(() => {}) // small hack to make it Promise<void>
      .catch(e => {
        setLoadingAuthState(false);
        throw e;
      });
  };

  const forgotPassword = (email: string) => {
    return firebaseAuth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert("email is sent");
      })
      .catch((error: any) => {
        alert(error.message);
      })
      .finally();
  };

  const logout = () => {
    setLoadingAuthState(true);
    return firebaseAuth()
      .signOut()
      .then(() => deleteToken())
      .catch(e => {
        setLoadingAuthState(false);
        throw e;
      });
  };

  const resetUser = async () => {
    try {
      setLoadingAuthState(true);
      const fbUser = currentUser();
      await setUserInfoFromUser(fbUser);
    } finally {
      setLoadingAuthState(false);
    }
  };
  const resetLab = async (lab: LabDto) => setLab(lab);

  const ensureNoLoadingState = () =>
    loadingAuthState && setLoadingAuthState(false);

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user ? true : false,
        loadingAuthState,
        lab,
        register,
        login,
        googleLogin,
        forgotPassword,
        logout,
        resetUser,
        resetLab,
        ensureNoLoadingState
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuthContext };
