import React, { useState, useEffect } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonButton,
  IonList,
  IonSkeletonText,
  IonRouterLink
} from "@ionic/react";
import { InvoiceViewDto, InvoiceStatus } from "../../models/Invoice";
import { RouteComponentProps } from "react-router";
import PaymentUpsertModal from "./PaymentUpsertModal";
import PaymentDto from "../../models/Payment";
import { useAuthContext } from "../../context/AuthProvider";
import Icon from "../../components/Icon";
import {
  faExternalLinkAlt,
  faMoneyBillWave,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import ValueLabel from "../ValueLabel";
import useTranslation from "../../data/useTranslation";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import InvoiceUpsertModal from "./InvoiceUpsertModal";
import {
  calculateInvoiceItemsTotals,
  calculateTotal,
  calculateTaxTotal,
  toDecimal
} from "./invoiceMath";
import useDate from "../../hooks/useDate";
import PaymentType from "../../models/PaymentType";
import ButtonTextIcon from "../ButtonTextIcon";
import useCurrency from "../../hooks/useCurrency";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const InvoiceView: React.FC<Props> = ({ match }) => {
  const { apiGet, apiBlobDownload } = useApi();
  const { user } = useAuthContext();
  const { formatWithCurrencySign } = useCurrency();
  const { toDateString, toDateTimeString } = useDate();

  const { handleError } = useNotificationContext();

  const [downloading, setDownloading] = useState(false);
  const [invoice, setInvoice] = useState<InvoiceViewDto>();
  const [paymentData, setPaymentData] = useState<PaymentDto>();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);

  const { t, tInvoiceStatus, tPaymentType } = useTranslation();

  useEffect(() => {
    getInvoice();
  }, [match.params.id]);

  const getInvoice = () =>
    apiGet<InvoiceViewDto>("invoice/get?id=" + match.params.id)
      .then(countTotals)
      .catch(handleError);

  const saveAsPdf = () => {
    setDownloading(true);
    apiBlobDownload("POST", `invoice/SaveAsPdf?id=${match.params.id}`, {})
      .catch(handleError)
      .finally(() => setDownloading(false));
  };

  const countTotals = (invoice: InvoiceViewDto) => {
    const items = calculateInvoiceItemsTotals(invoice.items);
    const total = calculateTotal(items);
    const taxTotal = calculateTaxTotal(total, invoice.taxRate);

    const nInvoice = {
      ...invoice,
      items,
      subTotal: toDecimal(total - taxTotal),
      taxTotal,
      total
    };
    setInvoice(nInvoice);
  };

  const addPayment = () => {
    if (invoice && invoice.status !== InvoiceStatus.Paid) {
      setPaymentData({
        id: 0,
        invoiceId: invoice.id,
        doctorId: invoice.doctorId,
        amount: toDecimal(invoice.total - invoice.totalPaid),
        paymentType: PaymentType.Cash,
        notes: ""
      });
    }
  };

  useEffect(() => {
    if (paymentData) setShowPaymentModal(true);
  }, [paymentData]);

  return (
    <IonPage>
      {invoice ? (
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/invoices" />
              </IonButtons>
              <IonTitle>
                {t("invoice")} {invoice.number}
              </IonTitle>
              <IonButtons slot="primary">
                <IonButton onClick={saveAsPdf} disabled={downloading}>
                  <ButtonTextIcon button="download" loading={downloading} />
                </IonButton>
                <IonButton onClick={() => setShowInvoiceModal(true)}>
                  <ButtonTextIcon button="edit" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow className="box">
                <IonCol>
                  <h5>{t("recipient")}</h5>
                  <ValueLabel title={t("name")}>
                    <IonRouterLink
                      routerLink={"/doctor/" + invoice.doctor.id}
                      color="default"
                    >
                      {invoice.doctor.name}{" "}
                      <Icon
                        className="mediumColor"
                        size="xs"
                        icon={faExternalLinkAlt}
                      />
                    </IonRouterLink>
                  </ValueLabel>
                  <ValueLabel title={t("company")}>
                    {invoice.doctor.clinicName}
                  </ValueLabel>
                  {invoice.doctor.address && (
                    <ValueLabel title={t("address")}>
                      {invoice.doctor.address}
                    </ValueLabel>
                  )}
                  {invoice.doctor.phone && (
                    <ValueLabel title={t("phone")}>
                      {invoice.doctor.phone}
                    </ValueLabel>
                  )}
                  {invoice.doctor.website && (
                    <ValueLabel title={t("website")}>
                      {invoice.doctor.website}
                    </ValueLabel>
                  )}
                </IonCol>
                <IonCol className="invoiceHeader">
                  <h5>{t("caseInfo")}</h5>

                  <ValueLabel title={t("name")}>
                    <IonRouterLink
                      routerLink={"/case/" + invoice.case.id}
                      color="default"
                    >
                      {invoice.case.name}{" "}
                      <Icon
                        className="mediumColor"
                        size="xs"
                        icon={faExternalLinkAlt}
                      />
                    </IonRouterLink>
                  </ValueLabel>
                  <ValueLabel title={t("dates.received")}>
                    {toDateString(invoice.case.received)}
                  </ValueLabel>
                </IonCol>
                <IonCol className="invoiceHeader">
                  <h5>{t("invoices.info")}</h5>

                  <ValueLabel title={t("invoices.number")}>
                    {invoice.number}{" "}
                  </ValueLabel>
                  <ValueLabel title={t("status")}>
                    <span className={"invoiceIcon" + invoice.status}>
                      {tInvoiceStatus(invoice.status)}
                    </span>
                  </ValueLabel>
                  <ValueLabel title={t("created")}>
                    {toDateString(invoice.created)}
                  </ValueLabel>
                  <ValueLabel title={t("createdBy")}>
                    {invoice.createdByUser.firstName}{" "}
                    {invoice.createdByUser.lastName}
                  </ValueLabel>
                </IonCol>
              </IonRow>

              <IonRow class="box ion-margin-top">
                <IonCol size="12" className="ion-no-padding">
                  <h5>{t("invoices.items")}</h5>
                </IonCol>
                <IonCol className="ion-no-padding">
                  <IonGrid>
                    <IonRow className="invoiceItemsHeader">
                      <IonCol>
                        <IonLabel>{t("name")}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonLabel>{t("quantity")}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonLabel>{t("price")}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonLabel>{t("discount")}</IonLabel>
                      </IonCol>
                      <IonCol class="col-align-hor">
                        <IonLabel>{t("total")}</IonLabel>
                      </IonCol>
                    </IonRow>

                    {invoice.items.map((item, i) => (
                      <IonRow key={i} className="invoiceItem">
                        <IonCol>{item.name}</IonCol>
                        <IonCol>{item.quantity}</IonCol>
                        <IonCol>{formatWithCurrencySign(item.price)}</IonCol>
                        <IonCol>{item.discount}%</IonCol>
                        <IonCol class="col-align-hor">
                          {formatWithCurrencySign(item.total)}
                        </IonCol>
                      </IonRow>
                    ))}
                  </IonGrid>
                </IonCol>
              </IonRow>
              <IonRow class="box">
                <IonCol size-xs="12" size-md="10" className="ion-no-padding">
                  <span hidden={!invoice.notes}>
                    <h5 className="ion-no-padding">{t("notes")}:</h5>
                    <p className="label-value">{invoice.notes}</p>
                  </span>
                </IonCol>
                <IonCol size-xs="12" size-md="2" pushMd="right">
                  <ValueLabel title={t("taxRate")}>
                    {invoice.taxRate}
                  </ValueLabel>
                  <ValueLabel title={t("subTotal")}>
                    {formatWithCurrencySign(invoice.subTotal)}
                  </ValueLabel>
                  <ValueLabel title={t("taxTotal")}>
                    {formatWithCurrencySign(invoice.taxTotal)}
                  </ValueLabel>
                  <ValueLabel title={t("total")}>
                    <b>{formatWithCurrencySign(invoice.total)}</b>
                  </ValueLabel>
                  <ValueLabel title={t("totalPaid")}>
                    <b>{formatWithCurrencySign(invoice.totalPaid)}</b>
                  </ValueLabel>
                  <ValueLabel title={t("balanceDue")}>
                    <b
                      className={
                        invoice.total - invoice.totalPaid > 0
                          ? "warningColor"
                          : "successColor"
                      }
                    >
                      {formatWithCurrencySign(
                        invoice.total - invoice.totalPaid
                      )}
                    </b>
                  </ValueLabel>
                </IonCol>
              </IonRow>
              <IonRow class="box">
                <Can permission={Permission.PaymentsRead}>
                  <IonCol className="ion-no-padding">
                    <h3
                      className="ion-margin-start"
                      hidden={invoice.payments.length === 0}
                    >
                      {t("payments.title")}
                    </h3>
                    <IonList class="ion-no-padding lines">
                      {invoice.payments.map(d => (
                        <IonItem
                          key={d.id}
                          button
                          detail
                          onClick={() =>
                            user &&
                            user.hasPermission(Permission.PaymentsUpdate) &&
                            setPaymentData(d)
                          }
                        >
                          <Icon slot="start" icon={faMoneyBillWave} />
                          <IonLabel>
                            <span className="content-font">
                              {formatWithCurrencySign(d.amount)}
                            </span>
                            <p>
                              <i>{tPaymentType(d.paymentType)}</i>
                            </p>
                            <p hidden={!d.notes.length}>
                              <i>
                                {t("notes")}: {d.notes}
                              </i>
                            </p>
                            <p>{toDateTimeString(d.created)}</p>
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>

                    <Can permission={Permission.PaymentsCreate}>
                      <div className="ion-text-center ion-margin-top">
                        <IonButton
                          color="secondary"
                          hidden={invoice.status === InvoiceStatus.Paid}
                          // class="ion-margin-start"
                          onClick={addPayment}
                        >
                          <Icon icon={faPlus} />
                          {t("payments.add")}
                        </IonButton>
                      </div>
                    </Can>
                  </IonCol>
                </Can>
              </IonRow>
            </IonGrid>
          </IonContent>
          {paymentData && (
            <PaymentUpsertModal
              initialData={paymentData}
              showPaymentModal={showPaymentModal}
              onCancel={() => setShowPaymentModal(false)}
              onSuccess={() => {
                getInvoice();
                setShowPaymentModal(false);
              }}
            />
          )}
          <InvoiceUpsertModal
            id={invoice.id}
            showInvoiceModal={showInvoiceModal}
            onSuccess={() => {
              getInvoice();
              setShowInvoiceModal(false);
            }}
            onCancel={() => setShowInvoiceModal(false)}
          />
        </>
      ) : (
        <IonSkeletonText animated />
      )}
    </IonPage>
  );
};

export default withPermission(InvoiceView, Permission.InvoiceRead);
