import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import TryoutFormDto from "../../models/Tryout";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import Form from "../form/Form";
import useTranslation from "../../data/useTranslation";
import ButtonTextIcon from "../ButtonTextIcon";
import ModalWrapper from "../ModalWrapper";
import FormInput from "../form/FormIonInput";
import FormIonDate from "../form/FormIonDate";
import FormAntdDate from "../form/FormAntdDate";

const minDate = new Date(
  new Date().setFullYear(new Date().getFullYear() - 1)
).toISOString();
const maxDate = new Date(
  new Date().setFullYear(new Date().getFullYear() + 2)
).toISOString();

interface Props {
  onSuccess: (data: TryoutFormDto) => void;
  onCancel: () => void;
  initialData: TryoutFormDto;
  showTryoutUpsertModal: boolean;
}
const TryoutUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showTryoutUpsertModal
}) => {
  const [submit, setSubmit] = useState<() => void>(() => {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { apiPost } = useApi();
  const { showSuccessToast, handleError } = useNotificationContext();
  const { t, tRequired, tPlaceholder } = useTranslation();

  const upsertTryout = (formData: TryoutFormDto) => {
    setIsSubmitting(true);
    apiPost<number>("tryout/upsert", formData)
      .then(() => {
        showSuccessToast(t("saved"));
        onSuccess(formData);
      })
      .catch(handleError)
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <ModalWrapper
      modalOpened={showTryoutUpsertModal}
      dismiss={onCancel}
      modal="tryoutUpsert"
    >
      <IonModal isOpen={showTryoutUpsertModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {initialData.id > 0
                ? t("cases.editTryout")
                : t("cases.addTryout")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={submit} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Form
            initialData={initialData}
            onSubmit={upsertTryout}
            submitButtonText={
              initialData.id > 0 ? t("cases.editTryout") : t("cases.addTryout")
            }
            exposeSubmit={setSubmit}
            setSubmitting={setIsSubmitting}
          >
            <FormInput name="id" label="" hidden />
            <FormInput name="caseId" label="" hidden />
            <FormInput name="doctorId" label="" hidden />
            <FormInput
              name="name"
              label={t("name")}
              rules={{
                required: tRequired("name")
              }}
              className="ion-margin-bottom"
              placeholder={t("cases.tryoutNamePlaceholder")}
            />
            {/* <FormIonDate
              name="date"
              label={t("date")}
              rules={{
                required: tRequired("date")
              }}
              min={minDate}
              max={maxDate}
              displayFormat="DD MMM YYYY HH:mm"
              minuteValues="00, 05, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55"
              placeholder={tPlaceholder("date")}
              okText={t("ok")}
              cancelText={t("cancel")}
            /> */}
            <FormAntdDate
              name="date"
              label={t("date")}
              rules={{
                required: tRequired("date")
              }}
              dateAndTime
              minuteStep={5}
              placeholder={tPlaceholder("date")}
            />
          </Form>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default TryoutUpsertModal;
