import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initTranslation } from "./data/useTranslation";
import { APP_UPDATE_EVENT } from "./data/Constants";

initTranslation();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  // onUpdate: r => {
  //   console.log(r);
  //   window.dispatchEvent(new CustomEvent(APP_UPDATE_EVENT, { detail: r }));
  // }
  onUpdate: async (registration: ServiceWorkerRegistration) => {
    await registration.update();
    window.dispatchEvent(
      new CustomEvent(APP_UPDATE_EVENT, { detail: registration })
    );
  }
});
