import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton
} from "@ionic/react";
import { RouteComponentProps, useHistory } from "react-router";
import useApi from "../../data/Api";
import DoctorFormDto from "../../models/Doctor";
import { useNotificationContext } from "../../context/NotificationProvider";
import Icon from "../../components/Icon";
import { faUserTie, faEdit } from "@fortawesome/free-solid-svg-icons";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import { SortDirection } from "../../models/PagedData";
import ValueLabel from "../ValueLabel";
import { CaseFlag, CaseStatus } from "../../models/Case";
import CaseUpsertModal from "../case/CaseUpsertModal";
import useTranslation from "../../data/useTranslation";
import { useAuthContext } from "../../context/AuthProvider";
import MiddleBox from "../MiddleBox";
import DoctorUpsertModal from "./DoctorUpsertModal";
import InvoicesTable from "../invoice/InvoicesTable";
import CasesTable from "../case/CasesTable";
import ButtonTextIcon from "../ButtonTextIcon";

interface DoctorUrlProps
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const DoctorProfile: React.FC<DoctorUrlProps> = ({ match }) => {
  const history = useHistory();
  const { user } = useAuthContext();
  const [segment, setSegment] = useState<"info" | "cases" | "invoices">(
    user?.hasPermission(Permission.CasesTableRead) ? "cases" : "info"
  );
  const [showFilter, setShowFilter] = useState(false);
  const [doctorData, setDoctorData] = useState<DoctorFormDto>();

  const [showAddCase, setShowAddCase] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const fetchDoctor = () =>
    apiGet<DoctorFormDto>("doctor/get?id=" + match.params.id)
      .then(setDoctorData)
      .catch(handleError);

  useEffect(() => {
    fetchDoctor();
  }, [match.params.id]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/doctors" />
          </IonButtons>
          <IonTitle>{doctorData?.name}</IonTitle>
          <IonButtons slot="primary">
            <Can permission={Permission.DoctorUpdate}>
              <IonButton onClick={() => setShowEditModal(true)}>
                <ButtonTextIcon button="edit" />
              </IonButton>
            </Can>
            <IonButton
              hidden={segment === "info"}
              color={showFilter ? "primary" : ""}
              onClick={() => setShowFilter(!showFilter)}
            >
              <ButtonTextIcon button="filter" />
            </IonButton>
            <Can permission={Permission.CaseCreate}>
              <IonButton onClick={() => setShowAddCase(true)}>
                <ButtonTextIcon button="addCase" />
              </IonButton>
            </Can>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="header_toolbar2">
          <IonSegment
            onIonChange={e => {
              setSegment(e.detail.value as any);
            }}
            value={segment}
          >
            <IonSegmentButton value={"info"}>
              <IonLabel>{t("info")}</IonLabel>
            </IonSegmentButton>
            <Can permission={Permission.CasesTableRead}>
              <IonSegmentButton value="cases">
                <IonLabel>{t("cases.title")}</IonLabel>
              </IonSegmentButton>
            </Can>
            <Can permission={Permission.InvoicesTableRead}>
              <IonSegmentButton value="invoices">
                <IonLabel>{t("invoices.title")}</IonLabel>
              </IonSegmentButton>
            </Can>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {segment === "info" && doctorData && (
          <MiddleBox className="box">
            <div className="ion-padding">
              <div className="ion-text-center ion-margin-top">
                <Icon icon={faUserTie} size="4x" className="secondaryColor" />
                <h3>{doctorData.name}</h3>
                <ValueLabel title={t("clinicName")}>
                  {doctorData.clinicName}
                </ValueLabel>
                {doctorData.email && (
                  <ValueLabel title={t("email")}>{doctorData.email}</ValueLabel>
                )}
                {doctorData.phone && (
                  <ValueLabel title={t("phone")}>{doctorData.phone}</ValueLabel>
                )}
                {doctorData.address && (
                  <ValueLabel title={t("address")}>
                    {doctorData.address}
                  </ValueLabel>
                )}

                <Can permission={Permission.DoctorUpdate}>
                  <IonButton
                    color="secondary"
                    onClick={() => setShowEditModal(true)}
                  >
                    <Icon icon={faEdit} /> {t("edit")}
                  </IonButton>
                </Can>
              </div>
            </div>
          </MiddleBox>
        )}
        {segment === "invoices" && doctorData && (
          <>
            <InvoicesTable
              initialUrlProps={{
                page: 1,
                doctorId: doctorData.id,
                pageSize: 10,
                status: null,
                sortBy: "created",
                sortDirection: SortDirection.Desc
              }}
              mode="doctorInvoices"
              showFilterProp={showFilter}
              searchProp=""
            />
          </>
        )}
        {segment === "cases" && doctorData && (
          <>
            <CasesTable
              initialUrlProps={{
                page: 1,
                doctorId: doctorData.id,
                pageSize: 10,
                status: null,
                sortBy: "received",
                sortDirection: SortDirection.Desc
              }}
              mode="doctorCases"
              showFilterProp={showFilter}
              searchProp=""
            />

            <Can permission={Permission.CaseCreate}>
              <div className="ion-text-center ion-margin-top">
                <IonButton onClick={() => setShowAddCase(true)}>
                  {t("cases.new")}
                </IonButton>
              </div>
            </Can>
          </>
        )}

        <CaseUpsertModal
          initialData={{
            id: 0,
            doctorId: parseInt(match.params.id),
            status: CaseStatus.InProgress,
            name: "",
            received: new Date().toISOString(),
            flag: CaseFlag.None,
            products: [],
            teethCondition: [],
            autoName: true
          }}
          showCaseModal={showAddCase}
          onSuccess={id => {
            setShowAddCase(false);
            history.replace("/case/" + id);
          }}
          onCancel={() => setShowAddCase(false)}
        />
        {doctorData && (
          <DoctorUpsertModal
            initialData={doctorData}
            showDoctorModal={showEditModal}
            onSuccess={() => {
              fetchDoctor();
              showSuccessToast(t("doctors.updated"));
              setShowEditModal(false);
            }}
            onCancel={() => setShowEditModal(false)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default withPermission(DoctorProfile, Permission.DoctorRead);
