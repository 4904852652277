import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonBackButton
} from "@ionic/react";
import React from "react";
import Form from "../form/Form";
import Logo from "../../components/Logo";
import { useAuthContext } from "../../context/AuthProvider";
import { RegisterData } from "../../models/User";
import EmailFormItem from "../form/EmailFormItem";
import useTranslation from "../../data/useTranslation";
import MiddleBox from "../MiddleBox";
import LanguageSelect from "../LanguageSelect";
import { useNotificationContext } from "../../context/NotificationProvider";
import { useHistory } from "react-router";
import Icon from "../Icon";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../form/FormIonInput";

interface Props {
  code: string | null;
}

const Register: React.FC<Props> = ({ code }) => {
  const { register } = useAuthContext();
  const { handleError } = useNotificationContext();
  const { t, tRequired, tPlaceholder } = useTranslation();
  const history = useHistory();

  const registerLocal = (e: RegisterData) =>
    register(e, code)
      .then(() => {
        code && history.push(`/invitation?code=${code}`);
      })
      .catch(handleError);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("register")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MiddleBox>
          <Logo />
          <Form
            onSubmit={registerLocal}
            submitButtonText={t("createAccount")}
            submitButtonIcon={<Icon slot="start" icon={faUserPlus} />}
          >
            <FormInput
              name="firstName"
              label={t("firstName")}
              rules={{
                required: tRequired("firstName")
              }}
              placeholder={tPlaceholder("firstName")}
            />
            <FormInput
              name="lastName"
              label={t("lastName")}
              rules={{
                required: tRequired("lastName")
              }}
              placeholder={tPlaceholder("lastName")}
            />
            <EmailFormItem required />

            <FormInput
              name="password"
              label={t("password")}
              rules={{
                required: tRequired("password")
              }}
              placeholder={tPlaceholder("password")}
              clearInput
              type="password"
            />
          </Form>
          <IonButton
            expand="block"
            fill="clear"
            routerLink={code ? `login?code=${code}` : "login"}
          >
            {t("goToLogin")}
          </IonButton>
          <LanguageSelect />
        </MiddleBox>
      </IonContent>
    </IonPage>
  );
};

export default Register;
