export enum DentalNotation {
  ISO = 1,
  Universal = 2,
  Palmer = 3
}

interface LabDto {
  id: number;
  name: string;
  phone: string | null;
  email: string | null;
  website: string | null;
  currency: string;
  taxRate: number;
  dentalNotation: DentalNotation;
  logo?: File;
}

export default LabDto;
