import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import { useAuthContext } from "../../context/AuthProvider";
import { allPages } from "./Navigation";

const InitialPath: React.FC = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const [initialPath, setInitialPath] = useState("");

  useEffect(() => {
    const findRoute = () => {
      if (!user) return "/login";

      const initialPage = allPages.find(
        p => !p.permission || user.hasPermission(p.permission)
      );
      if (initialPage) return initialPage.url;

      return "/profile";
    };
    setInitialPath(findRoute());
  }, [location, user]);

  return <Redirect to={initialPath} />;
};

export default InitialPath;
